import { TenantProvider } from '../../common/TenantPersona';
import { BreOrderContext, BrePerformableConfig } from '~/tenants/bre/model/BreOrderContext';

export enum BreProviderId {
  JOSEPH_IRASUSTA = 1,
  KWASI_ACHIAW = 2,
  NOEL_DASMARINAS = 3,
  ALICIA_GARCIA = 4,
  DAVID_DINDACK = 5,
  IVAN_MARTINEZ = 6,
  EMILY_MCLAUGHRY = 7,
  LAUREN_DINDAK = 8,
  CHRIS_RICKETTS = 9,
  ZACH_CRAWFORD = 10,
}

export interface BreProviderStatic {
  performables: Array<BrePerformableConfig['id']>;
}

export default class BreProvider extends TenantProvider<BreProviderStatic, BreOrderContext> {
  canPerform(_context: BreOrderContext, job: BreOrderContext['jobs'][0]): true | string {
    const performable = this.tenant.performable(job.performable_id);
    const hasPerfomable = this.config.performables.includes(job.performable_id);

    return hasPerfomable || `This provider does not do ${performable.config.name} jobs.`;
  }

  get performableIds(): string[] {
    return this.config.performables;
  }
}
