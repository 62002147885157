import { ReactElement, useEffect } from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { BreOrderMetadata } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosExtra from '~/tenants/bre/performable/photos/BrePhotosExtra';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';

interface BrePhotosFieldsProps {
  metadata: BreOrderMetadata;
}

const PHOTOS_OPTS = Array.from({ length: 11 })
  .map((_, i) => i * 10 + 15)
  .map<InputSelectOption>((value) => ({
    label: `${value} Photos`,
    value: value.toFixed(0),
  }));

export default function BrePhotosFields({ metadata }: BrePhotosFieldsProps): ReactElement {
  const [, , setPhotos] = useZodFormFieldSingle('photos');

  useEffect(() => {
    const recommended = BrePhotosJob.recommendedPhotos(metadata);
    setPhotos(recommended.toFixed(0));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormHorizontal name="photos" label="Minimum number of Photos recommended for your Property size" required>
        <ZodFieldSelect name="photos" options={PHOTOS_OPTS} />
      </FormHorizontal>
      <FormHorizontal label="Unsure about the package?">
        <BrePhotosExtra metadata={metadata} />
      </FormHorizontal>
    </>
  );
}
