import { useTenant } from '~/tenants/common/TenantContextProvider';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import LoadingIcon from '~/components/icons/LoadingIcon';

export function useLegacyResponsiveImage(src: string): string {
  const tenant = useTenant();

  return `https://${tenant.domain}/media/${src.replaceAll(' ', '%20')}`;
}

export default function LegacyResponsiveImage(props: {
  src: string;
  width?: number;
  className?: string;
  round?: boolean;
}): JSX.Element {
  const url = useLegacyResponsiveImage(props.src);
  const ref = useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ref.current?.complete) {
      setLoading(false);
    }
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <img
        ref={ref}
        src={url}
        srcSet={`${url}, ${url}?dpi=2 2x, ${url}?dpi=3 3x`}
        width={props.width}
        className={classNames(props.className, { 'rounded-theme overflow-hidden': props.round })}
        onLoad={() => {
          setLoading(false);
        }}
      />
      {loading && (
        <div className="flex items-center justify-center absolute inset-0 opacity-25">
          <div className="p-4 w-24 h-24">
            <LoadingIcon />
          </div>
        </div>
      )}
    </div>
  );
}
