import { Big } from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import {
  BrePostcardsHandlePay,
  BrePostcardsHandleRate,
  BrePostcardsMailingPay,
  BrePostcardsPostagePay,
  BrePostcardsPostageRate,
  BrePostcardsMailingRate,
  isUpTo,
} from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingData';
import BrePostcardsMailingConfig, {
  PostcardMailingPaper,
  PostcardMailingPostage,
  PostcardMailingSize,
} from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingConfig';
import { BreDesignPay, BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { BreOrderType } from '~/tenants/bre/model/enums';

export default class BrePostcardsMailingJob extends BreJob<typeof BrePostcardsMailingConfig> {
  get performable(): typeof BrePostcardsMailingConfig {
    return BrePostcardsMailingConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.RESIDENTIAL, BreOrderType.COMMERCIAL, BreOrderType.PROPERTY_MKTG);
  }

  get sizeType(): string {
    const { size } = this.metadata;
    return BrePostcardsMailingJob.sizeType(size);
  }

  get paperType(): string {
    const { paper } = this.metadata;
    return BrePostcardsMailingJob.paperType(paper);
  }

  get postageType(): string {
    const { postage } = this.metadata;
    return BrePostcardsMailingJob.postageType(postage);
  }

  static sizeType(size: PostcardMailingSize): string {
    return match(size)
      .with('half', () => 'Half Sheet')
      .with('jumbo', () => 'Jumbo Sheet')
      .with('flats', () => '8.5" x 11" Flats')
      .exhaustive();
  }

  static paperType(paper: PostcardMailingPaper): string {
    return match(paper)
      .with('heavy_gloss', () => 'Heavy-gloss')
      .with('super_heavy_gloss_uv', () => 'Super heavy-gloss with UV cover')
      .exhaustive();
  }

  static postageType(postage: PostcardMailingPostage): string {
    return match(postage)
      .with('standard', () => 'Bulk Mail')
      .with('first_class', () => 'First Class')
      .exhaustive();
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePostcardsMailingConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      count: 0,
      paper: 'heavy_gloss',
      size: 'half',
      postage: 'standard',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count, paper, size, postage, design } = this.metadata;
    const printRate = BrePostcardsMailingRate[size][paper].find(isUpTo(count));
    const handleRate = BrePostcardsHandleRate[size].find(isUpTo(count));
    const postageRate = BrePostcardsPostageRate[size][postage];
    const lines: TenantJobLine[] = [];

    if (printRate?.value) {
      lines.push({
        id: 'postcards_mailing',
        description: `${this.sizeType} ${this.paperType} post cards (x${count})`,
        amount: new Big(printRate.value),
        taxable: true,
      });
    }

    if (postageRate) {
      lines.push({
        id: 'postcards_mailing_postage',
        description: `${this.postageType} postage (x${count})`,
        amount: new Big(postageRate).times(count),
        taxable: true,
      });
    }

    if (handleRate?.value) {
      lines.push({
        id: 'postcards_mailing_handle',
        description: `Mailing service (x${count})`,
        amount: new Big(handleRate.value),
        taxable: true,
      });
    }

    if (design) {
      const rate = BreDesignRate[design][`postcard_${size}`];

      if (rate) {
        lines.push({
          id: 'porcard_mailing_design',
          description: `Postcard design (${design} layout)`,
          amount: new Big(rate),
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count, paper, size, postage, design } = this.metadata;
    const printRate = BrePostcardsMailingPay[size][paper].find(isUpTo(count));
    const handleRate = BrePostcardsHandlePay[size].find(isUpTo(count));
    const postageRate = BrePostcardsPostagePay[size][postage];
    const lines: TenantJobLine[] = [];

    if (printRate?.value) {
      lines.push({
        id: 'postcards_mailing',
        description: `${this.sizeType} ${this.paperType} post cards (x${count})`,
        amount: new Big(printRate.value),
      });
    }

    if (postageRate) {
      lines.push({
        id: 'postcards_mailing_postage',
        description: `${this.postageType} postage (x${count})`,
        amount: new Big(postageRate).times(count),
      });
    }

    if (handleRate?.value) {
      lines.push({
        id: 'postcards_mailing_handle',
        description: `Mailing service (x${count})`,
        amount: new Big(handleRate.value),
      });
    }

    if (design) {
      const pay = BreDesignPay[design][`postcard_${size}`];

      if (pay) {
        lines.push({
          id: 'porcard_mailing_design',
          description: `Postcard design (${design} layout)`,
          amount: new Big(pay),
        });
      }
    }

    return lines;
  }
}
