import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BrePortraitsRate } from '~/tenants/bre/performable/portraits/BrePortraitsData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';

export default function BrePortraitsForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { EXTRA_EDIT, EXTRA_POSE, ONSITE, STUDIO } = BrePortraitsRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Portraits Package" required>
        <ZodFieldCards
          name="type"
          cols={2}
          options={[
            { title: 'Headshot', description: 'At our Los Gatos Office', cost: STUDIO, value: 'studio' },
            { title: 'Lifestyle', description: 'Within a 25 miles radius of Los Gatos', cost: ONSITE, value: 'onsite' },
          ]}
          required
        />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckboxName
          name="extra_edit"
          title="Addtitional Edited Photo"
          description="Add an extra edited photo"
          image={null}
          persona={persona}
          cost={EXTRA_EDIT}
        />
        <TenantPackageAddOnCheckboxName
          name="extra_pose"
          title="Additional Lifestyle Photo"
          description="Add an extra lifestyle photo"
          image={null}
          persona={persona}
          cost={EXTRA_POSE}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
