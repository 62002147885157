import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '../../model/MpiJob';
import MpiBrochuresConfig, {
  MpiBrochureShipping,
  MpiBrochuresOption,
  MpiBrochuresQuantity,
} from './MpiBrochuresConfig';

export const MPI_BROCHURES_SHIPPING_STANDARD = 22;
export const MPI_BROCHURES_SHIPPING_RUSH = 60;

interface MpiBrochuresOptions {
  revenue: Record<MpiBrochuresQuantity, number>;
  pages: number;
  width: number;
  height: number;
}

export const BROCHURES_OPTIONS: Record<MpiBrochuresOption, MpiBrochuresOptions> = {
  [MpiBrochuresOption.TWO]: {
    revenue: {
      [MpiBrochuresQuantity.QTY_25]: 95,
      [MpiBrochuresQuantity.QTY_50]: 125,
      [MpiBrochuresQuantity.QTY_100]: 175,
      [MpiBrochuresQuantity.QTY_250]: 225,
    },
    pages: 2,
    width: 8.5,
    height: 11,
  },
  [MpiBrochuresOption.FOUR]: {
    revenue: {
      [MpiBrochuresQuantity.QTY_25]: 105,
      [MpiBrochuresQuantity.QTY_50]: 160,
      [MpiBrochuresQuantity.QTY_100]: 200,
      [MpiBrochuresQuantity.QTY_250]: 300,
    },
    pages: 4,
    width: 11,
    height: 17,
  },
  [MpiBrochuresOption.EIGHT]: {
    revenue: {
      [MpiBrochuresQuantity.QTY_25]: 170,
      [MpiBrochuresQuantity.QTY_50]: 260,
      [MpiBrochuresQuantity.QTY_100]: 360,
      [MpiBrochuresQuantity.QTY_250]: 525,
    },
    pages: 8,
    width: 11,
    height: 17,
  },
  [MpiBrochuresOption.TWELVE]: {
    revenue: {
      [MpiBrochuresQuantity.QTY_25]: 225,
      [MpiBrochuresQuantity.QTY_50]: 300,
      [MpiBrochuresQuantity.QTY_100]: 500,
      [MpiBrochuresQuantity.QTY_250]: 750,
    },
    pages: 12,
    width: 11,
    height: 17,
  },
  [MpiBrochuresOption.SIXTEEN]: {
    revenue: {
      [MpiBrochuresQuantity.QTY_25]: 300,
      [MpiBrochuresQuantity.QTY_50]: 360,
      [MpiBrochuresQuantity.QTY_100]: 640,
      [MpiBrochuresQuantity.QTY_250]: 850,
    },
    pages: 16,
    width: 11,
    height: 17,
  },
};

export default class MpiBrochuresJob extends MpiJob<typeof MpiBrochuresConfig> {
  get performable() {
    return MpiBrochuresConfig;
  }

  get eligible() {
    return true;
  }

  get configurable() {
    return true;
  }

  get submission() {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpiBrochuresConfig)['schema']> {
    return {
      option: MpiBrochuresOption.TWO,
      version: schema_latest_version(MpiBrochuresConfig.schema),
      quantity: MpiBrochuresQuantity.QTY_25,
    };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big(BROCHURES_OPTIONS[this.metadata.option].revenue[this.metadata.quantity]),
      description: this.performable.name,
      // TODO: Joel: Need to revisit this. Manager approval required
      discountable: false,
      taxable: true,
      id: this.performable.id,
    });

    switch (this.metadata.shipping) {
      case MpiBrochureShipping.STANDARD:
        lines.push({
          amount: new Big(MPI_BROCHURES_SHIPPING_STANDARD),
          description: 'Standard Shipping',
          discountable: false,
          taxable: true,
          id: 'standard',
        });
        break;
      case MpiBrochureShipping.RUSH:
        lines.push({
          amount: new Big(MPI_BROCHURES_SHIPPING_RUSH),
          description: 'Rush Shipping',
          discountable: false,
          taxable: true,
          id: 'rush',
        });
        break;
      default:
        break;
    }

    if (this.order.buyer.metadata.discount_print) {
      lines.push({
        amount: new Big(this.order.buyer.metadata.discount_print),
        description: 'Customer Loyalty Discount',
        id: 'discount_print',
        discount: true,
      });
    }

    return lines;
  }
}
