import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { BreBoostAddOn } from '~/tenants/bre/performable/BreBoostAddOn';
import BreCopyrightDisclosure from '~/tenants/bre/performable/BreCopyrightDisclosure';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreSocialAddOns from '~/tenants/bre/performable/social/BreSocialAddOns';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreSocialForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { RUSH } = BreSocialRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckboxName
          name="rush"
          title="Rush"
          description="Need your video by the next business day instead of the second business day?"
          image="tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
        <BreSocialAddOns type={context.metadata.type} persona={persona} />
        <BreBoostAddOn name="boost" persona={persona} />
      </TenantPerformableAddOns>

      {context.metadata.type === BreOrderType.RENTAL && <BreCopyrightDisclosure context={context} />}
      <BreListingDisclosure />
    </FormGroup>
  );
}
