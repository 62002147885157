import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum MpiHeadshotType {
  STUDIO = 'studio',
  LOCATION = 'location',
}

export default createPerformableConfig(
  'headshot',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      people: z.coerce.number().min(1).max(10).optional(),
      group: coerceBoolean().optional().optional(),
      type: z.nativeEnum(MpiHeadshotType),
      notes: z.string().optional(),
      parking: z.string().optional(),
    }),
  },
  {
    name: 'Studio Head Shots',
    images: [],
    short: '',
    tenant: TenantId.MPI,
  },
);
