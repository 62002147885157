import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { MpiAerialType } from '~/tenants/mpi/performable/aerial/MpiAerialConfig';

export default function MpiAerialForm({}: TenantPerformableFormProps<MpiOrderContext>) {
  return (
    <ZodFieldSelect
      name="type"
      options={[
        { label: 'Photos', value: MpiAerialType.PHOTO },
        { label: 'Video', value: MpiAerialType.VIDEO },
        { label: 'Photos + Video', value: MpiAerialType.BOTH },
      ]}
    />
  );
}
