import { ReactElement } from 'react';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { VOICE_OPTS } from '~/tenants/bre/performable/common';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCards, TenantPackageAddOnCheckboxName } from '~/tenants/common/package';

interface BreSocialAddOnsProps {
  persona: PersonaType;
  type: BreOrderType;
}

export default function BreSocialAddOns({ persona, type }: BreSocialAddOnsProps): ReactElement {
  const { AERIAL, INTRO, KWASI, NARRATION } = BreSocialRate[type];
  const narration = useZodFormFieldSingleValue('narration', (v) => v === 'true');

  return (
    <>
      <TenantPackageAddOnCheckboxName
        name="aerial"
        title="Aerial Upgrade"
        description="Add aerial video & 5 additional aerial photos."
        image="tenant/bre/social/aerial.jpg"
        persona={persona}
        cost={AERIAL}
      />
      <TenantPackageAddOnCheckboxName
        name="intro"
        title="Agent Intro"
        description="Add agent on camera intro."
        image="tenant/bre/social/intro.jpg"
        persona={persona}
        cost={INTRO}
      />
      <TenantPackageAddOnCheckboxName
        name="narration"
        title="Agent Narration"
        description="Add agent narration."
        image="tenant/bre/social/narration.jpg"
        persona={persona}
        cost={NARRATION}
      />
      {narration && (
        <TenantPackageAddOnCards
          name="kwasi"
          title="Professional Voice-Over"
          description="Enhance your video with a professional voice-over and script."
          image="tenant/bre/social/kwasi.png"
          persona={persona}
          options={VOICE_OPTS}
          cost={KWASI}
          cols={2}
          required
        />
      )}
    </>
  );
}
