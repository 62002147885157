import dedent from 'dedent';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';

export type PostcardMailingSize = z.infer<typeof sizeSchema>;

export type PostcardMailingPaper = z.infer<typeof paperSchema>;

export type PostcardMailingPostage = z.infer<typeof postageSchema>;

export const sizeSchema = z.enum(['half', 'jumbo', 'flats']);

export const paperSchema = z.enum(['heavy_gloss', 'super_heavy_gloss_uv']);

export const postageSchema = z.enum(['standard', 'first_class']);

export default createPerformableConfig(
  'postcards-mailing',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: z.coerce
        .number({ invalid_type_error: 'Count is required' })
        .min(250, { message: 'Count must be at least 250 pieces' })
        .max(7000, { message: 'Count cannot be over 7000 pieces' }),
      size: sizeSchema,
      paper: paperSchema,
      postage: postageSchema,
      need_by: zodTDateISODate('Need By date is required'),
      design: layoutSchema.optional(),
    }),
  },
  {
    name: 'Post Cards (mailing)',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/postcards/mailing/thumbnail.jpg',
    images: [],
    short: dedent`
      Mail custom postcards for Just Listed, Just Sold, You're Invited, and \
      more—all with mailing fees included in the price. Prefer print-only? \
      Select "Postcards (Print-Only)" instead!
    `,
  },
);
