import { Big } from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreEnvelopesConfig, { EnvelopePaper } from '~/tenants/bre/performable/print/envelopes/BreEnvelopesConfig';
import { BreEnvelopesPay, BreEnvelopesRate } from '~/tenants/bre/performable/print/envelopes/BreEnvelopesData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreEnvelopesJob extends BreJob<typeof BreEnvelopesConfig> {
  get performable(): typeof BreEnvelopesConfig {
    return BreEnvelopesConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.AGENT_MKTG);
  }

  get paperType(): string {
    const { paper } = this.metadata;
    return BreEnvelopesJob.paperType(paper);
  }

  static paperType(paper: EnvelopePaper): string {
    return match(paper)
      .with('smooth_white', () => 'Smooth White')
      .with('premium_linen_white', () => 'Premium Linen White')
      .exhaustive();
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  isFreeDelivery(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreEnvelopesConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      count: '250',
      paper: 'smooth_white',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count, paper } = this.metadata;
    const rate = BreEnvelopesRate[paper][count];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'envelopes_print',
        description: `${this.paperType} paper envelope (x${count})`,
        amount: new Big(rate),
        taxable: true,
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count, paper } = this.metadata;
    const pay = BreEnvelopesPay[paper][count];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'envelopes_print',
        description: `${this.paperType} paper envelope (x${count})`,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}
