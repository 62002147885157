import dedent from 'dedent';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';

export type Reach = z.infer<typeof reachSchema>;

export type Boost = z.infer<typeof boostSchema>;

export const reachSchema = z.enum([
  '3,000',
  '5,000',
  '7,000',
  '9,250',
  '11,500',
  '14,000',
  '16,500',
  '20,000',
  '24,000',
]);

export const boostSchema = z.object({
  reach: reachSchema,
  start_at: zodTDateISODate('Boost start date is required'),
});

export default createPerformableConfig(
  'boost',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      })
      .merge(boostSchema),
  },
  {
    name: 'Social Media Boosting',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/boost/thumbnail_v2.jpg',
    images: [],
    short: dedent`
      Maximize your property’s visibility with BayAreaHomeShowcase on Facebook \
      and Instagram! Instantly showcase your listing to buyers in your chosen \
      city or beyond, ensuring it reaches the right audience. Share stunning \
      photos, virtual tours, and key details in an engaging, interactive format \
      that encourages shares and saves. With strategic ad spending, you can \
      expand your reach, targeting serious buyers and boosting engagement. \
      Whether you're looking for a quick sale or maximum exposure, digital \
      marketing through social media is a game-changer. Elevate your listing \
      today and attract the right buyers effortlessly!
    `,
  },
);
