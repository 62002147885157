import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreTwilightRate } from '~/tenants/bre/performable/twilight/BreTwilightData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreCopyrightDisclosure from '~/tenants/bre/performable/BreCopyrightDisclosure';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { BRE_COPYRIGHT_PHOTO_FEE } from '~/tenants/bre/data';

export default function BreTwilightForm({ persona, context }: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { PHOTOS_15, PHOTOS_25, PHOTOS_8 } = BreTwilightRate[context.metadata.type];
  const tz = useTz();

  const commercialOptions: InputCardOption[] = [
    { title: '15 Photos', cost: PHOTOS_15, value: '15' },
    { title: '25 Photos', cost: PHOTOS_25, value: '25' },
  ];

  const options: InputCardOption[] = [
    { title: '8 Photos', cost: PHOTOS_8, value: '8' },
    ...commercialOptions,
  ];

  return (
    <FormGroup>
      <FormHorizontal name="photos" label="Twilight Package" required>
        <div className="flex flex-col gap-4">
          <ZodFieldCards
            name="photos"
            options={context.metadata.type === BreOrderType.COMMERCIAL ? commercialOptions : options}
            required
          />
        </div>
      </FormHorizontal>
      <FormHorizontal
        name="shoot_date"
        label="Shoot Date"
        description="Make sure to set a date after your expected shoot date."
        required
      >
        <ZodFieldDate name="shoot_date" filter={(date) => date >= tz().startOf('day')} />
      </FormHorizontal>
      <FormHorizontal name="notes" label="Notes">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <TenantPackageAddOnCheckboxName
          name="copyright"
          title="Copyright"
          description="Release photos with copyrights"
          image="/tenant/bre/copyright.webp"
          persona={persona}
          cost={BRE_COPYRIGHT_PHOTO_FEE[BreOrderType.COMMERCIAL]}
          costEach
        />
      )}

      {[BreOrderType.RENTAL, BreOrderType.LONG_TERM].includes(context.metadata.type) && (
        <BreCopyrightDisclosure context={context} />
      )}
      <BreListingDisclosure />
    </FormGroup>
  );
}
