import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '../../model/MpiJob';
import MpiFlyerConfig, { MpiFlyerQuantity, MpiFlyerShipping } from './MpiFlyerConfig';

export const MPI_FLYER_SHIPPING_STANDARD = 22;
export const MPI_FLYER_SHIPPING_RUSH = 60;

const pricing: Record<MpiFlyerQuantity, number> = {
  [MpiFlyerQuantity.QTY_25]: 95,
  [MpiFlyerQuantity.QTY_50]: 125,
  [MpiFlyerQuantity.QTY_100]: 175,
  [MpiFlyerQuantity.QTY_250]: 225,
};

export default class MpiFlyerJob extends MpiJob<typeof MpiFlyerConfig> {
  get performable() {
    return MpiFlyerConfig;
  }

  get eligible() {
    return true;
  }

  get submission() {
    return true;
  }

  get configurable() {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpiFlyerConfig)['schema']> {
    return {
      version: schema_latest_version(MpiFlyerConfig.schema),
      quantity: MpiFlyerQuantity.QTY_25,
    };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      id: 'quantity',
      amount: new Big(pricing[this.metadata.quantity]),
      description: `Print Flyers (${this.metadata.quantity})`,
      discountable: false,
      taxable: true,
    });

    switch (this.metadata.shipping) {
      case MpiFlyerShipping.STANDARD:
        lines.push({
          amount: new Big(MPI_FLYER_SHIPPING_STANDARD),
          description: 'Standard Shipping',
          discountable: false,
          taxable: true,
          id: 'standard',
        });
        break;
      case MpiFlyerShipping.RUSH:
        lines.push({
          amount: new Big(MPI_FLYER_SHIPPING_RUSH),
          description: 'Rush Shipping',
          discountable: false,
          taxable: true,
          id: 'rush',
        });
        break;
      default:
        break;
    }

    if (this.order.buyer.metadata.discount_print) {
      lines.push({
        amount: new Big(this.order.buyer.metadata.discount_print),
        description: 'Customer Loyalty Discount',
        id: 'discount_print',
        discount: true,
      });
    }

    const discount25 = this.order.buyer.metadata.discount_flyer_25 ?? this.order.parent?.discount_flyer_25;
    const discount50 = this.order.buyer.metadata.discount_flyer_50 ?? this.order.parent?.discount_flyer_50;

    if (discount25 && (this.metadata.quantity === MpiFlyerQuantity.QTY_25 || !discount50)) {
      lines.push({
        amount: new Big(discount25),
        description: '25 Print Flyers Discount',
        id: 'discount_25',
        discount: true,
      });
    } else if (discount50 && this.metadata.quantity !== MpiFlyerQuantity.QTY_25) {
      lines.push({
        amount: new Big(discount50),
        description: '50 Print Flyers Discount',
        id: 'discount_50',
        discount: true,
      });
    }

    return lines;
  }
}
