import MpiPhotosTwilight from './MpiPhotosTwilight';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import MpiPhotosCommunity from './MpiPhotosCommunity';
import MpiPhotosDetail from '~/tenants/mpi/performable/photos/MpiPhotosDetail';

export default function MpiPhotosAddOns(props: Pick<TenantPerformableFormProps<MpiOrderContext>, 'persona'>) {
  return (
    <>
      <MpiPhotosTwilight {...props} />
      <MpiPhotosCommunity {...props} />
      <MpiPhotosDetail {...props} />
    </>
  );
}
