import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';
import { MPI_WALKTHROUGH_AGENT } from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughJob';

export default function MpiWalkthroughForm({ persona }: TenantPerformableFormProps<MpiOrderContext>) {
  return (
    <TenantPackageAddOnCheckboxName
      name="agent"
      image={null}
      persona={persona}
      cost={MPI_WALKTHROUGH_AGENT}
      title="Agent on Camera"
      description="Addition to walkthrough video where agent is filmed describing property while on-site. Subject to additional scheduling requirements, and scheduling at the same time as photography may not be possible. Agent must be prepared with a script and ready for filming at the scheduled time."
    />
  );
}
