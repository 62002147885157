import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import dedent from 'dedent';
import { consentSchema } from '~/tenants/bre/performable/common';
import { coerceLiteralNumberRequired, coerceOptionalPositiveNumber, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'staging',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      style: z.enum([
        'modern',
        'scandinavian',
        'contemporary',
        'hamptons',
        'traditional',
        'farmhouse',
        'urban',
        'outdoor',
      ]),
      quantity: z.coerce
        .number({ invalid_type_error: 'Quantity is required' })
        .positive({ message: 'Quantity must be greater than zero' }),
      item_removal: coerceOptionalPositiveNumber('Item Removal images must be greater then zero'),
      space_renovation: coerceOptionalPositiveNumber('Space Renovation images images must be greater then zero'),
      disclosure: consentSchema(),
    }),
  },
  {
    name: 'Virtual Staging',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/staging/thumbnail.jpg',
    images: [],
    short: dedent`
      Virtual staging is a cost-effective solution that transforms empty spaces \
      into beautifully furnished homes, helping buyers visualize a property's \
      full potential. When traditional staging isn’t feasible due to budget \
      constraints, virtual staging offers a high-quality alternative that \
      enhances listing photos, making them more inviting and engaging. This \
      digital approach not only saves time and money but also allows for \
      customized styling that appeals to a wider audience. By showcasing a warm, \
      lived-in atmosphere, virtual staging can attract more buyers, increase \
      interest, and ultimately lead to faster, higher-value offers.
    `,
  },
);
