import dedent from 'dedent';
import * as v from 'valibot';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { consentSchema } from '~/tenants/bre/performable/common';

export type AerialType = z.infer<typeof typeSchema>;

export const typeSchema = z.enum(['five', 'ten', 'video', 'combo']);

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: typeSchema,
      twilight: coerceBoolean().optional(),
      poi: z.array(
        z.object({
          name: z.string({ required_error: 'Point of Interest name is required' }),
          location: z.string({ required_error: 'Point of Interest location is required' }),
        }),
      ).optional(),
      rush: coerceBoolean().optional(),
      disclosure: consentSchema(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Aerial Photo & Video',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/aerial/thumbnail.jpg',
    images: [],
    short: dedent`
      Our Aerial photos are shot using multiple exposures that are then \
      “layered” over each other to form an extremely natural-looking and \
      detail-rich images.
    `,
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
