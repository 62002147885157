import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtPhotoDomain from '~/tenants/twt/performable/photo/TwtPhotoDomain';
import React from 'react';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function TwtByopForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <TenantPerformableAddOns persona={persona}>
      <TwtPhotoDomain persona={persona} />
    </TenantPerformableAddOns>
  );
}
