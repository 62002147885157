import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import {
  BrePostcardsPostageRate,
  BrePostcardsMailingRate,
  isUpTo,
} from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingData';
import {
  PostcardMailingPaper,
  PostcardMailingPostage,
  PostcardMailingSize,
  paperSchema,
  postageSchema,
  sizeSchema,
} from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingConfig';
import BrePostcardsMailingJob from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingJob';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import { useTz } from '~/components/hooks/useTz';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TenantPackageAddOnCards } from '~/tenants/common/package';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { BreDesignRate } from '~/tenants/bre/performable/print/design/BreDesignData';
import { capitalize } from '~/lib/format';
import { ReactElement } from 'react';

interface IsAvailableOptions {
  count: number;
  size: PostcardMailingSize;
  postage: PostcardMailingPostage;
  paper: PostcardMailingPaper;
}

export default function BrePostcardsForm({ persona }: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const tz = useTz();
  const count = useZodFormFieldSingleValue('count', Number);
  const size = useZodFormFieldSingleValue<PostcardMailingSize>('size') ?? 'half';
  const paper = useZodFormFieldSingleValue<PostcardMailingPaper>('paper') ?? 'heavy_gloss';
  const postage = useZodFormFieldSingleValue<PostcardMailingPostage>('postage') ?? 'standard';

  const sizeOptions = sizeSchema.options.map<InputCardOption>((value) => ({
    title: BrePostcardsMailingJob.sizeType(value),
    description: 'postcards',
    disabled: isNotAvailable({ count, paper, postage, size: value }),
    value,
  }));

  const paperOptions = paperSchema.options.map<InputCardOption>((value) => {
    return {
      title: BrePostcardsMailingJob.paperType(value),
      description: 'postcards',
      disabled: isNotAvailable({ count, paper: value, postage, size }),
      value,
    };
  });

  const postageOptions = postageSchema.options.map<InputCardOption>((value) => ({
    title: BrePostcardsMailingJob.postageType(value),
    description: 'postage',
    disabled: isNotAvailable({ count, paper, postage: value, size }),
    value,
  }));

  const designOptions = layoutSchema.options.map<InputCardOption>((value) => {
    const rate = BreDesignRate[value][`postcard_${size}`];

    return {
      title: `${capitalize(value)} Layout`,
      cost: rate,
      disabled: !rate,
      value,
    };
  });

  return (
    <FormGroup>
      <FormHorizontal name="count" label="Count" description="Min 250, max 7000" required>
        <ZodFieldInput name="count" type="number" />
      </FormHorizontal>
      <FormHorizontal name="size" label="Size" required>
        <ZodFieldCards name="size" options={sizeOptions} required />
      </FormHorizontal>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldCards name="paper" options={paperOptions} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="postage" label="Postage" required>
        <ZodFieldCards name="postage" options={postageOptions} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need by" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 2)}
        />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCards
          name="design"
          title="Design"
          description="Include layout design to your flyers"
          image="tenant/bre/print/design.jpg"
          persona={persona}
          options={designOptions}
          cols={2}
          required
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

function isNotAvailable({ count, paper, postage, size }: IsAvailableOptions): boolean {
  const hasPrintRate = BrePostcardsMailingRate[size][paper].some(isUpTo(count));
  const hasPostageRate = BrePostcardsPostageRate[size][postage];

  return !hasPrintRate || !hasPostageRate;
}
