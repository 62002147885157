import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export enum Mpi3dType {
  MATTERPORT = 'MATTERPORT',
  IGUIDE = 'IGUIDE',
}

export default createPerformableConfig(
  '3d',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.nativeEnum(Mpi3dType),
    }),
  },
  {
    name: '3D Walk Through',
    thumbnail: 'tenant/mpi/matterport/1.jpg',
    short: "3D rendering to virtually showcase your property's interior area and size.",
    tenant: TenantId.MPI,
    images: [],
    group: 'Digital Media',
  },
);
