import { Big } from 'big.js';
import BreJob from '~/tenants/bre/model/BreJob';
import BreAerialConfig, { AerialType } from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { match } from 'ts-pattern';
import { BreAerialPay, BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { TenantJobMedia } from '~/tenants/common/TenantJob';
import { BRE_COPYRIGHT_PHOTO_FEE, BRE_COPYRIGHT_VIDEO_FEE } from '~/tenants/bre/data';

export default class BreAerialJob extends BreJob<typeof BreAerialConfig> {
  get performable(): typeof BreAerialConfig {
    return BreAerialConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get submission(): boolean {
    const { type } = this.metadata;
    return type === 'video' || type === 'combo';
  }

  get deliverable(): DeliverableType {
    return DeliverableType.AERIAL;
  }

  get editing(): boolean {
    const { self_edited } = this.metadata;
    return this.submission && !self_edited;
  }

  get media(): TenantJobMedia {
    return {
      persona: this.editing ? PersonaType.EDITOR : PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.isOneOf(
      BreOrderType.RESIDENTIAL,
      BreOrderType.COMMERCIAL,
      BreOrderType.RENTAL,
      BreOrderType.LONG_TERM,
    );
  }

  get typeText(): string {
    const { type } = this.metadata;
    return BreAerialJob.typeText(type);
  }

  get copyrightFee(): Big {
    const { type } = this.metadata;
    const orderType = this.order.metadata.type;
    const hasVideo = type === 'video' || type === 'combo';
    const photos = match(type)
      .with('five', () => 5)
      .with('ten', () => 10)
      .with('video', () => 0)
      .with('combo', () => 10)
      .exhaustive();

    return new Big(BRE_COPYRIGHT_PHOTO_FEE[orderType])
      .times(photos)
      .plus(hasVideo ? BRE_COPYRIGHT_VIDEO_FEE[orderType] : '0');
  }

  static typeText(type: AerialType): string {
    return match(type)
      .with('five', () => '5 Photos')
      .with('ten', () => '10 Photos')
      .with('combo', () => '10 Photos + Video')
      .with('video', () => 'Video Only')
      .exhaustive();
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreAerialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'five',
    };
  }

  onsite(): number {
    const { type, twilight, poi } = this.metadata;
    const photoTime = match(type)
      .with('five', () => 25)
      .with('ten', () => 35)
      .with('video', () => 30)
      .with('combo', () => 40)
      .exhaustive();
    const twilightTime = twilight ? 25 : 0;
    const poiTime = poi && poi.length > 0 ? 20 : 0;

    return photoTime + twilightTime + poiTime;
  }

  revenueLines(): TenantJobLine[] {
    const { type, poi, rush, twilight } = this.metadata;
    const { COMBO, FIVE, POI, POI_EACH, RUSH, TEN, TWILIGHT, VIDEO } = BreAerialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];
    const rate = match(type)
      .with('five', () => FIVE)
      .with('ten', () => TEN)
      .with('video', () => VIDEO)
      .with('combo', () => COMBO)
      .exhaustive();

    if (rate) {
      lines.push({
        id: 'aerial_photo_video',
        description: this.typeText,
        amount: new Big(rate),
      });
    }

    if (this.isOneOf(BreOrderType.LONG_TERM)) {
      lines.push({
        id: 'copyright',
        description: 'Copyright',
        amount: this.copyrightFee,
      });
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Photos',
        amount: new Big(TWILIGHT),
      });
    }

    if (poi && poi.length > 0) {
      lines.push({
        id: 'poi',
        description: `Points of Interest (x${poi.length})`,
        amount: poi.length > 7 ? new Big(POI_EACH).times(poi.length - 7).plus(POI) : new Big(POI),
      });
    }

    if (rush && type === 'combo') {
      lines.push({
        id: 'rush_fee',
        description: 'Next-day video rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { type, self_edited, twilight } = this.metadata;
    const { COMBO, FIVE, TEN, TWILIGHT, VIDEO } = BreAerialPay[this.order.metadata.type];
    const lines: TenantJobLine[] = [];
    const pay = match(type)
      .with('five', () => FIVE)
      .with('ten', () => TEN)
      .with('video', () => VIDEO)
      .with('combo', () => COMBO)
      .exhaustive();
    const split = match(type)
      .with('five', 'ten', () => '0.65')
      .otherwise(() => (self_edited ? '0.5' : '0.7'));

    lines.push({
      id: 'aerial_photo_video',
      description: this.typeText,
      amount: new Big(pay).times(split),
    });

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Photos',
        amount: new Big(TWILIGHT),
      });
    }

    return lines;
  }
}
