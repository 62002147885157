import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreFoldersConfig from '~/tenants/bre/performable/print/folders/BreFoldersConfig';
import { BreFoldersPay, BreFoldersRate } from '~/tenants/bre/performable/print/folders/BreFoldersData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreFoldersJob extends BreJob<typeof BreFoldersConfig> {
  get performable(): typeof BreFoldersConfig {
    return BreFoldersConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get eligible(): boolean {
    return false;
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  isFreeDelivery(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreFoldersConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      count: '250',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count } = this.metadata;
    const rate = BreFoldersRate[count];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'folders_print',
        description: `Presentation folders (x${count})`,
        amount: new Big(rate),
        taxable: true,
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count } = this.metadata;
    const pay = BreFoldersPay[count];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'folders_print',
        description: `Presentation folders (x${count})`,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}
