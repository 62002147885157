import {
  TWT_AERIAL_BOUNDARY_PHOTO,
  TWT_AERIAL_BOUNDARY_VIDEO,
  TWT_AERIAL_PIN_PHOTO,
} from '~/tenants/twt/performable/aerial/TwtAerialJob';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TenantPackageAddOnGeneric, TenantPackageAddOnRepeat } from '~/tenants/common/package';
import React from 'react';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldFile from '~/components/zod/ZodFieldFile';
import { useParams } from 'react-router';

export default function TwtAerialAddOns({ persona, commercial }: { persona: PersonaType; commercial?: boolean }) {
  const { order } = useParams();

  const type = useZodFormFieldSingleValue<TwtAerialType>('type');
  const photoLines = useZodFormFieldSingleValue('boundaries_photo');
  const videoLines = useZodFormFieldSingleValue('boundaries_video');

  let types = <></>;

  if (type === TwtAerialType.VIDEO) {
    types = <ZodFieldHidden name="type" value="video" />;
  } else if (type === TwtAerialType.PHOTO_VIDEO) {
    types = (
      <div className="w-24">
        <ZodFieldSelect
          name="type"
          options={[
            {
              label: 'Both',
              value: 'both',
            },
            {
              label: 'Photo',
              value: 'photo',
            },
            {
              label: 'Video',
              value: 'video',
            },
          ]}
        />
      </div>
    );
  } else {
    types = <ZodFieldHidden name="type" value="photo" />;
  }

  const plus = commercial ? 5 : 0;

  return (
    <>
      <TenantPackageAddOnRepeat
        persona={persona}
        name="pin_text"
        image={'tenant/twt/aerial/pin_text_combo.jpg'}
        title="Pin/Text Combos"
        cost={TWT_AERIAL_PIN_PHOTO + plus}
        lightbox
        description="Order Pin+Text Combos to add identifying markers on aerial photo(s). Let us know how many photos you would like the pin+text combo added to and the text details below. Provide us with the Text/Pin Location(s):"
      >
        <div className="flex items-center space-x-2">
          <ZodFieldInput name="location" placeholder="Location and/or Address" />

          {types}
        </div>
      </TenantPackageAddOnRepeat>

      <TenantPackageAddOnGeneric
        persona={persona}
        image={'tenant/twt/aerial/boundary.jpg'}
        title="Boundary Lines"
        cost={(type === TwtAerialType.VIDEO ? TWT_AERIAL_BOUNDARY_VIDEO : TWT_AERIAL_BOUNDARY_PHOTO) + plus}
        lightbox
        description="Utilizing a survey you provide, we will add property lines to the aerial photo(s) of your choice. Please note that the lines are an approximation. Let us know the number of images you would like edited."
      >
        <FormGroup>
          {type !== TwtAerialType.VIDEO && (
            <FormHorizontal label={`Photos ($${TWT_AERIAL_BOUNDARY_PHOTO + plus})`}>
              <ZodFieldInput name="boundaries_photo" type="number" placeholder="# of photos with boundary lines" />
            </FormHorizontal>
          )}
          {type && [TwtAerialType.PHOTO_VIDEO, TwtAerialType.VIDEO].includes(type) && (
            <FormHorizontal label={`Video Boundary Lines ($${TWT_AERIAL_BOUNDARY_VIDEO + plus})`}>
              <ZodFieldInput name="boundaries_video" type="number" placeholder="# of boundary lines appearances" />
            </FormHorizontal>
          )}
        </FormGroup>
        {order && (
          <div className={!photoLines && !videoLines ? 'hidden' : ''}>
            <FormHorizontal label="Survey">
              <ZodFieldFile name="survey" prefix={`order/${order}/buyer`} />
            </FormHorizontal>
          </div>
        )}
      </TenantPackageAddOnGeneric>
    </>
  );
}
