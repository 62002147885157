import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostRate } from '~/tenants/bre/performable/boost/BreBoostData';

export default class BreBoostJob extends BreJob<typeof BreBoostConfig> {
  get performable(): typeof BreBoostConfig {
    return BreBoostConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.RESIDENTIAL, BreOrderType.COMMERCIAL, BreOrderType.PROPERTY_MKTG);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreBoostConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      reach: '3,000',
      start_at: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { reach } = this.metadata;
    const rate = BreBoostRate[reach];
    const lines: TenantJobLine[] = [];

    if (reach) {
      lines.push({
        id: 'boost_reach',
        description: `${reach} Potencial Reach`,
        amount: new Big(rate),
        discountable: this.matchSocialPackage,
      });
    }

    return lines;
  }
}
