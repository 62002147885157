import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import { BreStagingRate } from '~/tenants/bre/performable/staging/BreStagingData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnInput } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const STYLE_OPTS: InputSelectOption[] = [
  { label: 'Modern', value: 'modern' },
  { label: 'Scandinavian', value: 'scandinavian' },
  { label: 'Contemporary', value: 'contemporary' },
  { label: 'Hamptons', value: 'hamptons' },
  { label: 'Traditional', value: 'traditional' },
  { label: 'Farmhouse', value: 'farmhouse' },
  { label: 'Urban/Industrial', value: 'urban' },
  { label: 'Outdoor Patio', value: 'outdoor' },
];

export default function BreStagingForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { ITEM_REMOVAL, SPACE_RENOVATION } = BreStagingRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="style" label="Style" required>
        <ZodFieldSelect name="style" options={STYLE_OPTS} />
      </FormHorizontal>
      <FormHorizontal name="quantity" label="Quantity" required>
        <ZodFieldInput name="quantity" type="number" />
      </FormHorizontal>

      <TenantPerformableAddOns
        persona={persona}
        title="Virtual Renovation"
        subtitle="Digitally enhances photos to highlight potential property improvements."
      >
        <TenantPackageAddOnInput
          name="item_removal"
          title="Item Removal"
          description="Remove large items from a space (bed, couch, dresser)"
          label="Images"
          image="tenant/bre/staging/item_removal_v2.jpg"
          persona={persona}
          cost={ITEM_REMOVAL}
          costSuffix="/each"
          inputType="number"
        />
        <TenantPackageAddOnInput
          name="space_renovation"
          title="Space Renovation"
          description="Complete space renovation (cabinets, flooring, countertops)"
          label="Images"
          image="tenant/bre/staging/space_renovation_v2.jpg"
          persona={persona}
          cost={SPACE_RENOVATION}
          costSuffix="/each"
          inputType="number"
        />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
