import { Big } from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { toNeedBy } from '~/tenants/bre/performable/common';
import BreFlyersConfig from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import BreRidersConfig, { RiderShipping } from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import { BreRidersPay, BreRidersRate } from '~/tenants/bre/performable/print/riders/BreRidersData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreRidersJob extends BreJob<typeof BreRidersConfig> {
  get performable(): typeof BreRidersConfig {
    return BreRidersConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.RESIDENTIAL, BreOrderType.COMMERCIAL, BreOrderType.PROPERTY_MKTG);
  }

  get shippingType(): string {
    const { shipping } = this.metadata;
    return BreRidersJob.shippingType(shipping);
  }

  get shippingFee(): string {
    const { shipping } = this.metadata;
    return BreRidersJob.shippingFee(shipping);
  }

  get hasSameDayFlyers(): boolean {
    return BreRidersJob.hasSameDayFlyers(this.order);
  }

  static shippingType(shipping: RiderShipping): string {
    return match(shipping)
      .with('courier', () => '2-Days Courier')
      .with('ups', () => '3-Days UPS')
      .exhaustive();
  }

  static shippingFee(shipping: RiderShipping): string {
    const { COURIER, UPS } = BreRidersRate;

    return match(shipping)
      .with('courier', () => COURIER)
      .with('ups', () => UPS)
      .exhaustive();
  }

  static hasSameDayFlyers(order: BreOrderContext): boolean {
    return order.jobs.some(({ metadata, performable_id }) => {
      return performable_id === BreFlyersConfig.id && toNeedBy(metadata.need_by) === 'same_day';
    });
  }

  isDelivery(): boolean {
    return this.metadata.shipping === 'courier';
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreRidersConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      shipping: 'courier',
      holes: '2',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { RIDER } = BreRidersRate;
    const { holes } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (RIDER) {
      lines.push({
        id: 'riders_print',
        description: `24" x 6" Yard Sign Rider (${holes} holes)`,
        amount: new Big(RIDER),
        taxable: true,
      });
    }

    if (!this.hasSameDayFlyers) {
      lines.push({
        id: 'shipping_fee',
        description: `${this.shippingType} shipping`,
        amount: new Big(this.shippingFee),
        fee: 'delivery',
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { COURIER } = BreRidersPay;
    const { holes, shipping } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (COURIER) {
      lines.push({
        id: 'riders_print',
        description: `24" x 6" Yard Sign Rider (${holes} holes)`,
        amount: new Big(COURIER),
      });
    }

    return lines;
  }
}
