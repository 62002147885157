import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRate = {
  SLIDESHOW: '50',
};

export const BreSlideshowRate: Record<BreOrderType, typeof BaseRate> = {
  [BreOrderType.COMMERCIAL]: BaseRate,
  [BreOrderType.RENTAL]: BaseRate,
  [BreOrderType.LONG_TERM]: BaseRate,
  [BreOrderType.RESIDENTIAL]: BaseRate,
  [BreOrderType.PROPERTY_MKTG]: BaseRate,
  [BreOrderType.AGENT_MKTG]: BaseRate,
};
