import Heading from '~/components/layout/Heading';
import React, { ReactElement, ReactNode, useId, useState } from 'react';
import IconCheck from '~/components/icons/streamline/line/IconCheck';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { format_money } from '~/components/Money';
import ResponsiveImage from '~/components/ResponsiveImage';
import {
  useZodFormFieldArray,
  useZodFormFieldMultiple,
  useZodFormFieldSingle,
  useZodFormFieldSingleValue,
  ZodNestedForm,
} from '~/components/zod/ZodForm';
import InputCheckbox from '~/components/input/InputCheckbox';
import Link from '~/components/interactive/Link';
import IconAdd from '~/components/icons/streamline/line/IconAdd';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import Button from '~/components/interactive/Button';
import Modal, { ModalHeader } from '~/components/interactive/Modal';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import IconVisible from '~/components/icons/streamline/line/IconVisible';
import classNames from 'classnames';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckboxMultiple from '~/components/zod/ZodFieldCheckboxMultiple';
import { PersonaType } from '~/tenants/common/TenantPersona';
import InputText from '~/components/input/InputText';
import IconArrowDown from '~/components/icons/streamline/IconArrowDown';
import IconArrowUp from '~/components/icons/streamline/IconArrowUp';
import ResizedThumbnail from '~/components/cdn/ResizedThumbnail';
import { InputCardOption } from '~/components/input/InputCards';
import { Row } from '~/components/layout/Sections';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { LibraryImage } from '~/routes/api+/libraries';

interface TenantPackageAddOnTemplateProps {
  image: string | null;
  lightbox?: boolean;
  disabled?: boolean;
  description?: string;
  persona: PersonaType;
  children?: React.ReactNode;
  title: string;
  examples?: [string, ...string[]];
}

export function TenantPackageAddOnTemplate({
  children,
  image,
  name,
  title,
  lightbox,
  cost,
  enable,
  onClick,
  disabled,
  persona,
  htmlFor,
  examples,
  description,
}: TenantPackageAddOnTemplateProps & {
  name?: string;
  htmlFor?: string;
  enable?: React.ReactNode;
  cost?: string;
  onClick?: () => void;
}) {
  const [show, setShow] = useState(false);

  return (
    <div
      className={classNames('flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6', {
        'grayscale pointer-events-none opacity-50': disabled,
      })}
    >
      {persona === PersonaType.CUSTOMER && image !== null && (
        <div className="rounded-lg overflow-hidden flex-shrink-0 ring-theme-separator ring-1">
          <Modal
            show={show}
            onClose={() => {
              setShow(false);
            }}
          >
            <ResponsiveImage
              path={image}
              width={900}
              className="rounded-theme"
              onClick={() => {
                setShow(false);
              }}
            />
          </Modal>
          <ResponsiveImage
            path={image}
            width={200}
            height={133}
            onClick={() => {
              if (lightbox) {
                setShow(true);
              }

              onClick?.();
            }}
            className={classNames({
              'opacity-50': disabled,
              'cursor-pointer': onClick || lightbox,
              'hover:scale-105 transition': onClick,
            })}
          />
        </div>
      )}
      <div className="w-full">
        <Row between className="leading-none select-none">
          {enable && <div className="w-8">{enable}</div>}
          <label
            className={classNames('text-lg font-medium flex-1', {
              'cursor-pointer': enable,
            })}
            htmlFor={htmlFor ?? name}
          >
            {title}
          </label>
          {cost && <p className="font-bold text-base whitespace-nowrap">{cost}</p>}
          <div className="text-nowrap">{examples && <PackageExamples images={examples} />}</div>
        </Row>
        <div className="pt-2 max-sm:pr-4">
          {persona === PersonaType.CUSTOMER && description && (
            <p className="text-sm text-gray-600 pb-2">{description}</p>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

function NeighborhoodSelection({ name, groups }: { name: string; groups: TenantPackageGroup[] }) {
  const [preview, setPreview] = useState<LibraryImage>();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const searched = (search ? groups.filter((g) => g.name.toLowerCase().includes(search.toLowerCase())) : groups).slice(
    0,
    10,
  );

  return (
    <div className="relative rounded-theme overflow-hidden bg-white">
      {preview && (
        <div className="p-inner space-y-3 flex flex-col items-center justify-between absolute w-full h-full z-100 bg-white overflow-hidden rounded-theme">
          <div className="flex-1 flex items-center w-full">
            <ResizedThumbnail file={preview} round className="max-h-80 w-full" width={650} height={300} medium />
          </div>
          <div className="flex w-full justify-between space-x-unrelated">
            <Button type="button" variant="outline" onClick={() => setPreview(undefined)}>
              Back to Gallery
            </Button>
          </div>
        </div>
      )}
      <ModalHeader>Neighborhood Search</ModalHeader>
      <div className="p-4 space-y-2 border-theme-separator border-b">
        <p className="text-sm font-medium">
          There are {groups.length} neighborhoods for you to choose from, narrow the list by typing in the search bar
          below.
        </p>
        <InputText
          placeholder="Search by postal code or name"
          onChange={(v) => {
            setSearch(v ?? '');
          }}
        />
      </div>
      <div className="bg-gray-50 overflow-y-scroll h-96 divide-y divide-theme-separator select-none">
        {searched.map((group) => (
          <div key={group.id}>
            <div
              className="py-2 px-4 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer"
              onClick={(e) => {
                if (e.target instanceof Element && e.target.tagName.toLowerCase() === 'input') {
                  return;
                }

                expanded.includes(group.id)
                  ? setExpanded(expanded.filter((e) => e !== group.id))
                  : setExpanded([...expanded, group.id]);
              }}
            >
              <ZodFieldCheckboxMultiple name={name} value={group.id} />
              <p className="font-medium flex-1">{group.name}</p>
              <div className="w-6 h-3 pointer-events-none">
                {expanded.includes(group.id) ? <IconArrowUp /> : <IconArrowDown />}
              </div>
            </div>
            {expanded.includes(group.id) && (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 p-inner">
                {group.images.map((image) => (
                  <ResizedThumbnail
                    file={image}
                    onClick={() => {
                      setPreview(image);
                    }}
                    className="cursor-pointer hover:scale-105 transition rounded-theme"
                    width={150}
                    height={100}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

// TODO: Use this across the other functions
export function GalleryExamples({
  name,
  persona,
  images,
}: {
  name: string;
  persona: PersonaType;
  images: [string, ...string[]];
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      {persona === PersonaType.CUSTOMER && (
        <div className="pt-2">
          <Link onClick={() => setShow(true)} icon={<IconVisible />}>
            View Examples
          </Link>
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <PackageGallery images={images} />
      </Modal>
    </>
  );
}

export function PackageExamples({ images }: { images: [string, ...string[]] }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Link onClick={() => setShow(true)} icon={<IconVisible />}>
        View Examples
      </Link>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <PackageGallery images={images} />
      </Modal>
    </>
  );
}

export function PackageGallery({
  images,
  onSelect,
}: {
  images: [string, ...string[]];
  onSelect?: (image: string) => void;
}) {
  const [preview, setPreview] = useState<string>();

  return (
    <div className="relative rounded-theme overflow-hidden bg-white">
      {preview && (
        <div className="p-inner space-y-3 flex flex-col items-center justify-between absolute w-full h-full z-100 bg-white overflow-hidden rounded-theme">
          <div className="flex-1 flex items-center w-full">
            <ResponsiveImage path={preview} round className="max-h-80 w-full" width={650} height={300} />
          </div>
          <div className="flex w-full justify-between space-x-unrelated">
            <Button type="button" variant="outline" onClick={() => setPreview(undefined)}>
              {onSelect ? 'Cancel' : 'Back to Gallery'}
            </Button>
            {onSelect && (
              <Button
                onClick={() => {
                  setPreview(undefined);

                  onSelect?.(preview);
                }}
              >
                Select Photo
              </Button>
            )}
          </div>
        </div>
      )}
      <ModalHeader>
        Scroll through the following images and click to preview{onSelect ? ' and select' : ''}.
      </ModalHeader>
      <div className="overflow-y-scroll h-96">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 p-inner">
          {images.map((image) => (
            <ResponsiveImage
              key={image}
              path={image}
              onClick={() => {
                setPreview(image);
              }}
              className="cursor-pointer hover:scale-105 transition rounded-theme"
              width={150}
              height={100}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function TenantPackageAddOnGalleryExamplesNumber({
  children,
  images,
  name,
  cost,
  value,
  disabled,
  ...template
}: Omit<TenantPackageAddOnTemplateProps, 'image'> & {
  name: string;
  images: [string, ...string[]];
  value?: string;
  disabled?: boolean;
  cost?: string | number;
}) {
  const [_name, count, setCount] = useZodFormFieldSingle(name);
  const checked = Number(count) > 0;
  const [show, setShow] = useState(false);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      image={images[0]}
      disabled={disabled}
      cost={cost ? `${format_money(cost)}/each` : undefined}
      enable={
        <InputCheckbox
          checked={checked}
          onChange={() => {
            if (checked) {
              setCount(null);
            } else {
              setCount('1');
            }
          }}
        />
      }
      onClick={() => setShow(true)}
    >
      {children}
      {checked ? (
        <FormHorizontal label="Quantity">
          <ZodFieldInput type="number" name={name} />
        </FormHorizontal>
      ) : template.persona === PersonaType.CUSTOMER ? (
        <div className="pt-2">
          <Link onClick={() => setShow(true)} icon={<IconVisible />}>
            View Examples
          </Link>
          <ZodFieldHidden name={name} value="0" />
        </div>
      ) : (
        <ZodFieldHidden name={name} value="0" />
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <PackageGallery images={images} />
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnGalleryExamplesCheckbox({
  children,
  images,
  name,
  cost,
  value,
  force = true,
  disabled,
  ...template
}: Omit<TenantPackageAddOnTemplateProps, 'image'> & {
  name: string;
  force?: boolean;
  images: [string, ...string[]];
  value?: string;
  disabled?: boolean;
  cost?: string | number;
}) {
  const [show, setShow] = useState(false);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      name={name}
      image={images[0]}
      disabled={disabled}
      cost={cost ? format_money(cost) : undefined}
      enable={<ZodFieldCheckbox id={name} name={name} value={value} force={force} disabled={disabled} />}
      onClick={() => setShow(true)}
    >
      {children}
      {template.persona === PersonaType.CUSTOMER && (
        <div className="pt-2">
          <Link onClick={() => setShow(true)} icon={<IconVisible />}>
            View Examples
          </Link>
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <PackageGallery images={images} />
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}

export interface TenantPackageGroup {
  id: string;
  name: string;
  images: LibraryImage[];
}

export function TenantPackageAddOnNeighborhoods({
  children,
  groups,
  name,
  cost,
  value,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  groups: TenantPackageGroup[];
  cost?: string | number;
  value?: string;
}) {
  const [_name, selected, setSelected] = useZodFormFieldMultiple(name);
  const [show, setShow] = useState(false);
  const checked = selected.length > 0;

  return (
    <TenantPackageAddOnTemplate
      {...template}
      cost={cost ? format_money(cost) : undefined}
      enable={
        <InputCheckbox
          checked={checked}
          disabled={template.disabled}
          onChange={() => {
            if (checked) {
              setSelected([]);
            } else {
              setShow(true);
            }
          }}
        />
      }
    >
      {children}
      {checked && (
        <div className="pt-2 space-y-1">
          {selected.map((group, index) => (
            <div className="flex items-center space-x-3" key={group}>
              <div>
                <Button
                  icon={<IconDelete />}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelected(selected.filter((s) => s !== group));
                  }}
                  variant="transparent"
                />
              </div>
              <div className="flex-grow">{groups.find((g) => g.id === group)?.name}</div>
              <ZodFieldHidden name={`${name}[${index}]`} value={group} />
            </div>
          ))}
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <NeighborhoodSelection name={name} groups={groups} />
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnGallerySingleCheckbox({
  children,
  images,
  name,
  cost,
  value,
  ...template
}: Omit<TenantPackageAddOnTemplateProps, 'image'> & {
  name: string;
  images: [string, ...string[]];
  cost?: string | number;
  value?: string;
}) {
  const [_name, selected, setSelected] = useZodFormFieldSingle(name);
  const [show, setShow] = useState(false);
  const checked = Boolean(selected);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      image={selected ?? images[0]}
      cost={cost ? format_money(cost) : undefined}
      enable={
        <InputCheckbox
          checked={checked}
          disabled={template.disabled}
          onChange={() => {
            if (checked) {
              setSelected(null);
            } else {
              setShow(true);
            }
          }}
        />
      }
    >
      {children}
      {selected && (
        <div className="pt-2">
          <Button onClick={() => setShow(true)} variant="outline">
            Change Selection
          </Button>
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <PackageGallery
          images={images}
          onSelect={(image) => {
            setSelected(image);
            setShow(false);
          }}
        />
      </Modal>
      <ZodFieldHidden name={name} value={selected ? value ?? selected : undefined} />
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnGeneric({
  cost,
  ...template
}: TenantPackageAddOnTemplateProps & {
  cost?: string | number;
}) {
  return <TenantPackageAddOnTemplate {...template} cost={cost ? format_money(cost) : undefined} />;
}

export function TenantPackageAddOnRepeat({
  children,
  name,
  cost,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  cost?: string | number;
}) {
  const [items, add, clear] = useZodFormFieldArray(name);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      name={name}
      cost={cost ? `${format_money(cost)}/each` : undefined}
      enable={
        <InputCheckbox
          id={name}
          checked={items.length > 0}
          onChange={() => {
            if (items.length === 0) {
              add({});
            } else {
              clear();
            }
          }}
        />
      }
    >
      <div className="space-y-2 pt-2">
        {items.map((item) => (
          <ZodNestedForm name={item.name} key={item.name}>
            <div className="flex items-center space-x-3">
              <div>
                <Button
                  icon={<IconDelete />}
                  onClick={(e) => {
                    e.preventDefault();
                    item.remove();
                  }}
                  variant="transparent"
                />
              </div>
              <div className="flex-grow">{children}</div>
            </div>
          </ZodNestedForm>
        ))}
        {items.length > 0 && (
          <div className="text-right">
            <Link icon={<IconAdd />} onClick={add}>
              Add Another
            </Link>
          </div>
        )}
      </div>
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnInput({
  name,
  cost,
  label,
  costSuffix = '',
  inputType,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  label: string;
  cost?: string | number;
  costSuffix?: string;
  inputType?: string;
}) {
  const [_name, value, setValue] = useZodFormFieldSingle(name);
  const [checked, setChecked] = useState(typeof value === 'string' && value.length > 0);

  return (
    <TenantPackageAddOnTemplate
      name={name}
      {...template}
      cost={cost ? `${format_money(cost)}${costSuffix}` : undefined}
      enable={
        <InputCheckbox
          id={name}
          checked={checked}
          onChange={() => {
            if (checked) {
              setValue(null);
              setChecked(false);
            } else {
              setChecked(true);
            }
          }}
        />
      }
    >
      {checked ? (
        <FormHorizontal label={label}>
          <ZodFieldInput name={name} type={inputType} />
        </FormHorizontal>
      ) : (
        <ZodFieldHidden name={name} value={value} />
      )}
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnCheckboxName({
  name,
  cost,
  costEach,
  value,
  children,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  cost?: string | number;
  costEach?: boolean;
  value?: string;
}) {
  const id = `${name}-${value}`;
  const price = costEach ? `${format_money(cost)}/each` : format_money(cost);
  const checked = useZodFormFieldSingleValue(name);

  return (
    <TenantPackageAddOnTemplate
      htmlFor={id}
      name={name}
      {...template}
      cost={cost ? price : undefined}
      enable={<ZodFieldCheckbox id={id} name={name} value={value} force={false} />}
    >
      {checked === 'true' && children}
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnCheckbox({
  cost,
  costEach,
  children,
  ...template
}: TenantPackageAddOnTemplateProps & {
  cost?: string | number;
  costEach?: boolean;
}) {
  const price = costEach ? `${format_money(cost)}/each` : format_money(cost);
  const [show, setShow] = useState(false);
  const id = useId();

  return (
    <TenantPackageAddOnTemplate
      htmlFor={id}
      {...template}
      cost={cost ? price : undefined}
      enable={
        <InputCheckbox
          id="id"
          checked={show}
          onChange={(checked) => {
            setShow(checked);
          }}
        />
      }
    >
      {show && children}
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnCards({
  name,
  children,
  options,
  cost,
  cols,
  required,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  options: InputCardOption[];
  cost?: string;
  cols?: 1 | 2 | 3;
  required?: boolean;
}): ReactElement {
  const [, value, setValue] = useZodFormFieldSingle(name);
  const id = useId();

  const [show, setShow] = useState(value !== undefined);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      htmlFor={`${name}-${id}`}
      cost={cost ? format_money(cost) : undefined}
      enable={
        <InputCheckbox
          id={`${name}-${id}`}
          checked={show}
          onChange={(checked) => {
            setShow(checked);

            if (!checked) {
              setValue(null);
            }
          }}
        />
      }
    >
      {show && (
        <div className="flex flex-col gap-2 pt-1">
          <ZodFieldCards
            name={name}
            options={options}
            default={value ?? options[0].value}
            required={required}
            cols={cols}
          />
          {children}
        </div>
      )}
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageInclude(props: {
  image?: string;
  itemNum?: number;
  name: string;
  children?: ReactNode;
}): ReactElement {
  return (
    <li className="flex items-center space-x-3">
      {props.image ? (
        <div className="rounded-lg overflow-hidden border-theme-separator border flex-shrink-0">
          <ResponsiveImage path={props.image} width={100} height={67} />
        </div>
      ) : (
        <div className="w-6" />
      )}

      <span className="flex flex-col space-y-1">
        <span className="text-base font-bold flex items-center space-x-1">
          <span className="w-6 h-6 inline-block">{props.itemNum ? <span>{props.itemNum}.</span> : <IconCheck />}</span>{' '}
          <span>{props.name}</span>
        </span>
        {props.children &&
          (typeof props.children === 'string' ? (
            <span className="text-sm text-gray-600">{props.children}</span>
          ) : (
            <div className="flex flex-col gap-2">{props.children}</div>
          ))}
      </span>
    </li>
  );
}

export function TenantPackageIncludes({ children }: { children?: React.ReactNode }) {
  return (
    <div className="space-y-5">
      <Heading title="What's included">The following products and services are included in this package:</Heading>

      <ul className="space-y-5">{children}</ul>
    </div>
  );
}
