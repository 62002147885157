import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type CoverCount = z.infer<typeof countSchema>;

export const countSchema = z.enum([
  '100',
  '250',
  '500',
  '1000',
]);

export default createPerformableConfig(
  'covers',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: countSchema,
      need_by: zodTDateISODate('Need By date is required'),
    }),
  },
  {
    name: 'Folders Covers',
    group: 'Agent Marketing',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '2PG UV coated super-heavy Gloss 16pt. folder cover 4/0',
  },
);
