import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';

export type SignType = z.infer<typeof typeSchema>;

export type SignShipping = z.infer<typeof shippingSchema>;

export const typeSchema = z.enum([
  'sign_24x24',
  'sign_24x18',
  'aframe_24x24',
]);

export const shippingSchema = z.enum(['courier', 'ups']);

export default createPerformableConfig(
  'signs',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: typeSchema,
      shipping: shippingSchema,
      need_by: zodTDateISODate('Need By date is required'),
    }),
  },
  {
    name: 'Agent Yard/A-Frame Signs',
    group: 'Agent Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/signs/thumbnail.jpg',
    images: [],
    short: 'For Sale signs and Open House A-Frames.',
  },
);
