import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import { MpiPhotosDetailTime } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { format_money } from '~/components/Money';
import { MPI_PHOTO_DETAIL_15, MPI_PHOTO_DETAIL_30 } from '~/tenants/mpi/performable/photos/MpiPhotosJob';
import range from 'lodash.range';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { Column } from '~/components/layout/Sections';

const images: [string, ...string[]] = [
  'tenant/mpi/detail/2.jpg',
  ...range(3, 4).map((i) => `/tenant/mpi/detail/${i}.jpg`),
];

export default function MpiPhotosDetail({ persona }: Pick<TenantPerformableFormProps<MpiOrderContext>, 'persona'>) {
  return (
    <TenantPackageAddOnCheckbox
      persona={persona}
      image="/tenant/mpi/detail/1.jpg"
      examples={images}
      title="Detail Photography"
      description="Do you have an area you'd like detail shots of? Up close shots to showcase certain features of the home. Example: Clawfoot Tub, Wolfe Stove"
    >
      <Column gap>
        <FormHorizontal label="Package">
          <ZodFieldSelect
            clearOnUnmount
            options={[
              {
                label: `4 to 8 images (15 Minutes) +${format_money(MPI_PHOTO_DETAIL_15)}`,
                value: MpiPhotosDetailTime.MINUTES_15,
              },
              {
                label: `9 to 16 images (30 Minutes) +${format_money(MPI_PHOTO_DETAIL_30)}`,
                value: MpiPhotosDetailTime.MINUTES_30,
              },
            ]}
            name="detail"
          />
        </FormHorizontal>
        <FormHorizontal label="Instructions">
          <ZodFieldTextarea name="detail_notes" clearOnUnmount />
        </FormHorizontal>
      </Column>
    </TenantPackageAddOnCheckbox>
  );
}
