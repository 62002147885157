import { TenantProvider } from '../../common/TenantPersona';
import { MpiOrderContext, MpiOrderType } from '~/tenants/mpi/model/MpiOrder';
import MpiPhotosConfig, { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiAerialConfig from '../performable/aerial/MpiAerialConfig';
import MpiBrochuresConfig from '../performable/brochures/MpiBrochuresConfig';
import MpiFloorplanConfig from '../performable/floorplan/MpiFloorplanConfig';
import MpiVirtualStagingConfig from '../performable/staging/MpiVirtualStagingConfig';
import MpiHeadshotConfig from '~/tenants/mpi/performable/headshot/MpiHeadshotConfig';
import Mpi3dConfig from '~/tenants/mpi/performable/3d/Mpi3dConfig';
import MpiFlyerConfig from '~/tenants/mpi/performable/flyer/MpiFlyerConfig';
import MpiVirtualConfig from '~/tenants/mpi/performable/virtual/MpiVirtualConfig';
import MpiWalkthroughConfig from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughConfig';

export enum MpiProviderId {
  HOULTON_MAHANEY = 1,
  DAN_WEINEL = 2,
  ALEX_GREGORY = 3,
  SOPHIA_BEDNARIK = 4,
  TIFFANY_LANG = 5,
  STEPHEN_POSKO = 6,
  BRAYLAN_DODSON = 7,
  CHAY_DEAVER = 8,
  BRIAN_WOODS = 9,
  MIKE_DAVIS = 10,
  NICK_KIPKE = 11,
  NIK_TYLER = 12,
}

export interface MpiProviderStaticData {
  expense_photos?: number;
  expense_appointment?: number;

  performable_event?: boolean;
  performable_headshot?: boolean;
  performable_commercial?: boolean;
  performable_walkthrough?: boolean;
  performable_floorplan?: boolean;
  performable_aerial?: boolean;
  performable_3d?: boolean;
}

export default class MpiProvider extends TenantProvider<MpiProviderStaticData> {
  canPerform(context: MpiOrderContext, job: MpiOrderContext['jobs'][0]): true | string {
    if (
      job.performable_id === MpiPhotosConfig.id &&
      job.metadata.type === MpiPhotosType.EVENT &&
      !this.config.performable_event
    ) {
      return 'This provider does not shoot events.';
    }

    if (job.performable_id === MpiHeadshotConfig.id && !this.config.performable_headshot) {
      return 'This provider does not do headshots.';
    }

    if (context.metadata.type === MpiOrderType.COMMERCIAL && !this.config.performable_commercial) {
      return 'This provider does not do commercial orders.';
    }

    return true;
  }

  get performableIds(): string[] {
    return [
      MpiPhotosConfig.id,
      MpiAerialConfig.id,
      MpiBrochuresConfig.id,
      MpiFloorplanConfig.id,
      Mpi3dConfig.id,
      MpiVirtualStagingConfig.id,
      MpiFlyerConfig.id,
      MpiHeadshotConfig.id,
      MpiVirtualConfig.id,
      MpiWalkthroughConfig.id,
    ];
  }
}
