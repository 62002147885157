import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import dedent from 'dedent';
import { consentSchema } from '~/tenants/bre/performable/common';

export default createPerformableConfig(
  'floorplan',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      rush: coerceBoolean().optional(),
      disclosure: consentSchema(),
    }),
  },
  {
    name: '2D Color Floorplan',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/floorplan/thumbnail_v2.jpg',
    images: [],
    short: dedent`
      Enhance your property listings with professional, Earth-Tone color \
      accurate floor plans that showcase room dimensions and total square \
      footage. This service provides a polished and informative layout, helping \
      buyers visualize the space while adding value to your marketing materials. \
      Pricing varies by square footage, ensuring a cost-effective solution \
      tailored to each property.
    `,
  },
);
