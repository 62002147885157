import { Big } from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';
import { BrePortraitsRate } from '~/tenants/bre/performable/portraits/BrePortraitsData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BrePortraitsJob extends BreJob<typeof BrePortraitsConfig> {
  get performable(): typeof BrePortraitsConfig {
    return BrePortraitsConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.AGENT_MKTG);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePortraitsConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'studio',
    };
  }

  onsite(): number {
    const { type, extra_pose } = this.metadata;
    const shootTime = match(type)
      .with('onsite', () => 90)
      .with('studio', () => 60)
      .exhaustive();
    const extraPoseTime = extra_pose && type !== 'onsite' ? 90 : 0;

    return shootTime + extraPoseTime;
  }

  revenueLines(): TenantJobLine[] {
    const { type, extra_edit, extra_pose } = this.metadata;
    const { EXTRA_EDIT, EXTRA_POSE, ONSITE, STUDIO } = BrePortraitsRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    match(type)
      .with('studio', () => {
        lines.push({
          id: 'headshots',
          description: 'In Studio Photo Shoot',
          amount: new Big(STUDIO),
        });
      })
      .with('onsite', () => {
        lines.push({
          id: 'headshots',
          description: 'Lifestyle Photo Session',
          amount: new Big(ONSITE),
        });
      })
      .exhaustive();

    if (extra_edit) {
      lines.push({
        id: 'extra_edit',
        description: 'Addtitional Edited Photo',
        amount: new Big(EXTRA_EDIT),
      });
    }

    if (extra_pose) {
      lines.push({
        id: 'extra_pose',
        description: 'Additional Lifestyle Photo',
        amount: new Big(EXTRA_POSE),
      });
    }

    return lines;
  }
}
