import { ReactElement, useMemo } from 'react';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';
import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import { VOICE_OPTS } from '~/tenants/bre/performable/common';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCards, TenantPackageAddOnCheckboxName } from '~/tenants/common/package';

interface BreCinematicAddOnsProps {
  context: BreOrderContext;
  persona: PersonaType;
}

export default function BreCinematicAddOns({ context, persona }: BreCinematicAddOnsProps): ReactElement {
  const { AERIAL_PHOTOS, INTRO, KWASI, NARRATION, TWILIGHT } = BreCinematicRate[context.metadata.type];

  const duration = useZodFormFieldSingleValue('duration', (v) => (v ? Number(v) : undefined));
  const narration = useZodFormFieldSingleValue('narration', (v) => v === 'true');

  const aerialCost = useMemo((): string | undefined => {
    if (context.metadata.type !== BreOrderType.AGENT_MKTG && context.metadata.type !== BreOrderType.PROPERTY_MKTG) {
      const { sqft, type } = context.metadata;
      const normalRate = BreCinematicJob.rate({ duration, sqft, type });
      const aerialRate = BreCinematicJob.rate({ aerial_upgrade: true, duration, sqft, type });

      return aerialRate.minus(normalRate).toFixed();
    }

    return undefined;
  }, [context.metadata, duration]);

  return (
    <>
      <TenantPackageAddOnCheckboxName
        name="aerial_upgrade"
        title="Aerial Upgrade"
        description="Adds aerial to the Cinematic Video and 5 aerial photos (Must meet FAA area rules for the address)"
        image="tenant/bre/cinematic/aerial_upgrade.jpg"
        persona={persona}
        cost={aerialCost}
      />
      <TenantPackageAddOnCheckboxName
        name="intro"
        title="Agent Intro"
        description="Add agent on camera intro."
        image="tenant/bre/cinematic/intro.jpg"
        persona={persona}
        cost={INTRO}
      />
      <TenantPackageAddOnCheckboxName
        name="narration"
        title="Agent Narration"
        description="Add agent narration."
        image="tenant/bre/cinematic/narration.jpg"
        persona={persona}
        cost={NARRATION}
      />
      {narration && (
        <TenantPackageAddOnCards
          name="kwasi"
          title="Professional Voice-Over"
          description="Enhance your video with a professional voice-over and script."
          image="tenant/bre/cinematic/kwasi.png"
          persona={persona}
          options={VOICE_OPTS}
          cost={KWASI}
          cols={2}
          required
        />
      )}
      <TenantPackageAddOnCheckboxName
        name="twilight"
        title="Twilight Transitions"
        description="Day-to-Dusk Twilight Transitions"
        image="tenant/bre/cinematic/twilight.jpg"
        persona={persona}
        cost={TWILIGHT}
      />
      <TenantPackageAddOnCheckboxName
        name="aerial_photos"
        title="5 Aerial Photos"
        description="Add 5 additional aerial photos."
        image="tenant/bre/cinematic/aerial_photos.jpg"
        persona={persona}
        cost={AERIAL_PHOTOS}
      />
    </>
  );
}
