import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { typeSchema } from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import BreGuidesJob from '~/tenants/bre/performable/print/guides/BreGuidesJob';
import { BreGuidesRate } from '~/tenants/bre/performable/print/guides/BreGuidesData';
import { ReactElement } from 'react';

const TYPE_OPTS = typeSchema.options.map<InputCardOption>((value) => ({
  thumbnail: `tenant/bre/print/guides/${value}.jpg`,
  title: BreGuidesJob.typeText(value),
  cost: BreGuidesRate.PDF,
  value,
}));

export default function BreGuidesForm(): ReactElement {
  const tz = useTz();

  return (
    <FormGroup>
      <FormHorizontal name="types" label="Guides" required>
        <ZodFieldCards name="types" options={TYPE_OPTS} cols={2} multiple />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 2)}
        />
      </FormHorizontal>
    </FormGroup>
  );
}
