import React from 'react';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { PackageFormNested, usePackageFormContext } from '~/components/performable/PackageForm';
import TwtAerialConfig, { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import TwtAerialAddOns from '~/tenants/twt/performable/aerial/TwtAerialAddOns';
import {
  TenantPackageAddOnCheckboxName,
  TenantPackageInclude,
  TenantPackageIncludes
} from '~/tenants/common/package';
import { TwtPackagePhotoInclude } from '~/tenants/twt/package/common';
import TwtPhotosNeighborhood from '~/tenants/twt/performable/photo/TwtPhotosNeighborhood';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import { TWT_REEL_VOICEOVER } from '~/tenants/twt/performable/reel/TwtReelJob';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtPhotosAddOns from '~/tenants/twt/performable/photo/TwtPhotosAddOns';

export default function TwtJonnyForm() {
  return (
    <>
      <TenantPackageIncludes>
        <TwtPackagePhotoInclude />
        <TenantPackageInclude name="Aerial Photos" image="tenant/twt/aerial/mini.jpg">
          A bird’s eye view to enhance the marketing of your property
        </TenantPackageInclude>
        <TenantPackageInclude name="Twilight Photos" image="tenant/twt/twilight/authentic/1.jpg" />
        <TenantPackageInclude name="Property Interior Video + Raw Footage" image="tenant/twt/video/thumbnail.png" />
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <PackageFormNested performable={TwtVideoConfig}>
          <ZodFieldHidden name="raw" value="true" />
          <TenantPackageAddOnCheckboxName
            name="voiceover"
            persona={PersonaType.CUSTOMER}
            image="tenant/twt/cameo/thumbnail.jpeg"
            title="Agent Cameo / Voiceover"
            cost={TWT_REEL_VOICEOVER}
            description="Add a personal touch to your video with an on screen cameo or a descriptive voiceover."
          />
        </PackageFormNested>
        <PackageFormNested performable={TwtPhotoConfig}>
          <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />
          <ZodFieldHidden name="twilight" value="true" />
          <TwtPhotosNeighborhood persona={PersonaType.CUSTOMER} />
        </PackageFormNested>
        <PackageFormNested performable={TwtAerialConfig}>
          <ZodFieldHidden name="type" value={TwtAerialType.PHOTO_VIDEO} />
          <TwtAerialAddOns persona={PersonaType.CUSTOMER} />
        </PackageFormNested>
      </TenantPerformableAddOns>
    </>
  );
}
