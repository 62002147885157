import NepEddmConfig, { NepEddmOptionId, NepEddmQuantity } from '~/tenants/nep/performable/eddm/NepEddmConfig';
import NepJob from '~/tenants/nep/model/NepJob';
import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { NepOrderType } from '~/tenants/nep/model/NepOrder';

interface NepEddmOptions {
  revenue: Record<NepEddmQuantity, number>;
  height: number;
  width: number;
}

export const DEMO_EDDM_OPTIONS: Record<NepEddmOptionId, NepEddmOptions> = {
  [NepEddmOptionId.NINE]: {
    revenue: {
      [NepEddmQuantity.QTY_250]: 150,
      [NepEddmQuantity.QTY_500]: 225,
      [NepEddmQuantity.QTY_1000]: 400,
      [NepEddmQuantity.QTY_2500]: 875,
    },
    width: 6.25,
    height: 9,
  },
  [NepEddmOptionId.TWELVE]: {
    revenue: {
      [NepEddmQuantity.QTY_250]: 165,
      [NepEddmQuantity.QTY_500]: 250,
      [NepEddmQuantity.QTY_1000]: 450,
      [NepEddmQuantity.QTY_2500]: 950,
    },
    width: 6.25,
    height: 12,
  },
};

export default class NepEddmJob extends NepJob<typeof NepEddmConfig> {
  get performable() {
    return NepEddmConfig;
  }

  get eligible() {
    return this.order.metadata.type !== NepOrderType.COMMERCIAL;
  }

  get configurable() {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof NepEddmConfig)['schema']> {
    return {
      option: NepEddmOptionId.NINE,
      version: schema_latest_version(NepEddmConfig.schema),
      postage: false,
      quantity: NepEddmQuantity.QTY_250,
    };
  }

  revenueLines(): TenantOrderLine[] {
    const amount = new Big(DEMO_EDDM_OPTIONS[this.metadata.option].revenue[this.metadata.quantity]);

    const lines: TenantOrderLine[] = [
      {
        amount,
        description: this.performable.name,
        id: this.performable.id,
      },
    ];

    if (this.metadata.postage) {
      lines.push({
        amount: new Big(this.metadata.quantity).times('0.20'),
        description: 'Postage',
        id: 'postage',
      });
    }

    return lines;
  }
}
