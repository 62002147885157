import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreSlideshowConfig from '~/tenants/bre/performable/slideshow/BreSlideshowConfig';
import { BreSlideshowRate } from '~/tenants/bre/performable/slideshow/BreSlideshowData';
import { TenantJobMedia } from '~/tenants/common/TenantJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';

export default class BreSlideshowJob extends BreJob<typeof BreSlideshowConfig> {
  get performable(): typeof BreSlideshowConfig {
    return BreSlideshowConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SLIDESHOW;
  }

  get media(): TenantJobMedia {
    return {
      persona: PersonaType.CUSTOMER,
      microsite: MicrositeFileType.VIDEO,
    };
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.RESIDENTIAL, BreOrderType.COMMERCIAL, BreOrderType.PROPERTY_MKTG);
  }

  get hidden(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreSlideshowConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { SLIDESHOW } = BreSlideshowRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'slideshow_video',
      description: 'Social Media Slideshow',
      amount: new Big(SLIDESHOW),
    });

    return lines;
  }
}
