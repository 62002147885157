import dedent from 'dedent';
import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type GuideType = z.infer<typeof typeSchema>;

export const typeSchema = z.enum([
  'selling',
  'buyers',
  'after_sale',
  'preparing_for_sale',
  'contract_to_close',
]);

export default createPerformableConfig(
  'guides',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      types: typeSchema
        .array()
        .min(1, { message: 'Select at least one PDF guide' })
        .max(typeSchema.options.length, { message: `Cannot choose more than ${typeSchema.options.length} guides` }),
      need_by: zodTDateISODate('Need By date is required'),
    }),
  },
  {
    name: 'PDF Agent/Broker Guides',
    group: 'Agent Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/guides/thumbnail.jpg',
    images: [],
    short: dedent`
      These digital only guides consist of Buyer, Seller, After the Sale, \
      Preparing You Home for Sale and Contract to assist you in helping your \
      client through the sale of their home.
    `,
  },
);
