import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  AERIAL_PHOTOS: '105',
  INTRO: '175',
  NARRATION: '250',
  KWASI: '250',
  RUSH: '200',
  TWILIGHT: '400',
};

export const BreCinematicRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
