import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { countSchema } from '~/tenants/bre/performable/print/covers/BreCoversConfig';
import { BreCoversRate } from '~/tenants/bre/performable/print/covers/BreCoversData';

const COUNT_OPTS = countSchema.options.map<InputCardOption>((value) => {
  const rate = BreCoversRate[value];

  return {
    title: `${value} covers`,
    description: `2 sets of ${Number(value) / 2}`,
    cost: rate,
    disabled: !rate,
    value,
  };
});

export default function BreCoversForm(): ReactElement {
  const tz = useTz();

  return (
    <FormGroup>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldCards name="count" options={COUNT_OPTS} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 7)}
        />
      </FormHorizontal>
    </FormGroup>
  );
}
