import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export enum MpiAerialType {
  PHOTO = 'photo',
  VIDEO = 'video',
  BOTH = 'both',
}

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.nativeEnum(MpiAerialType),
    }),
  },
  {
    name: 'Aerial',
    thumbnail: 'tenant/mpi/aerial/4.jpg',
    short:
      'Our high-resolution aerial photography service, conducted by FAA-certified pilots, provides between 6 and 15 photos of a home or property.',
    tenant: TenantId.MPI,
    group: 'Digital Media',
    images: [],
  },
);
