import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberRequired,
  coerceOptionalPositiveNumber,
  FIRST_VERSION_TIMESTAMP,
} from '~/lib/zod';
import { GeocodedAddressSchema } from '~/lib/model';
import dedent from 'dedent';
import { consentSchema } from '~/tenants/bre/performable/common';

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.coerce.number().positive(),
      extra_photos: coerceOptionalPositiveNumber(),
      max_extra_photos: coerceOptionalPositiveNumber(),
      extras_consent: consentSchema('Please accept or reject the extra photos consent'),
      notes: z.string().optional(),
      twilight: coerceBoolean().optional(),
      local_area: z.array(
        z.object({
          name: z.string({ required_error: 'Local Area Photo name is required' }),
          address: GeocodedAddressSchema,
        })
      ).optional(),
      community: z.string().array().optional(),
      rush: coerceBoolean().optional(),
      disclosure: consentSchema(),
    }),
  },
  {
    name: 'Photography Day & Twilight',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/photos/thumbnail_v2.jpg',
    images: [],
    short: dedent`
      We specialize in blending exposures to create photographs with stunning \
      luminosity and dynamic range. By combining multiple images at different \
      exposures, we enhance details that bring scenes to life—delivering vibrant, \
      true-to-life photos that capture every nuance without looking over-processed.
    `,
  },
);
