import z from 'zod';
import { GeocodedAddressSchema } from '~/lib/model';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export enum MpiFlyerQuantity {
  QTY_25 = '25',
  QTY_50 = '50',
  QTY_100 = '100',
  QTY_250 = '250',
}

export enum MpiFlyerShipping {
  STANDARD = 'standard',
  RUSH = 'rush',
}

export default createPerformableConfig(
  'flyer',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      quantity: z.nativeEnum(MpiFlyerQuantity),
      shipping: z.nativeEnum(MpiFlyerShipping).optional(),
      delivery: GeocodedAddressSchema.optional(),
    }),
  },
  {
    name: 'Flyer',
    thumbnail: 'tenant/mpi/brochure/1.jpg',
    short:
      'High quality pront material, professionally designed and printed, poised to impress clients and potential buysers alike. Turn around time varies based off the size of your job. Please contact MPI if you have any questions before ordering.',
    tenant: TenantId.MPI,
    images: [],
    group: 'Print',
  },
);
