import Message from '~/components/Message';
import { format_money } from '~/components/Money';
import { useZodFormData } from '~/components/zod/ZodForm';
import { datetime } from '~/lib/datettime';
import { TimeZone } from '~/lib/enum';
import { UserTime } from '~/lib/model';
import { BRE_HOLIDAY_FEE, BRE_WEEKEND_FEE } from '~/tenants/bre/data';
import { holiday_lookup } from '~common/holidays/holidays';

export default function BreOrderTimes() {
  const times = useZodFormData().times as UserTime[];

  const hasWeekends = times.some(({ day }) => datetime(day, TimeZone.US_CENTRAL).weekday > 5);
  const holidays = times.flatMap(({ day }) => holiday_lookup(day));

  return (
    <div className="flex flex-col gap-4">
      {hasWeekends && (
        <Message type="info">
          Weekend bookings may include an additional <b>{format_money(BRE_WEEKEND_FEE)}</b> fee, depending on
          photographer availability.
        </Message>
      )}

      {holidays.length > 0 && (
        <Message type="info">
          Bookings on select holidays (<i>{holidays.join(', ')}</i>) may include an additional{' '}
          <b>{format_money(BRE_HOLIDAY_FEE)}</b> fee, depending on photographer availability.
        </Message>
      )}
    </div>
  );
}
