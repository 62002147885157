import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';
import { MpiOrderContext } from '../../model/MpiOrder';
import { MpiFlyerQuantity, MpiFlyerShipping } from '~/tenants/mpi/performable/flyer/MpiFlyerConfig';
import { MPI_FLYER_SHIPPING_RUSH, MPI_FLYER_SHIPPING_STANDARD } from '~/tenants/mpi/performable/flyer/MpiFlyerJob';

export default function MpiFlyerForm(_props: TenantPerformableFormProps<MpiOrderContext>) {
  const [_quantityName, quantity] = useZodFormFieldSingle<MpiFlyerQuantity>('quantity');

  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="quantity" label="Quantity">
        <ZodFieldSelect
          name="quantity"
          options={Object.values(MpiFlyerQuantity).map((id) => ({
            value: id,
            label: id,
          }))}
        />
      </FormHorizontal>

      {quantity && (
        <FormHorizontal name="shipping" label="Shipping">
          <ZodFieldSelect
            name="shipping"
            options={[
              {
                label: `Standard ( + $${MPI_FLYER_SHIPPING_STANDARD})`,
                value: MpiFlyerShipping.STANDARD,
              },
              {
                label: `Rush ( + $${MPI_FLYER_SHIPPING_RUSH})`,
                value: MpiFlyerShipping.RUSH,
              },
            ]}
          />
        </FormHorizontal>
      )}
    </TenantPerformableConfiguration>
  );
}
