import dedent from 'dedent';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';

export type BusinessCardCount = z.infer<typeof countSchema>;

export type BusinessCardPaper = z.infer<typeof paperSchema>;

export const countSchema = z.enum([
  '50',
  '100',
  '250',
  '500',
  '1000',
  '2500',
  '5000',
]);

export const paperSchema = z.enum(['uv_glossy', 'satin_matte']);

export default createPerformableConfig(
  'business-cards',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: countSchema,
      paper: paperSchema,
      need_by: zodTDateISODate('Need By date is required'),
    }),
  },
  {
    name: 'Business Cards',
    group: 'Agent Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/cards/thumbnail_v2.jpg',
    images: [],
    short: dedent`
      We offer high-quality business card printing with premium finishes to \
      make a lasting impression.
    `,
  },
);
