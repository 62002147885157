import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import range from 'lodash.range';
import { MPI_PHOTO_TWILIGHT_SEP_TRIP, MPI_PHOTO_TWILIGHT_VIRTUAL } from './MpiPhotosJob';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { FormHorizontal } from '~/components/form/layout';
import { format_money } from '~/components/Money';
import Big from 'big.js';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import { Column } from '~/components/layout/Sections';

export enum MpiTwilightPhotoType {
  NATURAL = 'natural',
  VIRTUAL = 'virtual',
}

const MPI_PHOTOS_TWILIGHT: [string, ...string[]] = [
  'tenant/mpi/twilight/1.jpg',
  ...range(2, 8).map((i) => `tenant/mpi/twilight/${i}.jpg`),
];

export default function MpiPhotosTwilight({ persona }: Pick<TenantPerformableFormProps<MpiOrderContext>, 'persona'>) {
  const type = useZodFormFieldSingleValue<MpiTwilightPhotoType>('twilight_type');

  return (
    <>
      <TenantPackageAddOnCheckbox
        persona={persona}
        image={MPI_PHOTOS_TWILIGHT[0]}
        examples={MPI_PHOTOS_TWILIGHT}
        title="Twilight Imagery"
        description="Choose between in person twilights and digital twilights. In-person twilight photography sessions are subject to weather conditions and photographer availability. Digitally edited twilights are also available upon request."
      >
        <Column gap>
          <FormHorizontal name="twilight_type" label="Type">
            <ZodFieldSelect
              clearOnUnmount
              options={[
                {
                  label: `Natural Twilight (In Person) +${format_money(MPI_PHOTO_TWILIGHT_SEP_TRIP)}`,
                  value: MpiTwilightPhotoType.NATURAL,
                },
                { label: 'Virtual Twilight (Digital Edit)', value: MpiTwilightPhotoType.VIRTUAL },
              ]}
              name="twilight_type"
            />
          </FormHorizontal>
          {type === MpiTwilightPhotoType.VIRTUAL && (
            <FormHorizontal name="twilight_photos" label="Photos">
              <ZodFieldSelect
                clearOnUnmount
                options={range(1, 4).map((count) => {
                  const photos = count * 5;

                  return {
                    label: `${photos} Photos: ${format_money(
                      new Big(photos).times(MPI_PHOTO_TWILIGHT_VIRTUAL).toFixed(2),
                    )}`,
                    value: photos.toString(),
                  };
                })}
                name="twilight_photos"
              />
            </FormHorizontal>
          )}
        </Column>
      </TenantPackageAddOnCheckbox>
    </>
  );
}
