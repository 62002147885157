import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  COMMUNITY: '10',
  LOCAL_AREA: '25',
  RUSH: '75',
  TWILIGHT: '315',
};

export const BrePhotosRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    TWILIGHT: '420',
  },
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
