import { ReactElement } from 'react';
import { format_money } from '~/components/Money';
import InputCheckbox from '~/components/input/InputCheckbox';
import { usePerformableGroup } from '~/components/performable/PerformableGroupForm';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnTemplate, TenantPackageInclude } from '~/tenants/common/package';

interface BrePackagIncludeProps {
  itemNum?: number;
}

export function BrePackagePhotosInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  const photos = useZodFormFieldSingleValue('metadata.photos');

  return (
    <TenantPackageInclude itemNum={itemNum} name="Ground Photography" image="tenant/bre/photos/thumbnail.jpg">
      {`${photos} Photos`}
    </TenantPackageInclude>
  );
}

export function BrePackageFloorplanInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  return (
    <TenantPackageInclude itemNum={itemNum} name="2D Floorplan" image="tenant/bre/floorplan/thumbnail.jpg">
      Earth-Tone Color 2D Floorplan.
    </TenantPackageInclude>
  );
}

export function BrePackageSocialInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  return (
    <TenantPackageInclude itemNum={itemNum} name="Social Media Video" image="tenant/bre/social/thumbnail.png">
      Basic Ground Footage
    </TenantPackageInclude>
  );
}

export function BrePackageCinematicInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  return (
    <TenantPackageInclude itemNum={itemNum} name="Cinematic Video" image="tenant/bre/cinematic/thumbnail.jpg">
      With a free social media edit and 5 aerial images
    </TenantPackageInclude>
  );
}

export function BrePackageBoostInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  const reach = useZodFormFieldSingleValue('metadata.reach');

  return (
    <TenantPackageInclude itemNum={itemNum} name="Social Media Boost" image="tenant/bre/boost/thumbnail_v2.jpg">
      {`Up to ${reach} potential reach`}
    </TenantPackageInclude>
  );
}

export function BrePackageWebsiteInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  return (
    <TenantPackageInclude
      itemNum={itemNum}
      name="Single Property Website Page"
      image="tenant/bre/website/thumbnail.png"
    >
      1-year subscription to a single property website page
    </TenantPackageInclude>
  );
}

export function BrePackageDuskInclude({ itemNum }: BrePackagIncludeProps): ReactElement {
  return (
    <TenantPackageInclude itemNum={itemNum} name="Day-to-Dusk Edit" image="tenant/bre/dusk/thumbnail.jpg">
      One complimentary Day-to-Dusk photo edit.
    </TenantPackageInclude>
  );
}

export function BreZillowInclude(): ReactElement {
  return <TenantPackageInclude name="Zillow Showcase">A Zillow showcase.</TenantPackageInclude>;
}

export function BreMatterportInclude(): ReactElement {
  return (
    <TenantPackageInclude name="3D Matterport">Our 3D Matterport Showcase online experience.</TenantPackageInclude>
  );
}

export function BrePackageMatterportUpgrade(): ReactElement | null {
  const { order, addPerformable, removePerformable } = usePerformableGroup<BreOrderContext>();

  if (order.metadata.type === BreOrderType.AGENT_MKTG || order.metadata.type === BreOrderType.PROPERTY_MKTG) {
    return null;
  }

  return (
    <TenantPackageAddOnTemplate
      title="Include 3D Matterport Showcase"
      description="Experience fully immersive inside and out virtual spaces that are so real, it’s like being there"
      image="tenant/bre/matterport/thumbnail.png"
      persona={PersonaType.CUSTOMER}
      cost={format_money(BreMatterportJob.rate(order.metadata.sqft).toFixed(2))}
      enable={
        <InputCheckbox
          onChange={(checked) =>
            checked ? addPerformable(BreMatterportConfig) : removePerformable(BreMatterportConfig)
          }
        />
      }
    />
  );
}
