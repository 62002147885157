import { registerTenant, TenantId } from '~/tenants/common/registry';
import { TimeZone } from '~/lib/enum';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiPhotosJob from '~/tenants/mpi/performable/photos/MpiPhotosJob';
import MpiPhotosForm from '~/tenants/mpi/performable/photos/MpiPhotosForm';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import MpiProvider from '~/tenants/mpi/model/MpiProvider';
import { MPI_PROVIDER_DATA } from '~/tenants/mpi/data';
import MpiCustomer, { MpiCustomerSchema } from '~/tenants/mpi/model/MpiCustomer';
import MpiOrderWizardType from '~/tenants/mpi/forms/MpiOrderWizardType';
import { MpiOrder, MpiOrderSchema } from '~/tenants/mpi/model/MpiOrder';
import MpiOrderForm from '~/tenants/mpi/forms/MpiOrderForm';
import MpiFloorplanConfig from './performable/floorplan/MpiFloorplanConfig';
import MpiFloorplanJob from './performable/floorplan/MpiFloorplanJob';
import MpiFloorplanForm from './performable/floorplan/MpiFloorplanForm';
import MpiVirtualStagingConfig from './performable/staging/MpiVirtualStagingConfig';
import MpiVirtualStagingJob from './performable/staging/MpiVirtualStagingJob';
import MpiVirtualStagingForm from './performable/staging/MpiVirtualStagingForm';
import MpiAerialConfig, { MpiAerialType } from './performable/aerial/MpiAerialConfig';
import MpiAerialJob from './performable/aerial/MpiAerialJob';
import MpiAerialForm from './performable/aerial/MpiAerialForm';
import MpiBrochuresConfig from './performable/brochures/MpiBrochuresConfig';
import MpiBrochuresJob from './performable/brochures/MpiBrochuresJob';
import MpiBrochuresForm from './performable/brochures/MpiBrochuresForm';
import MpiHeadshotConfig from '~/tenants/mpi/performable/headshot/MpiHeadshotConfig';
import MpiHeadshotJob from '~/tenants/mpi/performable/headshot/MpiHeadshotJob';
import MpiHeadshotForm from '~/tenants/mpi/performable/headshot/MpiHeadshotForm';
import MpiVirtualConfig from '~/tenants/mpi/performable/virtual/MpiVirtualConfig';
import { MpiVirtualJob } from '~/tenants/mpi/performable/virtual/MpiVirtualJob';
import MpiVirtualForm from '~/tenants/mpi/performable/virtual/MpiVirtualForm';
import Mpi3dConfig from '~/tenants/mpi/performable/3d/Mpi3dConfig';
import Mpi3dJob from '~/tenants/mpi/performable/3d/Mpi3dJob';
import Mpi3dForm from '~/tenants/mpi/performable/3d/Mpi3dForm';
import MpiFlyerConfig from '~/tenants/mpi/performable/flyer/MpiFlyerConfig';
import MpiFlyerJob from '~/tenants/mpi/performable/flyer/MpiFlyerJob';
import MpiFlyerForm from '~/tenants/mpi/performable/flyer/MpiFlyerForm';
import MpiWalkthroughConfig from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughConfig';
import MpiWalkthroughForm from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughForm';
import MpiWalkthroughJob from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughJob';
import MpiAerialPackage from '~/tenants/mpi/package/MpiAerialPackage';
import { DeliverableType } from '~common/model/Deliverable';
import TwtEditingConfig from '~/tenants/twt/performable/editing/TwtEditingConfig';
import TwtEditingAction from '~/tenants/twt/performable/editing/TwtEditingAction';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtPhotoReshootForm from '~/tenants/twt/performable/photo/TwtPhotoReshootForm';
import MpiReshootForm from '~/tenants/mpi/action/MpiReshootForm';

export default function registerMpi() {
  registerTenant(TenantId.MPI, MpiOrderSchema, {
    name: 'Maryland Photography Inc.',
    domain: 'mpi.photology.co',
    phone: '+14106958100',
    logoRaster: 'https://optimize.photology.co/tenant/mpi/logo.png',
    logoVector: 'https://optimize.photology.co/tenant/mpi/logo.svg',
    background: '#F05722',
    legal: 'Maryland Photography Inc.',
    email: 'mpi@photology.co',
    emailEnable: false,
    userback: true,
    tax: '0.06',
    providerClass: MpiProvider,
    providers: MPI_PROVIDER_DATA,
    rainforestMerchantId: 'mid_2swlIK2yPVAMMIM9FfJfNZenqL0',
    rainforestMerchantApplicationId: 'app_2swlIG5TDRpYcoRgm7l9ixtOfg2',
    scheduleStartTime: '06:00',
    scheduleEndTime: '20:00',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    customerClass: MpiCustomer,
    locations: [
      {
        slug: 'clb',
        name: 'Columbia',
        timezone: TimeZone.US_EASTERN,
        address: {
          line1: '9570 Berger Rd Suite D',
          city: 'Columbia',
          state: 'MD',
          zip: '21046',
          lat: 39.17338,
          long: -76.84341,
        },
      },
    ],
    orderClass: MpiOrder,
    packages: [
      {
        config: {
          id: 'aerial',
          configurable: true,
          description: 'Here is what you won Bob!',
          name: 'Aerial Bundle',
          performables: [
            MpiPhotosConfig,
            {
              config: MpiAerialConfig,
              metadata: {
                type: MpiAerialType.BOTH,
              },
            },
          ],
        },
        form: MpiAerialPackage,
      },
    ],
    customerSchema: MpiCustomerSchema,
    forms: {
      orderConfigure: MpiOrderForm,
      wizardType: MpiOrderWizardType,
      provider: EmptyForm,
    },
    actions: [
      {
        id: 'reshoot',
        name: 'Request Reshoot',
        deliverable: [DeliverableType.PHOTO, DeliverableType.AERIAL],
        job: MpiPhotosConfig,
        form: MpiReshootForm,
      },
    ],
    performables: {
      [MpiPhotosConfig.id]: {
        config: MpiPhotosConfig,
        job: MpiPhotosJob,
        form: MpiPhotosForm,
      },
      [MpiAerialConfig.id]: {
        config: MpiAerialConfig,
        job: MpiAerialJob,
        form: MpiAerialForm,
      },
      [Mpi3dConfig.id]: {
        config: Mpi3dConfig,
        job: Mpi3dJob,
        form: Mpi3dForm,
      },
      [MpiFloorplanConfig.id]: {
        config: MpiFloorplanConfig,
        job: MpiFloorplanJob,
        form: MpiFloorplanForm,
      },
      [MpiVirtualStagingConfig.id]: {
        config: MpiVirtualStagingConfig,
        job: MpiVirtualStagingJob,
        form: MpiVirtualStagingForm,
      },
      [MpiFlyerConfig.id]: {
        config: MpiFlyerConfig,
        job: MpiFlyerJob,
        form: MpiFlyerForm,
      },
      [MpiBrochuresConfig.id]: {
        config: MpiBrochuresConfig,
        job: MpiBrochuresJob,
        form: MpiBrochuresForm,
      },
      [MpiHeadshotConfig.id]: {
        config: MpiHeadshotConfig,
        job: MpiHeadshotJob,
        form: MpiHeadshotForm,
      },
      [MpiVirtualConfig.id]: {
        config: MpiVirtualConfig,
        job: MpiVirtualJob,
        form: MpiVirtualForm,
      },
      [MpiWalkthroughConfig.id]: {
        config: MpiWalkthroughConfig,
        job: MpiWalkthroughJob,
        form: MpiWalkthroughForm,
      },
    },
  });
}
