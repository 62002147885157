import { Big } from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreSignsConfig, { SignShipping, SignType } from '~/tenants/bre/performable/print/signs/BreSignsConfig';
import { BreSignsPay, BreSignsRate } from '~/tenants/bre/performable/print/signs/BreSignsData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreSignsJob extends BreJob<typeof BreSignsConfig> {
  get performable(): typeof BreSignsConfig {
    return BreSignsConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get eligible(): boolean {
    return false;
  }

  get typeName(): string {
    const { type } = this.metadata;
    return BreSignsJob.typeName(type);
  }

  get shippingType(): string {
    const { shipping } = this.metadata;
    return BreSignsJob.shippingType(shipping);
  }

  static typeName(type: SignType): string {
    return match(type)
      .with('sign_24x24', () => '24" x 24" For Sale Sign')
      .with('sign_24x18', () => '24" x 18" For Sale Sign')
      .with('aframe_24x24', () => '24" x 24" Open House A-Frame')
      .exhaustive();
  }

  static shippingType(shipping: SignShipping): string {
    return match(shipping)
      .with('courier', () => '2-Days Courier')
      .with('ups', () => '3-Days UPS')
      .exhaustive();
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreSignsConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'sign_24x24',
      shipping: 'courier',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { shipping, type } = this.metadata;
    const rate = BreSignsRate.signs[type];
    const shippingFee = BreSignsRate.shipping[shipping];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'signs_print',
        description: this.typeName,
        amount: new Big(rate),
        taxable: true,
      });
    }

    if (shippingFee) {
      lines.push({
        id: 'shipping_fee',
        description: `${this.shippingType} shipping`,
        amount: new Big(shippingFee),
        fee: 'delivery',
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { shipping, type } = this.metadata;
    const pay = BreSignsPay.signs[type];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'signs_print',
        description: this.typeName,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}
