import { TenantOrderLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '../../model/MpiJob';
import MpiAerialConfig, { MpiAerialType } from './MpiAerialConfig';
import Big from 'big.js';
import { DeliverableType } from '~common/model/Deliverable';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default class MpiAerialJob extends MpiJob<typeof MpiAerialConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiAerialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: MpiAerialType.PHOTO,
    };
  }

  onsite() {
    let onsite = 30;

    if (this.metadata.type === MpiAerialType.BOTH) {
      onsite += 30;
    }

    return onsite;
  }

  get deliverable() {
    return DeliverableType.AERIAL;
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  get editing() {
    return true;
  }

  get performable() {
    return MpiAerialConfig;
  }

  get configurable() {
    return true;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      id: 'insurance',
      description: 'Government Mandated Drone Flight Insurance',
      amount: new Big(15),
    });

    const photos = this.order.jobs.some((j) => j.performable_id === 'photos');
    const video = this.order.buyer.metadata.discount_aerial_video ?? this.order.parent?.discount_aerial_video;
    const photo = this.order.buyer.metadata.discount_aerial_photo ?? this.order.parent?.discount_aerial_photo;

    if (this.metadata.type !== MpiAerialType.VIDEO) {
      lines.push({
        id: 'photos',
        description: 'Aerial Photography',
        amount: new Big(175),
      });
    }

    if (this.metadata.type !== MpiAerialType.PHOTO) {
      lines.push({
        id: 'video',
        description: 'Aerial Video',
        amount: new Big(175),
      });
    }

    if (video && this.metadata.type !== MpiAerialType.PHOTO) {
      lines.push({
        amount: new Big(video),
        description: 'Aerial Video Discount',
        id: 'aerial_video_discount',
        discount: true,
      });
    } else if (photos) {
      if (photo && this.metadata.type !== MpiAerialType.VIDEO) {
        lines.push({
          amount: new Big(photo),
          description: 'Aerial Photo Discount',
          id: 'aerial_photo_discount',
          discount: true,
        });
      } else {
        lines.push({
          id: `aerial_bundle_discount`,
          description: 'Aerial + Photos Discount',
          discount: true,
          amount: new Big(-50),
        });
      }
    }

    return lines;
  }
}
