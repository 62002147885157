import React from 'react';
import {
  TWT_FLOORPLAN_UPGRADE_3D_AMOUNT,
  TWT_FLOORPLAN_UPGRADE_PLUS_AMOUNT,
} from '~/tenants/twt/performable/floorplan/TwtFloorplanJob';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';

export default function TwtFloorplanUpgrade({ persona }: { persona: PersonaType }) {
  const upgrade = useZodFormFieldSingleValue('upgrade');

  return (
    <>
      <TenantPackageAddOnCheckboxName
        name="upgrade"
        value="plus"
        persona={persona}
        image="tenant/twt/floorplan/plus2.png"
        title="Plus Upgrade"
        lightbox
        disabled={upgrade === '3d'}
        cost={TWT_FLOORPLAN_UPGRADE_PLUS_AMOUNT}
        description={
          upgrade === '3d'
            ? 'Another floorplan upgrade was already selected.'
            : 'Enhance your colored floor plan with fixed furniture and door orientations.'
        }
      />
      <TenantPackageAddOnCheckboxName
        name="upgrade"
        image="tenant/twt/floorplan/3d2.png"
        value="3d"
        title="3D Upgrade"
        persona={persona}
        disabled={upgrade === 'plus'}
        lightbox
        cost={TWT_FLOORPLAN_UPGRADE_3D_AMOUNT}
        description={
          upgrade === 'plus'
            ? 'Another floorplan upgrade was already selected.'
            : 'Enhance your floor plan with digitally replicated furniture, decluttering, and photo realistic colors and textures.'
        }
      />
    </>
  );
}
