import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';

export default function BreBoostForm(): ReactElement {
  return (
    <FormGroup>
      <BreBoostFields />
    </FormGroup>
  );
}
