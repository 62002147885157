import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiPhotosConfig, { MpiPhotosDetailTime, MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiJob from '~/tenants/mpi/model/MpiJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MpiOrderType } from '../../model/MpiOrder';
import { MpiTwilightPhotoType } from './MpiPhotosTwilight';

export const MPI_PHOTO_EXTRA = 4;
export const MPI_PHOTO_COMMUNITY = 75;
export const MPI_PHOTO_TWILIGHT_SAME_TRIP = 175;
export const MPI_PHOTO_TWILIGHT_SEP_TRIP = 250;
export const MPI_PHOTO_TWILIGHT_VIRTUAL = 5;
export const MPI_PHOTO_DETAIL_15 = 60;
export const MPI_PHOTO_DETAIL_30 = 85;

export default class MpiPhotosJob extends MpiJob<typeof MpiPhotosConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiPhotosConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: MpiPhotosType.STANDARD,
    };
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  get hourly() {
    return this.order.metadata.type === MpiOrderType.COMMERCIAL;
  }

  get editing(): boolean {
    return true;
  }

  get configurable(): boolean {
    return true;
  }

  static extraPhotos(amount: number) {
    return new Big(MPI_PHOTO_EXTRA).times(amount);
  }

  onsite(): number {
    let onsite = this.metadata.type === MpiPhotosType.STANDARD || this.metadata.type === MpiPhotosType.EVENT ? 60 : 30;

    if (this.metadata.twilight_type === MpiTwilightPhotoType.NATURAL) {
      onsite += 20;
    }

    if (this.metadata.detail === MpiPhotosDetailTime.MINUTES_15) {
      onsite += 15;
    }

    if (this.metadata.detail === MpiPhotosDetailTime.MINUTES_30) {
      onsite += 30;
    }

    if (this.metadata.community) {
      onsite += 20;
    }

    if (this.metadata.extra_photos_max) {
      onsite += this.metadata.extra_photos_max;
    }

    return onsite;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    if (this.metadata.type === MpiPhotosType.EVENT) {
      lines.push({
        amount: new Big(300),
        description: 'Event Shoot',
        id: 'event',
      });
    } else if (this.metadata.type === MpiPhotosType.EXTERIOR) {
      lines.push({
        amount: new Big(125),
        description: 'Exterior',
        id: 'exterior',
      });
    } else if (this.metadata.type === MpiPhotosType.RESHOOT) {
      lines.push({
        amount: new Big(125),
        description: 'Reshoot',
        id: 'reshoot',
      });
    } else if (this.order.metadata.type === MpiOrderType.COMMERCIAL) {
      if (this.metadata.hours) {
        lines.push({
          amount: new Big(300).times(this.metadata.hours),
          description: `Hours (${this.metadata.hours})`,
          id: 'photos',
        });
      }
    } else if (this.metadata.type === MpiPhotosType.TWILIGHT) {
      lines.push({
        amount: new Big(MPI_PHOTO_TWILIGHT_SEP_TRIP),
        description: 'Standalone Twilight Photos',
        id: 'twilight',
      });
    } else {
      lines.push({
        amount: new Big(220),
        description: 'Photos',
        id: 'photos',
      });

      if (this.metadata.twilight_type === MpiTwilightPhotoType.NATURAL) {
        if (this.appointment) {
          // only give discount once appointment is booked
          lines.push({
            amount: new Big(MPI_PHOTO_TWILIGHT_SAME_TRIP),
            description: 'Same-Trip Twilight Photos',
            id: 'twilight',
          });
        } else {
          // otherwise when ordering show the higher price incase it is separate later
          lines.push({
            amount: new Big(MPI_PHOTO_TWILIGHT_SEP_TRIP),
            description: 'Twilight Photos',
            id: 'twilight',
          });
        }
      }
    }

    if (this.metadata.detail === MpiPhotosDetailTime.MINUTES_15) {
      lines.push({
        amount: new Big(MPI_PHOTO_DETAIL_15),
        description: 'Detail Photography (4-8 Images)',
        id: 'detail',
      });
    } else if (this.metadata.detail === MpiPhotosDetailTime.MINUTES_30) {
      lines.push({
        amount: new Big(MPI_PHOTO_DETAIL_30),
        description: 'Detail Photography (9-16 Images)',
        id: 'detail',
      });
    }

    if (this.metadata.extra_photos_actual && this.metadata.extra_photos_actual > 0) {
      lines.push({
        amount: MpiPhotosJob.extraPhotos(this.metadata.extra_photos_actual),
        description: `Extra Photos (${this.metadata.extra_photos_actual})`,
        id: 'extra_photos',
      });
    }

    if (this.metadata.twilight_type === MpiTwilightPhotoType.VIRTUAL && this.metadata.twilight_photos) {
      lines.push({
        amount: new Big(MPI_PHOTO_TWILIGHT_VIRTUAL).times(this.metadata.twilight_photos),
        description: `Virtual Twilight Photos (${this.metadata.twilight_photos})`,
        id: 'twilight',
      });
    }

    if (this.metadata.community) {
      lines.push({
        amount: new Big(MPI_PHOTO_COMMUNITY),
        description: 'Community Photos',
        id: 'community',
      });
    }

    const discount = this.order.buyer.metadata.discount_photography ?? this.order.parent?.discount_photography;

    if (discount) {
      lines.push({
        amount: new Big(discount),
        description: 'Customer Loyalty Discount',
        id: 'discount_photography',
        discount: true,
      });
    }

    return lines;
  }

  get performable() {
    return MpiPhotosConfig;
  }
}
