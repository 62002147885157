import { ReactElement } from 'react';
import Message from '~/components/Message';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';

export default function BreListingDisclosure(): ReactElement {
  return (
    <Message type="info">
      The property must be &quot;Photo Ready&quot; upon the photographer&apos;s arrival. If not, a same-day cancellation
      or delay fee will apply:
      <ul className="list-disc list-inside indent-2">
        <li>
          <b>Same-Day Cancellation:</b> $125 (less than 24 hours notice) or $175 (less than 3 hours notice).
        </li>
        <li>
          <b>Delay Fee:</b> $50 for every 15 minutes of delay (up to 30 minutes).
        </li>
      </ul>
      <div className="pt-2">Please ensure the property is prepared in advance to avoid additional fees.</div>
      <label className="flex items-center gap-2 mt-4 font-semibold cursor-pointer" htmlFor="disclosure">
        <ZodFieldCheckbox id="disclosure" name="disclosure" />I understand and agree to the terms of delays and
        cancellations.
      </label>
    </Message>
  );
}
