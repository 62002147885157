import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import {
  TenantPackageAddOnCheckbox,
  TenantPackageAddOnCheckboxName,
  TenantPackageAddOnGalleryExamplesCheckbox,
} from '~/tenants/common/package';
import { MpiOrderContext } from '../../model/MpiOrder';
import { format_money } from '~/components/Money';
import { MPI_PHOTO_COMMUNITY, MPI_PHOTO_DETAIL_15, MPI_PHOTO_DETAIL_30 } from './MpiPhotosJob';
import Big from 'big.js';
import IconSolidNew from '~/components/icons/streamline/solid/IconSolidNew';
import IconText from '~/components/layout/IconText';
import { useZodFormFieldArray, useZodFormFieldObject } from '~/components/zod/ZodForm';
import { DistanceAddress } from '~/lib/model';
import ZodFieldAddress from '~/components/zod/ZodFieldAddress';
import classNames from 'classnames';
import Button from '~/components/interactive/Button';
import { Column } from '~/components/layout/Sections';
import { FormHorizontal } from '~/components/form/layout';
import { MpiPhotosDetailTime } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

function communityExtraPhotos(count: number) {
  return {
    label: `15 Minute Shoot, ${count} Community Photos: ${format_money(new Big(MPI_PHOTO_COMMUNITY).toFixed(2))}`,
    value: count.toString(),
  };
}

function Address({ name, remove }: { name: string; remove: () => void }) {
  const [_name, address] = useZodFormFieldObject<DistanceAddress>(name);

  return (
    <div className="flex gap-4 align-baseline">
      <div
        className={classNames('', {
          'flex-1': !address?.city,
        })}
      >
        <ZodFieldAddress name={name} key={name} />
      </div>
      {address?.city && (
        <Button type="button" variant="outline" size="short" onClick={remove}>
          remove
        </Button>
      )}
    </div>
  );
}

export default function MpiPhotosCommunity({ persona }: Pick<TenantPerformableFormProps<MpiOrderContext>, 'persona'>) {
  return (
    <TenantPackageAddOnCheckboxName
      name="community"
      persona={persona}
      image="/tenant/mpi/community/1.jpg"
      cost={MPI_PHOTO_COMMUNITY}
      title="Community Photos"
      description="Our photographer will visit a location of your choice for 15 minutes and take 5-10 additional photos."
    >
      <FormHorizontal label="Instructions">
        <ZodFieldTextarea name="community_notes" clearOnUnmount />
      </FormHorizontal>
    </TenantPackageAddOnCheckboxName>
  );
}
