import React, { useEffect } from 'react';
import { TenantPackageAddOnGalleryExamplesCheckbox } from '~/tenants/common/package';
import { useZodFormFieldSingle, useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TWT_PHOTO_TWILIGHT } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import range from 'lodash.range';

const TWT_PHOTOS_TWILIGHT: [string, ...string[]] = [
  'tenant/twt/twilight/authentic/2.jpg',
  ...range(1, 13)
    .map((i) => `tenant/twt/twilight/authentic/${i}.jpg`)
    .filter((i) => !i.includes('/2.jpg')),
];

export default function TwtPhotosTwilight({ persona }: { persona: PersonaType }): JSX.Element {
  const sky = Boolean(useZodFormFieldSingleValue('twilight_virtual_sky'));
  const twilight = useZodFormFieldSingleValue('twilight') === 'true';
  const sunrise = useZodFormFieldSingleValue('sunrise') === 'true';
  const [_count, count, setCount] = useZodFormFieldSingle('twilight_virtual_count');

  useEffect(() => {
    if (sky && !count) {
      setCount('1');
    }
  }, [sky]);

  return (
    <>
      <TenantPackageAddOnGalleryExamplesCheckbox
        name="twilight"
        persona={persona}
        images={TWT_PHOTOS_TWILIGHT}
        disabled={sky || sunrise}
        title="Twilight Shoot"
        cost={TWT_PHOTO_TWILIGHT}
        description="Add this service and we will combine your day shoot with Twilight exteriors. We will arrive an hour or so prior to sunset to photograph day exteriors as well as all interiors and then head out to capture twilight exteriors."
      />
      {persona === PersonaType.OFFICE && (
        <TenantPackageAddOnGalleryExamplesCheckbox
          name="sunrise"
          persona={persona}
          images={TWT_PHOTOS_TWILIGHT}
          disabled={sky || twilight}
          title="Sunrise Shoot"
          cost={TWT_PHOTO_TWILIGHT}
          description=""
        />
      )}
    </>
  );
}
