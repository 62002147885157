import { z } from 'zod';
import dedent from 'dedent';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';

export type FlyerSide = z.infer<typeof sideSchema>;

export type FlyerCount = z.infer<typeof countSchema>;

export type FlyerPaper = z.infer<typeof paperSchema>;

export const sideSchema = z.enum([
  '1',
  '2',
  '4',
]);

export const countSchema = z.enum([
  '50',
  '100',
  '150',
  '200',
]);

export const paperSchema = z.enum([
  'low_gloss',
  'heavy_gloss',
  'heavy_gloss_uv',
  'super_heavy_gloss_uv',
  'linen_cover',
]);

export default createPerformableConfig(
  'flyers',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      sides: sideSchema,
      count: countSchema,
      paper: paperSchema,
      need_by: zodTDateISODate('Need By date is required'),
      no_free_uv: coerceBoolean().optional(),
      content_writing: coerceBoolean().optional(),
      design: layoutSchema.optional(),
    }),
  },
  {
    name: 'Flyers',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/flyers/thumbnail_v2.jpg',
    images: [],
    short: dedent`
      Print eye-catching 9.5" x 11" flyers on 1, 2, or 4 sides with premium and \
      laser paper options. Need help with content? Add professional writing for \
      a small fee!
    `,
  },
);
