import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import { TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { TwtSimpleJob } from '~/tenants/twt/performable/TwtSimpleJob';
import Big from 'big.js';
import { twt_version, TwtVersion } from '~/tenants/twt/data';

const TWT_FLOORPLAN_STANDARD_NAME = 'Standard';

export const TWT_FLOORPLAN_UPGRADE_PLUS_AMOUNT = 15;
const TWT_FLOORPLAN_UPGRADE_PLUS_NAME = 'Plus Upgrade';

export const TWT_FLOORPLAN_UPGRADE_3D_AMOUNT = 30;
const TWT_FLOORPLAN_UPGRADE_3D_NAME = 'Plus 3D Upgrade';

export default class TwtFloorplanJob extends TwtSimpleJob<typeof TwtFloorplanConfig> {
  protected get config() {
    return {
      expenseMinimum: 30,
      expensePer1000: 10,
      revenueMinimum: 99,
      revenuePer1000: 40,
      onsiteMinimum: 10,
      onsitePer1000: 5,
    };
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtFloorplanConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite() {
    let onsite = 15;

    if (this.provider) {
      if (this.sqft <= 6000) {
        onsite = this.provider.config.floorplan_onsite_5001_6000 ?? onsite;
      }

      if (this.sqft <= 5000) {
        onsite = this.provider.config.floorplan_onsite_4001_5000 ?? onsite;
      }

      if (this.sqft <= 4000) {
        onsite = this.provider.config.floorplan_onsite_3001_4000 ?? onsite;
      }

      if (this.sqft <= 3000) {
        onsite = this.provider.config.floorplan_onsite_2001_3000 ?? onsite;
      }

      if (this.sqft <= 2000) {
        onsite = this.provider.config.floorplan_onsite_1001_2000 ?? onsite;
      }

      if (this.sqft <= 1000) {
        onsite = this.provider.config.floorplan_onsite_0_1000 ?? onsite;
      }

      if (this.sqft > 6000 && this.provider.config.scan_onsite_every_1000_after_6000) {
        const per1000 = this.provider.config.scan_onsite_every_1000_after_6000;

        onsite += per1000 * Math.ceil((this.sqft - 6000) / 1000);
      }
    }

    return onsite;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.FLOORPLAN;
  }

  get submission(): boolean {
    return true;
  }

  get short() {
    return '2D';
  }

  get performable() {
    return TwtFloorplanConfig;
  }

  get configurable() {
    return true;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.FLOORPLAN };
  }

  get eligible(): boolean {
    return this.order.metadata.subtype !== TwtPropertyType.LAND;
  }

  info(): DisplayData[] {
    const info = super.info();

    let type = TWT_FLOORPLAN_STANDARD_NAME;

    if (this.metadata.upgrade === 'plus') {
      type = TWT_FLOORPLAN_UPGRADE_PLUS_NAME;
    } else if (this.metadata.upgrade === '3d') {
      type = TWT_FLOORPLAN_UPGRADE_3D_NAME;
    }

    if (this.metadata.detached && this.metadata.detached.length > 0) {
      info.push({
        name: 'Detached Structures',
        value: this.metadata.detached.join(','),
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    return [
      ...info,
      { name: 'Type', value: type, provider: true, schedule: true },
    ];
  }

  protected revenueMinimum() {
    if (this.orderHasPhotos) {
      return 44;
    }

    return this.config.revenueMinimum;
  }

  protected expenseMinimum() {
    if (this.orderHasMatterport && this.orderHasPhotos) {
      return 0;
    }

    return this.config.expenseMinimum;
  }

  protected revenuePer(thousands: number) {
    if (this.orderHasPhotos) {
      return 35;
    }

    return thousands < 10 ? 40 : 50;
  }

  protected expensePer(thousands: number) {
    if (this.orderHasMatterport && this.orderHasPhotos) {
      return 0;
    }

    if (twt_version(this.order.created, TwtVersion.V5)) {
      if (thousands < 3) {
        return 25;
      }
    } else {
      if (thousands === 3) {
        return 30;
      }

      if (thousands < 4) {
        return 25;
      }
    }

    return 30;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    let expense = this.simpleExpense();

    if (!twt_version(this.order.created, TwtVersion.V4)) {
      const mapping: Record<string, string> = {
        '55.00': '20.00',
        '105.00': '20.00',
        '80.00': '20.00',
        '0.00': '20.00'
      };

      if (mapping[expense.toFixed(2)]) {
        expense = new Big(mapping[expense.toFixed(2)]);
      }
    }

    lines.push({
      amount: expense,
      description: this.description,
      id: 'floorplan',
    });

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (!(this.orderHasMatterport && this.orderHasPhotos)) {
      let revenue = this.simpleRevenue().amount;

      if (!twt_version(this.order.created, TwtVersion.V4)) {
        const mapping: Record<string, string> = {
          '79.00': '39.00',
          '114.00': '39.00',
          '149.00': '39.00'
        };

        if (mapping[revenue.toFixed(2)]) {
          revenue = new Big(mapping[revenue.toFixed(2)]);
        }
      }

      lines.push({
        amount: revenue,
        description: this.description,
        id: 'floorplan',
        taxable: true,
        discountable: true
      });
    }

    if (this.metadata.upgrade === 'plus') {
      lines.push({
        amount: new Big(TWT_FLOORPLAN_UPGRADE_PLUS_AMOUNT),
        description: 'Plus Upgrade',
        id: 'plus',
        taxable: true,
        discountable: true,
      });
    } else if (this.metadata.upgrade === '3d') {
      lines.push({
        amount: new Big(TWT_FLOORPLAN_UPGRADE_3D_AMOUNT),
        description: '3D Upgrade',
        id: '3d',
        taxable: true,
        discountable: true,
      });
    }

    return lines;
  }

  private get description(): string {
    let upgrade = TWT_FLOORPLAN_STANDARD_NAME;

    if (this.metadata.upgrade === 'plus') {
      upgrade = TWT_FLOORPLAN_UPGRADE_PLUS_NAME;
    } else if (this.metadata.upgrade === '3d') {
      upgrade = TWT_FLOORPLAN_UPGRADE_3D_NAME;
    }

    return `2D Floorplan (${upgrade})`;
  }
}
