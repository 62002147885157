import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';
import { MpiOrderContext } from '../../model/MpiOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { MpiHeadshotType } from '~/tenants/mpi/performable/headshot/MpiHeadshotConfig';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { MPI_HEADSHOT_PEOPLE } from '~/tenants/mpi/performable/headshot/MpiHeadshotJob';

export default function MpiHeadshotForm(_props: TenantPerformableFormProps<MpiOrderContext>) {
  const type = useZodFormFieldSingleValue<MpiHeadshotType>('type');

  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="type" label="Location">
        <ZodFieldSelect
          name="type"
          options={[
            { label: 'At MPI Studio', value: MpiHeadshotType.STUDIO },
            { label: 'On Location', value: MpiHeadshotType.LOCATION },
          ]}
        />
      </FormHorizontal>
      {type === MpiHeadshotType.LOCATION && (
        <>
          <FormHorizontal name="people" label="People">
            <ZodFieldSelect
              name="people"
              options={Object.keys(MPI_HEADSHOT_PEOPLE).map((people) => ({
                label: `${people} People`,
                value: people,
              }))}
            />
          </FormHorizontal>
          <FormHorizontal label="Parking" name="parking">
            <ZodFieldTextarea name="parking" />
          </FormHorizontal>
        </>
      )}
    </TenantPerformableConfiguration>
  );
}
