import { TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { PersonaType } from '~/tenants/common/TenantPersona';
import React from 'react';
import MpiPhotosAddOns from '~/tenants/mpi/performable/photos/MpiPhotosAddOns';
import MpiPhotosConfig, { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { PackageFormNested } from '~/components/performable/PackageForm';
import MpiAerialConfig, { MpiAerialType } from '~/tenants/mpi/performable/aerial/MpiAerialConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function MpiAerialPackage() {
  return (
    <>
      <TenantPackageIncludes>
        <TenantPackageInclude name="36 Photos" image="tenant/mpi/residential/1.jpg">
          {MpiPhotosConfig.short}
        </TenantPackageInclude>
        <PackageFormNested performable={MpiAerialConfig}>
          <ZodFieldHidden name="type" value={MpiAerialType.BOTH} />
          <TenantPackageInclude name="Aerial Photos + Video" image="tenant/mpi/aerial/1.jpg">
            {MpiAerialConfig.short}
          </TenantPackageInclude>
        </PackageFormNested>
      </TenantPackageIncludes>
      {/*<TwtPackageTimeOfDay />*/}
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <PackageFormNested performable={MpiPhotosConfig}>
          <ZodFieldHidden name="type" value={MpiPhotosType.STANDARD} />
          <MpiPhotosAddOns persona={PersonaType.CUSTOMER} />
        </PackageFormNested>
      </TenantPerformableAddOns>
    </>
  );
}
