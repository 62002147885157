import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreCommunityConfig from '~/tenants/bre/performable/community/BreCommunityConfig';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { TenantJobMedia } from '~/tenants/common/TenantJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreOrderType } from '~/tenants/bre/model/enums';

export default class BreCommuntityJob extends BreJob<typeof BreCommunityConfig> {
  get performable(): typeof BreCommunityConfig {
    return BreCommunityConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get media(): TenantJobMedia {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.isOneOf(BreOrderType.RESIDENTIAL, BreOrderType.COMMERCIAL, BreOrderType.RENTAL)
      && !this.order.jobs.find((j) => j.performable_id === 'photos' && j.metadata.community?.length);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreCommunityConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: [],
    };
  }

  revenueLines(): TenantJobLine[] {
    const { COMMUNITY } = BrePhotosRate[this.order.metadata.type];
    const { photos } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (photos.length > 0) {
      lines.push({
        id: 'community_photos',
        description: `Community Photos (x${photos.length})`,
        amount: new Big(COMMUNITY).times(photos.length),
      });
    }

    return lines;
  }
}
