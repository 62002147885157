import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TwtTwilightType } from '~/tenants/twt/performable/twilight/TwtTwilightConfig';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export function TwtTwilightForm(): JSX.Element {
  return (
    <FormGroup>
      <FormHorizontal label="Type">
        <ZodFieldSelect
          options={[
            { label: 'Front + Back', value: TwtTwilightType.BOTH },
            { label: 'Front', value: TwtTwilightType.FRONT },
            { label: 'Back', value: TwtTwilightType.BACK },
          ]}
          name="type"
        />
      </FormHorizontal>
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>
    </FormGroup>
  );
}
