import dedent from 'dedent';
import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type DesignLayout = z.infer<typeof layoutSchema>;

export type DesignType = z.infer<typeof typeSchema>;

export const layoutSchema = z.enum(['new', 'existing']);

export const typeSchema = z.enum([
  'postcard_half',
  'postcard_jumbo',
  'postcard_flats',
  'flyer_1',
  'flyer_2',
  'flyer_4',
  'brochure_8',
  'brochure_12',
  'brochure_16',
  'brochure_20',
  'brochure_24',
  'brochure_28',
]);

export default createPerformableConfig(
  'design',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      layout: layoutSchema,
      type: typeSchema,
    }),
  },
  {
    name: 'Print Design',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/design.jpg',
    images: [],
    short: dedent`
      We design Flyers, Brochures, Booklets, and Postcards to showcase your \
      brand with style and professionalism.
    `,
  },
);
