import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { Mpi3dType } from '~/tenants/mpi/performable/3d/Mpi3dConfig';

export default function Mpi3dForm({}: TenantPerformableFormProps<MpiOrderContext>) {
  return (
    <ZodFieldSelect
      name="type"
      options={[
        { label: 'Matterport', value: Mpi3dType.MATTERPORT },
        { label: 'iGuide', value: Mpi3dType.IGUIDE },
      ]}
    />
  );
}
