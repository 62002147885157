import { TenantOrder } from '../../common/TenantOrder';
import { BRE_CANCEL_FEE, BRE_COPYRIGHT_PHOTO_FEE, BRE_COPYRIGHT_VIDEO_FEE, BRE_HOLIDAYS, BRE_HOLIDAY_FEE, BRE_TRAVEL_FEES, BRE_WEEKEND_FEE } from '../data';
import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BreFlyersJob from '~/tenants/bre/performable/print/flyers/BreFlyersJob';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';

import { DateTime } from 'luxon';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreJob from '~/tenants/bre/model/BreJob';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { datetime, formalTime } from '~/lib/datettime';
import { holiday_lookup } from '~common/holidays/holidays';
import { TenantPackageConfig } from '~/tenants/common/registry';
import { deliveryFee } from '~/tenants/bre/performable/common';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import BreSocialJob from '~/tenants/bre/performable/social/BreSocialJob';
import BreTwilightJob from '~/tenants/bre/performable/twilight/BreTwilightJob';

interface FeeLineOptions {
  id: string;
  date: DateTime;
  excludeWeekendFees: boolean | undefined;
}

export class BreOrder extends TenantOrder<BreOrderContext, BreJob> {
  get isFreeDelivery(): boolean {
    return this.jobs
      .filter((j) => j.isDelivery())
      .every((j) => j.isFreeDelivery());
  }

  needsDelivery(): boolean {
    return this.jobs.some((j) => j.isDelivery());
  }

  revenueLines(): TenantOrderLine[] {
    const { delivery, excludeTravelFees, excludeWeekendFees, type } = this.context.metadata;
    const hasSameDayFlyers = this.jobs.some((job) => job instanceof BreFlyersJob && job.needBy === 'same_day');
    const hasRushPhotos = this.jobs.some((job) => job instanceof BrePhotosJob && job.metadata.rush);
    const lines = super.revenueLines();

    if (hasSameDayFlyers && hasRushPhotos) {
      const { RUSH } = BrePhotosRate[type];

      lines.push({
        id: 'free_rush_photos',
        description: 'Same-day flyers photo rush discount',
        discount: true,
        amount: new Big(RUSH).neg(),
      });
    }

    const pkgConfig = this.tenant.packages
      .map(({ config }) => config)
      .filter((config) =>
        config.performables
          .map((p) => ('id' in p ? p.id : p.config.id))
          .every((id) => this.jobs.some((j) => j.performable.id === id)),
      )
      .reduce<TenantPackageConfig | null>((acc, config) => {
        if (acc !== null) {
          return config.performables.length > acc.performables.length ? config : acc;
        }

        return config;
      }, null);

    if (pkgConfig?.percentage) {
      const pkgJobs = this.jobs.filter((j) =>
        pkgConfig.performables.map((p) => ('id' in p ? p.id : p.config.id)).includes(j.performable.id),
      );
      const discountable = pkgJobs.reduce((acc, j) => {
        return acc.plus(j.revenue(true));
      }, new Big(0));

      lines.push({
        id: 'package_discount',
        description: `${pkgConfig.name} Package Discount`,
        amount: discountable.times(pkgConfig.percentage).neg(),
        discount: true,
      });
    }

    if (!excludeTravelFees) {
      if (!this.hasActiveAppointments && this.jobs.some((j) => j.onsite() > 0)) {
        const { zip } = this.context.address ?? {};

        if (zip) {
          const zone = BRE_TRAVEL_FEES.find((f) => f.zipcodes.includes(zip));

          if (zone?.fee) {
            lines.push({
              id: 'travel',
              description: 'Travel Compensation',
              amount: new Big(zone.fee),
              fee: 'travel',
            });
          }
        }

        this.context.requested.every(({ day }) => {
          const date = datetime(day, this.location.timezone);
          const feeLine = feeLineForDate({ id: 'requested', date, excludeWeekendFees });

          if (feeLine !== null) {
            lines.push(feeLine);
            return false;
          }

          return true;
        });
      }

      this.context.appointments.forEach(({ address, id, start, canceled }) => {
        const { zip } = address ?? this.context.address ?? {};
        const date = datetime(start, this.location.timezone);

        if (canceled) {
          lines.push({
            id: `cancel-${id}`,
            description: `Cancellation fee (${formalTime(date)})`,
            amount: new Big(BRE_CANCEL_FEE[canceled]),
            appointment_id: id,
          });
        } else if (zip) {
          const zone = BRE_TRAVEL_FEES.find((f) => f.zipcodes.includes(zip));
          const feeLine = feeLineForDate({ id, date, excludeWeekendFees });

          if (zone?.fee) {
            lines.push({
              id: `travel-${id}`,
              description: `Travel Compensation (${date.toFormat('MM/dd')})`,
              amount: new Big(zone.fee),
              fee: 'travel',
            });
          }

          if (feeLine !== null) {
            lines.push(feeLine);
          }
        }
      });
    }

    if (this.needsDelivery() && !this.isFreeDelivery && delivery?.time !== undefined) {
      const rate = deliveryFee(delivery.time, 'rate');

      if (rate) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(rate),
          fee: 'delivery',
        });
      }
    }

    if (this.context.metadata.type === BreOrderType.RENTAL) {
      const hasPhotos = this.jobs.some((j) =>
        (j instanceof BreAerialJob && (j.metadata.type !== 'video' || j.metadata.twilight))
        || (j instanceof BreCinematicJob && (j.metadata.aerial_photos || j.metadata.aerial_upgrade))
        || (j instanceof BrePhotosJob && j.metadata.photos > 0)
        || (j instanceof BreSocialJob && j.metadata.aerial)
        || (j instanceof BreTwilightJob && j.metadata.photos > 0),
      );
      const hasVideos = this.jobs.some((j) =>
        (j instanceof BreAerialJob && (j.metadata.type === 'video' || j.metadata.type === 'combo'))
        || j instanceof BreCinematicJob
        || j instanceof BreSocialJob
      );

      if (hasPhotos) {
        lines.push({
          id: 'photos_copyright_fee',
          description: 'Photography Copyright Fee',
          amount: new Big(BRE_COPYRIGHT_PHOTO_FEE[this.context.metadata.type]),
        });
      }

      if (hasVideos) {
        lines.push({
          id: 'video_copyright_fee',
          description: 'Video Copyright Fee',
          amount: new Big(BRE_COPYRIGHT_VIDEO_FEE[this.context.metadata.type]),
        });
      }
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { delivery, excludeTravelFees, excludeWeekendFees } = this.context.metadata;
    const lines = super.expenseLines();

    if (!excludeTravelFees) {
      this.context.appointments.forEach(({ id, address, start, canceled }) => {
        const { zip } = address ?? this.context.address ?? {};
        const date = datetime(start, this.location.timezone);

        if (canceled) {
          lines.push({
            id: `cancel-${id}`,
            description: `Cancellation Fee (${formalTime(date)})`,
            amount: new Big(BRE_CANCEL_FEE[canceled]).times('0.65'),
            appointment_id: id,
          });
        } else if (zip) {
          const zone = BRE_TRAVEL_FEES.find(({ zipcodes }) => zipcodes.includes(zip));
          const feeLine = feeLineForDate({ id, date, excludeWeekendFees });

          if (zone?.fee) {
            lines.push({
              id: `travel-${id}`,
              description: `Travel Compensation (${date.toFormat('MM/dd')})`,
              amount: new Big(zone.fee),
              fee: 'travel',
            });
          }

          if (feeLine !== null) {
            lines.push(feeLine);
          }
        }
      });
    }

    if (this.needsDelivery() && !this.isFreeDelivery && delivery?.time !== undefined) {
      const pay = deliveryFee(delivery.time, 'pay');

      if (pay) {
        lines.push({
          id: 'delivery_fee',
          description: `Delivery Fee (${delivery.city})`,
          amount: new Big(pay),
        });
      }
    }

    return lines;
  }
}

function feeLineForDate({ id, date, excludeWeekendFees }: FeeLineOptions): TenantOrderLine | null {
  const holidays = holiday_lookup(date);

  if (holidays.some((h) => BRE_HOLIDAYS.includes(h))) {
    return {
      id: `${id}-holiday`,
      description: 'Holiday Fee',
      amount: new Big(BRE_HOLIDAY_FEE),
      fee: 'holiday',
    };
  }

  if (!excludeWeekendFees && date.weekday > 5) {
    return {
      id: `${id}-weekend`,
      description: 'Weekend fee',
      amount: new Big(BRE_WEEKEND_FEE),
      fee: 'weekend',
    };
  }

  return null;
}
