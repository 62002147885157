import { ReactElement } from 'react';
import { BreOrder } from '~/tenants/bre/model/BreOrder';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreDelivery from '~/tenants/bre/performable/print/BreDelivery';
import { useTenant } from '~/tenants/common/TenantContextProvider';

interface BreOrderDeliveryProps {
  context: BreOrderContext;
}

export default function BreOrderDelivery({ context }: BreOrderDeliveryProps): ReactElement {
  const tenant = useTenant();

  const order = new BreOrder(tenant, context);

  return (
    <BreDelivery
      name="delivery"
      label="Delivery Address"
      context={context}
      required
      free={order.isFreeDelivery}
    />
  );
}
