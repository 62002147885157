import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreCopyrightDisclosure from '~/tenants/bre/performable/BreCopyrightDisclosure';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreCinematicAddOns from '~/tenants/bre/performable/cinematic/BreCinematicAddOns';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';

export default function BreCinematicForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { RUSH } = BreCinematicRate[context.metadata.type];

  return (
    <FormGroup>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <FormHorizontal name="duration" label="Duration (mins)">
          <ZodFieldInput name="duration" type="number" />
        </FormHorizontal>
      )}

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckboxName
          name="rush"
          title="Rush"
          description="Need your video by the next business day instead of the second business day?"
          image="tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
        <BreCinematicAddOns context={context} persona={persona} />
      </TenantPerformableAddOns>

      {context.metadata.type === BreOrderType.RENTAL && <BreCopyrightDisclosure context={context} />}
      <BreListingDisclosure />
    </FormGroup>
  );
}
