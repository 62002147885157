import NepAerialConfig, { NepAerialPhotographyOptionId } from '~/tenants/nep/performable/aerial/NepAerialConfig';
import NepJob from '~/tenants/nep/model/NepJob';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { NepOrderType } from '~/tenants/nep/model/NepOrder';

interface NepAerialPhotographyOptions {
  revenue: number;
  photos: number;
  acres: number;
}

export const DEMO_AERIAL_OPTIONS: Record<NepAerialPhotographyOptionId, NepAerialPhotographyOptions> = {
  [NepAerialPhotographyOptionId.ONE]: {
    revenue: 175,
    photos: 10,
    acres: 1,
  },
  [NepAerialPhotographyOptionId.TWO]: {
    revenue: 225,
    photos: 15,
    acres: 2,
  },
  [NepAerialPhotographyOptionId.THREE]: {
    revenue: 275,
    photos: 20,
    acres: 3,
  },
  [NepAerialPhotographyOptionId.FOUR]: {
    revenue: 325,
    photos: 25,
    acres: 4,
  },
};

export default class NepAerialJob extends NepJob<typeof NepAerialConfig> {
  get performable() {
    return NepAerialConfig;
  }

  onsite(): number {
    return 30;
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.community) {
      info.push({ name: 'Community Photos', value: 'Yes' });
    }

    return info;
  }

  get configurable() {
    return this.order.metadata.type !== NepOrderType.COMMERCIAL;
  }

  get multiple() {
    return this.order.metadata.type !== NepOrderType.COMMERCIAL;
  }

  get hourly() {
    return this.order.metadata.type === NepOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof NepAerialConfig)['schema']> {
    let option = NepAerialPhotographyOptionId.ONE;

    for (const [key, value] of Object.entries(DEMO_AERIAL_OPTIONS)) {
      if (this.order.metadata.acres <= Math.min(4, value.acres)) {
        // TODO: better typing
        option = key as any as NepAerialPhotographyOptionId;
        break;
      }
    }

    return {
      option,
      version: schema_latest_version(NepAerialConfig.schema),
    };
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === NepOrderType.COMMERCIAL) {
      return [];
    }

    const amount = new Big(DEMO_AERIAL_OPTIONS[this.metadata.option].revenue);

    const lines: TenantOrderLine[] = [
      {
        amount,
        description: this.performable.name,
        discountable: true,
        id: this.performable.id,
      },
    ];

    if (this.metadata.community) {
      lines.push({
        amount: new Big(60),
        description: 'Community Photos',
        id: 'community',
      });
    }

    return lines;
  }
}
