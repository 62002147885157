import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export default function BreCustomForm(): ReactElement {
  return (
    <FormGroup>
      <FormHorizontal name="description" label="Description" required>
        <ZodFieldTextarea name="description" />
      </FormHorizontal>
      <FormHorizontal name="rate" label="Price">
        <ZodFieldInput name="rate" type="number" />
      </FormHorizontal>
      <FormHorizontal name="pay" label="Payout">
        <ZodFieldInput name="pay" type="number" />
      </FormHorizontal>
      <FormHorizontal name="onsite" label="Onsite (minutes)">
        <ZodFieldInput name="onsite" type="number" />
      </FormHorizontal>
      <FormHorizontal name="taxable" label="Taxable">
        <ZodFieldCheckbox name="taxable" />
      </FormHorizontal>
    </FormGroup>
  );
}
