import { TenantPackageAddOnTemplate } from '~/tenants/common/package';
import { format_money } from '~/components/Money';
import { TWT_PHOTO_DOMAIN } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import InputCheckbox from '~/components/input/InputCheckbox';
import Button from '~/components/interactive/Button';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import Modal, { ModalHeader, ModalWrapper } from '~/components/interactive/Modal';
import ValibotForm from '~/components/valibot/ValibotForm';
import { SCHEMA_API_DOMAIN_AVAILABLE } from '~/routes/api+/schema';
import Message from '~/components/Message';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';
import ValibotButton from '~/components/valibot/ValibotButton';
import React, { useState } from 'react';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import useSubmitSchema from '~/components/hooks/useSubmitSchema';
import type { ApiDomainAvailableResponse } from '~/routes/api+/domain.available';

export default function TwtPhotoDomain({ persona }: { persona: PersonaType }) {
  const [show, setShow] = useState(false);
  const [_domain, domain, setDomain] = useZodFormFieldSingle('domain');
  const [submitted, onSubmit] = useSubmitSchema<ApiDomainAvailableResponse>(
    SCHEMA_API_DOMAIN_AVAILABLE,
    '/api/domain/available',
  );

  return (
    <TenantPackageAddOnTemplate
      persona={persona}
      image="tenant/twt/microsite/domain.jpg"
      title="Custom Domain"
      cost={format_money(TWT_PHOTO_DOMAIN)}
      description="Register a custom domain for your tour instead of using the default mls.tours subdomain."
      enable={
        <InputCheckbox
          checked={Boolean(domain)}
          onChange={(checked) => {
            if (checked && !domain) {
              setShow(true);
            } else if (!checked && domain) {
              setDomain(null);
            }
          }}
        />
      }
    >
      {domain && (
        <div className="flex items-center space-x-2 pt-4">
          <Button
            type="button"
            variant="transparent"
            icon={<IconDelete />}
            onClick={(e) => {
              e.preventDefault();

              setDomain(null);
            }}
          />
          <p>{domain}</p>
          <ZodFieldHidden name="domain" value={domain} />
        </div>
      )}
      <Modal show={show} onClose={() => setShow(false)}>
        <ModalWrapper>
          <ModalHeader>Domain Search</ModalHeader>
          <ValibotForm schema={SCHEMA_API_DOMAIN_AVAILABLE} onSubmit={onSubmit}>
            <div className="p-4 w-[500px] space-y-4">
              {submitted && submitted.available && <Message type="success">That domain is available!</Message>}
              {submitted && !submitted.available && <Message type="error">That domain is not available.</Message>}
              <div className="flex items-center space-x-2">
                <span className="text-sm">www.</span>
                <ValibotFieldInput name="domain" placeholder="123PeachtreeSt.com" />
                {submitted && submitted.available && <ValibotButton>Search</ValibotButton>}
              </div>
              {submitted && submitted.available ? (
                <div className="flex items-center border-t border-theme-separator pt-4">
                  <div className="w-28 shrink-0">
                    <Button
                      type="button"
                      onClick={() => {
                        setDomain(submitted?.domain);
                        setShow(false);
                      }}
                      variant="tenant"
                    >
                      Register
                    </Button>
                  </div>
                  <p className="text-xs text-justify">
                    By continuing you acknowledge that when this order is submitted, you will be charged upfront for the
                    domain and that fee is non-refundable.
                  </p>
                </div>
              ) : (
                <ValibotButton showError>Search</ValibotButton>
              )}
            </div>
          </ValibotForm>
        </ModalWrapper>
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}
