import { FormHorizontal } from '~/components/form/layout';
import FormGroup from '~/components/form/FormGroup';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodSubmitButton from '~/components/zod/ZodSubmitButton';
import { PerformableFormRevenue } from '~/components/performable/PerformableForm';
import React from 'react';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';

export default function TwtPhotoReshootForm() {
  return (
    <FormGroup>
      <ZodFieldHidden name="type" value={TwtPhotoType.RESHOOT} />
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldSelect
          options={[
            { label: '10 Photos', value: '0' },
            { label: '15 Photos', value: '5' },
            { label: '20 Photos', value: '10' },
            { label: '25 Photos', value: '15' },
            { label: '30 Photos', value: '20' },
            { label: '35 Photos', value: '25' },
            { label: '40 Photos', value: '30' },
            { label: '45 Photos', value: '35' },
            { label: '50 Photos', value: '40' },
          ]}
          name="photos"
        />
      </FormHorizontal>
      <FormHorizontal name="instructions" label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>

      <ZodSubmitButton variant="tenant">
        Submit for <PerformableFormRevenue />
      </ZodSubmitButton>
    </FormGroup>
  );
}
