import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';

export default function BrePhotosSubmitForm(): ReactElement {
  return (
    <FormGroup>
      <FormHorizontal name="extra_photos" label="Extra Photos">
        <ValibotFieldInput name="extra_photos" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}
