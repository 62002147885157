import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreFloorplanJob from '~/tenants/bre/performable/floorplan/BreFloorplanJob';
import BreMatterportAddOns from '~/tenants/bre/performable/matterport/BreMatterportAddOns';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const PLAN_OPTS: InputSelectOption[] = [
  { label: 'Basic', value: 'basic' },
  { label: 'Advanced', value: 'advanced' },
];

export default function BreMatterportForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement | null {
  const { context, persona } = props;
  const { type } = context.metadata;
  const isCommercial = type === BreOrderType.COMMERCIAL;

  if (type === BreOrderType.AGENT_MKTG || type === BreOrderType.PROPERTY_MKTG) {
    return null;
  }

  return (
    <FormGroup>
      {isCommercial && (
        <FormHorizontal name="plan" label="Plan">
          <ZodFieldSelect name="plan" options={PLAN_OPTS} />
        </FormHorizontal>
      )}

      <TenantPerformableAddOns persona={persona}>
        <BreMatterportAddOns context={context} persona={persona} />

        {!isCommercial && persona === PersonaType.OFFICE && (
          <TenantPackageAddOnCheckboxName
            name="foorplan"
            title="2D Floorplan"
            description="Add 2D floorplans"
            image="tenant/bre/floorplan/thumbnail.jpg"
            persona={persona}
            cost={BreFloorplanJob.rate(context.metadata.sqft, type).toFixed(2)}
          />
        )}
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
