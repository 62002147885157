import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { TWT_ACCESS_TYPE_NAME, TwtAccessType, TwtOccupancy, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { useZodFormFieldSingle, useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { useRef } from 'react';
import { PersonaType } from '~/tenants/common/TenantPersona';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { TenantOrderFormProps } from '~/tenants/common/form';
import { TwtOrderType } from '~/tenants/twt/model/enums';

export default function TwtOrderForm({ persona }: TenantOrderFormProps) {
  // TODO: better way to handle this
  const [_type, type] = useZodFormFieldSingle<TwtOrderType>('type');
  const [_subtype, subtype] = useZodFormFieldSingle<TwtPropertyType>('subtype');
  const access = useZodFormFieldSingleValue<TwtAccessType>('access');
  const preselected = useRef(type !== undefined);

  return (
    <FormGroup>
      {preselected.current && <ZodFieldHidden name="type" />}
      {!preselected.current && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              {
                label: 'Residential',
                value: TwtOrderType.FOR_SALE_LISTING,
              },
              {
                label: 'Short Term Rental',
                value: TwtOrderType.SHORT_TERM_RENTAL,
              },
              {
                label: 'Long Term Rental',
                value: TwtOrderType.LONG_TERM_RENTAL,
              },
              {
                label: 'Commercial',
                value: TwtOrderType.COMMERCIAL,
              },
            ]}
          />
        </FormHorizontal>
      )}
      {type === TwtOrderType.COMMERCIAL ? (
        <ZodFieldHidden name="speed" value="standard" />
      ) : (
        <FormHorizontal name="speed" label="Delivery">
          <ZodFieldSelect
            name="speed"
            options={[
              { label: 'Standard (6PM Next Day)', value: 'standard' },
              { label: 'Rush (8PM Same Day)', value: 'rush' },
              { label: 'Priority (9AM Next Day)', value: 'priority' },
            ]}
          />
          {persona === PersonaType.CUSTOMER && (
            <p className="text-sm mt-2">
              <strong>Note:</strong> Rush/Priority processing does not apply to Twilight, Matterport, Floor Plan or
              Aerial/Video products.
            </p>
          )}
        </FormHorizontal>
      )}
      {type === TwtOrderType.COMMERCIAL && (
        <FormHorizontal name="subtype" label="Property Type">
          <ZodFieldSelect
            name="subtype"
            options={[
              {
                label: 'Commercial Building',
                value: TwtPropertyType.BUILDING,
              },
              {
                label: 'Land Only',
                value: TwtPropertyType.LAND,
              },
            ]}
          />
        </FormHorizontal>
      )}
      {type !== TwtOrderType.COMMERCIAL && (
        <FormHorizontal name="subtype" label="Property Type">
          <ZodFieldSelect
            name="subtype"
            options={[
              {
                label: 'Single-Family Home',
                value: TwtPropertyType.SINGLE_FAMILY,
              },
              {
                label: 'Multi-Family Unit',
                value: TwtPropertyType.MULTI_FAMILY,
              },
              {
                label: 'Townhome',
                value: TwtPropertyType.TOWNHOME,
              },
              {
                label: 'Condo',
                value: TwtPropertyType.CONDO,
              },
              {
                label: 'Apartment',
                value: TwtPropertyType.APARTMENT,
              },
              {
                label: 'Land Only',
                value: TwtPropertyType.LAND,
              },
            ]}
          />
        </FormHorizontal>
      )}
      {subtype && subtype !== TwtPropertyType.LAND && (
        <FormHorizontal name="unit" label="Unit">
          <ZodFieldInput name="unit" />
        </FormHorizontal>
      )}
      {subtype === TwtPropertyType.LAND ? (
        <>
          <FormHorizontal name="acres" label="Acres">
            <ZodFieldInput type="number" name="acres" />
          </FormHorizontal>
        </>
      ) : (
        <FormHorizontal name="sqft" label="Square Feet">
          <ZodFieldInput name="sqft" type="number" />
        </FormHorizontal>
      )}
      {subtype !== TwtPropertyType.LAND && (
        <FormHorizontal name="occupancy" label="Occupancy">
          <ZodFieldSelect
            name="occupancy"
            options={[
              {
                label: 'Vacant',
                value: TwtOccupancy.VACANT,
              },
              {
                label: 'Seller Occupied',
                value: TwtOccupancy.SELLER,
              },
              {
                label: 'Tenant Occupied',
                value: TwtOccupancy.TENANT,
              },
            ]}
          />
        </FormHorizontal>
      )}
      <FormHorizontal name="access" label="Property Access">
        <ZodFieldSelect
          name="access"
          placeholder="Please select..."
          options={Object.values(TwtAccessType).map((type) => ({
            label: TWT_ACCESS_TYPE_NAME[type],
            value: type,
          }))}
        />
      </FormHorizontal>
      {access === TwtAccessType.LOCKBOX && (
        <FormHorizontal label="Lockbox Code">
          <ZodFieldInput name="code" />
        </FormHorizontal>
      )}
      <FormHorizontal label="Gate Code">
        <ZodFieldInput name="gate" />
      </FormHorizontal>
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="special" />
      </FormHorizontal>
    </FormGroup>
  );
}
