import { ReactElement } from 'react';
import { match } from 'ts-pattern';
import { FormHorizontal } from '~/components/form/layout';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { typeSchema } from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';

interface BreAerialFieldsProps {
  type: BreOrderType;
}

export default function BreAerialFields({ type }: BreAerialFieldsProps): ReactElement {
  const { COMBO, FIVE, TEN, VIDEO } = BreAerialRate[type];

  const typeOptions = typeSchema.options
    .filter((value) => (type === BreOrderType.LONG_TERM ? value !== 'combo' && value !== 'video' : true))
    .map<InputCardOption>((value) => ({
      title: BreAerialJob.typeText(value),
      cost: match(value)
        .with('five', () => FIVE)
        .with('ten', () => TEN)
        .with('combo', () => COMBO)
        .with('video', () => VIDEO)
        .exhaustive(),
      value,
    }));

  return (
    <FormHorizontal name="type" label="Aerial Package" required>
      <div className="flex flex-col gap-4">
        <ZodFieldCards name="type" cols={2} options={typeOptions} required />
      </div>
    </FormHorizontal>
  );
}
