import FormGroup from '~/components/form/FormGroup';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import React from 'react';
import { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import ZodSubmitButton from '~/components/zod/ZodSubmitButton';
import { PerformableFormRevenue } from '~/components/performable/PerformableForm';

export default function MpiReshootForm() {
  return (
    <FormGroup>
      <ZodFieldHidden name="type" value={MpiPhotosType.RESHOOT} />
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>
      <ZodSubmitButton variant="tenant">
        Submit for <PerformableFormRevenue />
      </ZodSubmitButton>
    </FormGroup>
  );
}
