import { Big } from 'big.js';
import { TenantOrderLine } from '~/lib/model';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import MpiVirtualStagingConfig from './MpiVirtualStagingConfig';
import MpiJob from '../../model/MpiJob';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';

const MPI_VIRTUAL_STAGIN_PER_PHOTO_BASE_COST = 30;
const MPI_VIRTUAL_STAGIN_PER_PHOTO_DISCOUNT_COST = 25;

export default class MpiVirtualStagingJob extends MpiJob<typeof MpiVirtualStagingConfig> {
  get performable() {
    return MpiVirtualStagingConfig;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpiVirtualStagingConfig)['schema']> {
    return {
      photos: 1,
      version: schema_latest_version(MpiVirtualStagingConfig.schema),
    };
  }

  get eligible() {
    return true;
  }

  get configurable() {
    return true;
  }

  get input() {
    return {
      performables: [MpiPhotosConfig.id],
      required: this.metadata.photos,
      title: 'Select Staging Photos',
      description: 'choose which photos you would like virtually staged',
    };
  }

  revenueLines(): TenantOrderLine[] {
    const numberOfPhotos = this.metadata.photos;

    return [
      {
        amount:
          numberOfPhotos >= 5
            ? new Big(MPI_VIRTUAL_STAGIN_PER_PHOTO_DISCOUNT_COST).times(numberOfPhotos)
            : new Big(MPI_VIRTUAL_STAGIN_PER_PHOTO_BASE_COST).times(numberOfPhotos),
        discountable: false,
        description: this.performable.name,
        id: this.performable.id,
      },
    ];
  }
}
