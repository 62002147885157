import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { MpiCustomerMetadata } from '~/tenants/mpi/model/MpiCustomer';
import { TenantOrder } from '~/tenants/common/TenantOrder';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { datetime } from '~/lib/datettime';
import MpiFloorplanConfig from '../performable/floorplan/MpiFloorplanConfig';
import MpiVirtualStagingConfig from '../performable/staging/MpiVirtualStagingConfig';
import MpiAerialConfig from '../performable/aerial/MpiAerialConfig';
import MpiBrochuresConfig from '../performable/brochures/MpiBrochuresConfig';
import MpiFlyerConfig from '~/tenants/mpi/performable/flyer/MpiFlyerConfig';
import MpiHeadshotConfig from '~/tenants/mpi/performable/headshot/MpiHeadshotConfig';
import { MpiProviderStaticData } from '~/tenants/mpi/model/MpiProvider';
import MpiWalkthroughConfig from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughConfig';
import MpiVirtualConfig from '~/tenants/mpi/performable/virtual/MpiVirtualConfig';
import Mpi3dConfig from '~/tenants/mpi/performable/3d/Mpi3dConfig';

const BULK_ORDER_DISCOUNT: Record<number, number> = {
  2: 0.08,
  3: 0.1,
  4: 0.12,
  5: 0.15,
  6: 0.18,
  7: 0.21,
};

export enum MpiOrderType {
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
}

export enum MpiPropertyAccess {
  HOMEOWNER = 'HOMEOWNER',
  AGENT = 'AGENT',
  LOCKBOX = 'LOCKBOX',
  SENTRI = 'SENTRI',
}

export const MpiOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z.object({
    version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    type: z.nativeEnum(MpiOrderType),
    access: z.nativeEnum(MpiPropertyAccess).optional(),
    lockbox: z.string().optional(),
    sqft: z.coerce
      .number({
        required_error: 'Please provide the square footage of the property or unit.',
        invalid_type_error: 'Please provide the square footage of the property or unit.',
      })
      .min(1, { message: 'The square footage of the property or unit must be greater than 1.' }),
  }),
};

export type MpiOrderMetadata = ZodVersionedMetadata<typeof MpiOrderSchema>;

export type MpiPerformableConfig =
  | typeof MpiPhotosConfig
  | typeof MpiFloorplanConfig
  | typeof MpiVirtualStagingConfig
  | typeof MpiAerialConfig
  | typeof MpiBrochuresConfig
  | typeof MpiFlyerConfig
  | typeof MpiHeadshotConfig
  | typeof MpiWalkthroughConfig
  | typeof MpiVirtualConfig
  | typeof Mpi3dConfig;

export type MpiOrderContext = TenantOrderContext<
  MpiOrderMetadata,
  MpiPerformableConfig,
  MpiCustomerMetadata,
  MpiProviderStaticData
>;

export class MpiOrder extends TenantOrder<MpiOrderContext> {
  get confirmed(): boolean {
    // always require manual review before scheduling
    return false;
  }

  info(): Array<DisplayData> {
    const info = super.info();

    const type = (() => {
      switch (this.context.metadata.type) {
        case MpiOrderType.RESIDENTIAL:
          return 'Real estate property';
        default:
          return undefined;
      }
    })();

    if (type) {
      info.push({
        name: 'Order Type',
        invoice: true,
        value: type,
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    if (this.context.metadata.sqft) {
      info.push({
        name: 'Square Feet',
        invoice: true,
        provider: true,
        customer: true,
        schedule: true,
        value: this.context.metadata.sqft.toString(),
      });
    }

    return info;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();
    const onsite = this.jobs.some((j) => j.onsite() > 0);

    if (onsite && !this.hasAppointments && !this.context.buyer.metadata.discount_gas) {
      lines.push({
        id: 'travel',
        description: 'Travel Compensation',
        amount: new Big(5),
        fee: 'travel',
      });
    }

    if (this.requestedOnWeekend && this.context.appointments.length === 0) {
      lines.push({
        id: 'weekend-requested',
        description: 'Weekend Fee',
        amount: new Big(150),
        fee: 'weekend',
      });
    }

    for (const appointment of this.context.appointments) {
      const isOnWeekend = datetime(appointment.start, this.location.timezone).weekday >= 6;

      if (isOnWeekend) {
        lines.push({
          id: `weekend-${appointment.id}`,
          description: 'Weekend Fee',
          amount: new Big(150),
          fee: 'weekend',
        });
      }

      if (!this.context.buyer.metadata.discount_gas) {
        lines.push({
          id: `travel-${appointment.id}`,
          description: 'Travel Compensation',
          amount: new Big(5),
        });
      }
    }

    if (this.jobs.length > 1) {
      const discountRatio = new Big(BULK_ORDER_DISCOUNT[Math.min(this.jobs.length, 7)]);
      const jobsRevenue = this.jobs.reduce((prev, curr) => prev.plus(curr.revenue(false)), new Big(0));

      lines.push({
        id: 'bulk-discount',
        description: `Order Discount (${this.jobs.length} Services: ${discountRatio.times(100).toFixed(0)}%)`,
        amount: discountRatio.times(jobsRevenue).times(-1),
        discountable: false,
      });
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    if (this.context.buyer.metadata.provider_increase) {
      for (const job of this.jobs) {
        lines.push({
          id: `provider-increase-${job.id}`,
          description: 'Provider Increase',
          job_id: job.id,
          amount: job
            .expense()
            .plus(job.expense().times(new Big(this.context.buyer.metadata.provider_increase).div(100))),
        });
      }
    }

    for (const appointment of this.context.appointments) {
      if (appointment.provider.expense_appointment) {
        lines.push({
          id: `flat-${appointment.id}`,
          description: 'Appointment',
          appointment_id: appointment.id,
          amount: new Big(appointment.provider.expense_appointment),
        });
      }
    }

    return lines;
  }
}
