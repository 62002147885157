import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberRequired,
  FIRST_VERSION_TIMESTAMP,
  zodNativeEnum,
} from '~/lib/zod';
import z from 'zod';

export const DemoPhotographyRealTwilightPrice = 200;
export const DemoPhotographyVirtualTwilightPrice = 25;
export const DemoPhotographyTwilightCommercialTime = 60;

export enum DemoPhotographyOptionId {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
}

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      option: zodNativeEnum(DemoPhotographyOptionId),
      virtual_twilight: z.coerce.number().optional(),
      real_twilight: coerceBoolean().optional(),
      community: coerceBoolean().optional(),
      license: z.string().array().optional(),
    }),
  },
  {
    name: 'Photography',
    short: 'High resolution real estate photography to showcase your listing.',
    thumbnail: 'card/public/photos.jpg',
    tenant: TenantId.NE_PHOTO,
    images: [],
    group: 'Digital Media',
  },
);
