import MpxJob from '~/tenants/mpx/model/MpxJob';
import { TenantJobLine } from '~/lib/model';
import MpxZillowConfig from '~/tenants/mpx/performable/zillow/MpxZillowConfig';
import Big from 'big.js';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxZillowJob extends MpxJob<typeof MpxZillowConfig> {
  get performable() {
    return MpxZillowConfig;
  }

  onsite(): number {
    return 30;
  }

  get eligible(): boolean {
    return [MpxOrderType.RESIDENTIAL, MpxOrderType.BUILDERS].includes(this.order.metadata.type);
  }

  get configurable(): boolean {
    return this.order.metadata.type !== MpxOrderType.BUILDERS;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxZillowConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines() {
    const lines: TenantJobLine[] = [];

    let amount = new Big(0);
    let discount = new Big(0);

    if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      let photos = 0;
      let matterport = false;

      for (const job of this.order.jobs) {
        if (job.performable_id === 'photos' && job.metadata.type === 'photos') {
          if (job.metadata.photos > photos) {
            photos = job.metadata.photos;
          }
        }

        if (job.performable_id === 'matterport') {
          matterport = true;
        }
      }

      if (photos > 0 && matterport) {
        amount = new Big(99);
      } else if ('sqft' in this.order.metadata) {
        if (this.order.metadata.sqft < 2000) {
          amount = new Big(149);

          if (photos >= 24) {
            discount = new Big(75);
          }
        } else if (this.order.metadata.sqft < 4000) {
          amount = new Big(159);

          if (photos >= 36) {
            discount = new Big(105);
          }
        } else if (this.order.metadata.sqft < 6000) {
          amount = new Big(199);

          if (photos >= 36) {
            discount = new Big(125);
          }
        }
      }
    } else if (this.order.metadata.type === MpxOrderType.BUILDERS) {
      amount = new Big(145);
    }

    if (amount.gt(0)) {
      lines.push({
        description: '3D Home Scan',
        amount,
        taxable: true,
        id: 'scan',
      });
    }

    if (discount.gt(0)) {
      lines.push({
        description: 'Zillow 3D Home Bundle Discount',
        amount: discount.times(-1),
        discount: true,
        taxable: true,
        id: 'bundle',
      });
    }

    if (this.metadata.floorplan) {
      lines.push({
        description: 'Floorplan',
        amount: new Big(49),
        taxable: true,
        id: 'floorplan',
      });
    }

    return lines;
  }
}
