import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreCustomConfig from '~/tenants/bre/performable/custom/BreCustomConfig';

export default class BreCustomJob extends BreJob<typeof BreCustomConfig> {
  get performable(): typeof BreCustomConfig {
    return BreCustomConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get hidden(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreCustomConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      description: '',
      rate: 0,
    };
  }

  onsite(): number {
    const { onsite } = this.metadata;

    return onsite ?? 0;
  }

  revenueLines(): TenantJobLine[] {
    const { description, rate, taxable } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'custom_service',
        description,
        amount: new Big(rate),
        taxable,
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { description, pay } = this.metadata;
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'custom_service',
        description,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}
