import classNames from 'classnames';
import { ReactElement, useEffect, useId } from 'react';
import { format_money } from '~/components/Money';
import ResponsiveImage from '~/components/ResponsiveImage';
import InputCheckbox from '~/components/input/InputCheckbox';

export interface InputCardOption {
  thumbnail?: string;
  title: string;
  description?: string;
  cost?: string | number;
  disabled?: boolean;
  value: string;
}

interface InputCardsBaseProps {
  cols?: 1 | 2 | 3;
  costPrefix?: string;
  disabled?: boolean;
  name?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  options: InputCardOption[];
  required?: boolean;
}

interface InputCardsSingleProps extends InputCardsBaseProps {
  multiple?: false;
  onChange?: (value: string | null) => void;
  value?: string | null;
}

interface InputCardsMultiProps extends InputCardsBaseProps {
  multiple: true;
  onChange?: (value: string[]) => void;
  value?: string[];
}

export type InputCardsProps = InputCardsSingleProps | InputCardsMultiProps;

export default function InputCards(props: InputCardsProps): ReactElement {
  const {
    cols = 3,
    costPrefix = 'for',
    disabled,
    multiple,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    required,
    value,
  } = props;

  const optionsId = useId();

  useEffect(() => {
    if (value) {
      if (multiple) {
        const enabled = options.filter((option) => !option.disabled).map((option) => option.value);
        const next = value.filter((val) => enabled.includes(val));

        return onChange?.(next);
      }

      if (options.find((option) => option.value === value)?.disabled) {
        const next = options.find((option) => !option.disabled);
        onChange?.(next?.value ?? null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, JSON.stringify(value), JSON.stringify(options)]);

  useEffect(() => {
    if (!multiple && required) {
      const next = options.find((option) => !option.disabled);

      if (!value && next) {
        onChange?.(next.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, required, JSON.stringify(value), JSON.stringify(options)]);

  return (
    <div
      className={classNames('grid grid-cols-1 gap-3', {
        'md:grid-cols-1': cols === 1,
        'md:grid-cols-2': cols === 2,
        'md:grid-cols-3': cols === 3,
      })}
    >
      {options.map((option) => {
        const isDisabled = disabled || option.disabled;
        const isSelected = multiple ? value?.includes(option.value) : option.value === value;

        return (
          <button
            key={`${optionsId}-${option.value}`}
            className={classNames(
              'relative flex flex-col text-left border border-gray-300 rounded-theme outline-none overflow-hidden transition ease-in-out duration-200',
              {
                'hover:border-gray-400': !isDisabled && !isSelected,
                'border-gray-900 ring-1 ring-black': isSelected,
                'bg-gray-100 cursor-not-allowed': isDisabled,
              },
            )}
            onClick={() => {
              if (multiple) {
                const next = value?.includes(option.value)
                  ? value?.filter((v) => option.value !== v)
                  : [option.value, ...(value ?? [])];

                return onChange?.(next ?? []);
              }

              onChange?.(option.value === value && !required ? null : option.value);
            }}
            disabled={isDisabled}
            onFocus={onFocus}
            onBlur={onBlur}
            type="button"
          >
            {multiple && (
              <div className="absolute top-0 right-1">
                <InputCheckbox size="sm" checked={isSelected} disabled={isDisabled} />
              </div>
            )}
            {option.thumbnail && (
              <ResponsiveImage
                className="w-full max-h-40 aspect-video object-cover pointer-events-none"
                path={option.thumbnail}
                width={350}
                sharpen
              />
            )}
            <div className={classNames('p-3', { 'pr-6': multiple && !option.thumbnail })}>
              <span className={classNames('font-bold text-gray-400', { 'text-gray-800': !isDisabled })}>
                {option.title}
              </span>
              {(option.description || option.cost) && (
                <div className={classNames('text-sm text-gray-400', { 'text-gray-600': !isDisabled })}>
                  {option.description && <div>{option.description}</div>}
                  {option.cost && (
                    <div>
                      {costPrefix} {format_money(option.cost)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </button>
        );
      })}

      {name && value && (
        <>
          {multiple &&
            value.map((option, i) => (
              <input key={option} id={option} name={`${name}[${i}]`} type="hidden" checked={true} value={option} readOnly />
            ))}

          {!multiple && <input name={name} type="hidden" value={value} />}
        </>
      )}
    </div>
  );
}
