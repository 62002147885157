import classNames from 'classnames';
import { ReactElement, useMemo, useState } from 'react';
import { format_money } from '~/components/Money';
import { FormHorizontal } from '~/components/form/layout';
import InputCheckbox from '~/components/input/InputCheckbox';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderMetadata } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';

interface BrePhotosExtraProps {
  metadata: BreOrderMetadata;
}

const MAX_EXTRA_OPTIONS = Array.from({ length: 7 })
  .map((_, i) => (i + 1) * 5)
  .map<InputSelectOption>((value) => ({
    label: `${value} Photos`,
    value: `${value}`,
  }));

export default function BrePhotosExtra({ metadata }: BrePhotosExtraProps): ReactElement {
  const photos = useZodFormFieldSingleValue('photos', Number) || 0;
  const maxExtraPhotos = useZodFormFieldSingleValue('max_extra_photos', Number) || 0;
  const extraConsent = useZodFormFieldSingleValue('extras_consent', (v) => v === 'true');

  const [agree, setAgree] = useState(extraConsent && maxExtraPhotos > 0);
  const [reject, setReject] = useState(extraConsent && maxExtraPhotos <= 0);

  const minPrice = useMemo(() => {
    const price = BrePhotosJob.rate({ metadata, photos: 15 });
    return format_money(price.toFixed(2));
  }, [JSON.stringify(metadata)]);

  const cost = useMemo((): number => {
    const basePrice = BrePhotosJob.rate({ metadata, photos });
    const totalPrice = BrePhotosJob.rate({ metadata, photos: photos + maxExtraPhotos });
    return totalPrice.minus(basePrice).toNumber();
  }, [photos, maxExtraPhotos, JSON.stringify(metadata)]);

  return (
    <div className="flex flex-col gap-4 px-4 py-2 rounded-md bg-red-50 text-red-600">
      <div className={classNames('flex flex-col gap-2', { 'pointer-events-none opacity-50': reject })}>
        <div className="flex justify-between">
          <label className="flex gap-2 font-medium cursor-pointer" htmlFor="agree_extra">
            <InputCheckbox id="agree_extra" checked={agree} onChange={setAgree} disabled={reject} />
            Let the photographer choose.
          </label>

          {agree && <span className="font-bold whitespace-nowrap">Up to {format_money(cost)}</span>}
        </div>
        <span className="text-sm text-red-500">
          Our photographer may upgrade to the next package if your selection isn&apos;t sufficient. You agree to
          additional charges for extra photos needed to showcase the property.
        </span>
        {agree && (
          <FormHorizontal name="max_extra_photos" label="I Authorize up to">
            <ZodFieldSelect name="max_extra_photos" options={MAX_EXTRA_OPTIONS} />
          </FormHorizontal>
        )}
        <ZodFieldHidden name="extras_consent" value={`${agree || reject}`} />
      </div>

      <div className={classNames('flex flex-col gap-2', { 'pointer-events-none opacity-50': agree })}>
        <label className="flex gap-2 font-medium cursor-pointer" htmlFor="no_extra">
          <InputCheckbox id="no_extra" checked={reject} onChange={setReject} disabled={agree} />I do not want more
          photos.
        </label>
        <span className="text-sm text-red-500">I understand the risk. Reshoots are a min of {minPrice}.</span>
      </div>
    </div>
  );
}
