import dedent from 'dedent';
import { TenantPackageConfig } from '~/tenants/common/registry';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import { BreOrderType } from '~/tenants/bre/model/enums';

const BreSocialPackageConfig: TenantPackageConfig<BreOrderContext> = {
  id: 'social_package',
  name: 'Social Media',
  percentage: 0.1,
  performables: [
    BrePhotosConfig,
    BreFloorplanConfig,
    BreWebsiteConfig,
    BreSocialConfig,
    BreBoostConfig,
    BreDuskConfig,
  ],
  eligible: ({ metadata }) => metadata.type === BreOrderType.RESIDENTIAL,
  description: dedent`
    Includes our ground photography, a 2D Floor Plan, the single property website \
    page, a social media boost, a 1 Minute Social Media Video, and one complimentary \
    Day-to-Dusk Photo Edit.
  `,
};

export default BreSocialPackageConfig;
