import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreCoversConfig from '~/tenants/bre/performable/print/covers/BreCoversConfig';
import { BreCoversPay, BreCoversRate } from '~/tenants/bre/performable/print/covers/BreCoversData';
import { DeliverableType } from '~common/model/Deliverable';

export default class BreCoversJob extends BreJob<typeof BreCoversConfig> {
  get performable(): typeof BreCoversConfig {
    return BreCoversConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.BROCHURE;
  }

  get eligible(): boolean {
    return false;
  }

  isDelivery(): boolean {
    return true;
  }

  isPrint(): boolean {
    return true;
  }

  isFreeDelivery(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreCoversConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      count: '100',
      need_by: '' as TDateISODate,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { count } = this.metadata;
    const rate = BreCoversRate[count];
    const lines: TenantJobLine[] = [];

    if (rate) {
      lines.push({
        id: 'covers_print',
        description: `Folders cover (x${count})`,
        amount: new Big(rate),
        taxable: true,
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { count } = this.metadata;
    const pay = BreCoversPay[count];
    const lines: TenantJobLine[] = [];

    if (pay) {
      lines.push({
        id: 'covers_print',
        description: `Folders cover (x${count})`,
        amount: new Big(pay),
      });
    }

    return lines;
  }
}
