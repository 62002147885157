import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import { BreFpvVideoRate } from '~/tenants/bre/performable/fvp/BreFpvVideoData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreFpvVideoForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { INDOOR } = BreFpvVideoRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckboxName
          name="indoor"
          title="Indoor Footage"
          description="Add indoor footage to your FPV video"
          image="tenant/bre/fpv/indoor.jpg"
          persona={persona}
          cost={INDOOR}
        />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
