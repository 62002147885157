import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import MpiJob from '~/tenants/mpi/model/MpiJob';
import MpiVirtualConfig from '~/tenants/mpi/performable/virtual/MpiVirtualConfig';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';

export class MpiVirtualJob extends MpiJob<typeof MpiVirtualConfig> {
  get performable() {
    return MpiVirtualConfig;
  }

  info(): DisplayData[] {
    const info = super.info();

    info.push({
      name: 'Photos',
      value: this.metadata.photos.toString(),
    });

    return info;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpiVirtualConfig)['schema']> {
    return {
      version: schema_latest_version(MpiVirtualConfig.schema),
      sky: '',
      photos: 1,
    };
  }

  get configurable() {
    return true;
  }

  get media() {
    return { persona: PersonaType.EDITOR, microsite: MicrositeFileType.GALLERY };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big(this.metadata.photos > 4 ? 20 : 25).times(this.metadata.photos),
      taxable: true,
      description: `Virtual Twilight (${this.metadata.photos} photos)`,
      id: 'photos',
    });

    return lines;
  }
}
