import { useState } from 'react';
import { FormHorizontal } from '~/components/form/layout';
import InputCheckbox from '~/components/input/InputCheckbox';
import { format_money } from '~/components/Money';
import MpiPhotosJob from './MpiPhotosJob';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import range from 'lodash.range';
import { Column, Row } from '~/components/layout/Sections';

export default function MpiPhotosExtra() {
  const [agree, setAgree] = useState(false);

  return (
    <Column gap>
      <Row>
        <div className="self-start">
          <InputCheckbox checked={agree} onChange={setAgree} />
        </div>

        <div className="text-sm flex-1">
          By checking this box, you are allowing our photographers to take additional photos using their professional
          discretion, and you accept the additional fees for extra images.
        </div>
      </Row>
      {agree && (
        <FormHorizontal name="extra_photos" label="Max Photos">
          <ZodFieldSelect
            options={range(1, 8).map((i) => ({
              label: `${i * 5} Photos: ${format_money(MpiPhotosJob.extraPhotos(i * 5).toFixed(2))}`,
              value: (i * 5).toString(),
            }))}
            name="photos"
          />
        </FormHorizontal>
      )}
    </Column>
  );
}
