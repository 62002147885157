import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  BASE_VIDEO: '315',
  AERIAL: '265',
  INTRO: '50',
  KWASI: '125',
  NARRATION: '50',
  RUSH: '200',
};

export const BreSocialRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
