import {
  TenantPackageAddOnNeighborhoods,
  TenantPackageAddOnRepeat,
  TenantPackageGroup,
} from '~/tenants/common/package';
import { TWT_PHOTO_NEIGHBORHOOD } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import React, { useEffect, useState } from 'react';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function TwtPhotosNeighborhood({ persona }: { persona: PersonaType }): JSX.Element {
  const [neighborhoods, setNeighborhoods] = useState<TenantPackageGroup[]>([]);

  useEffect(() => {
    fetch('/api/libraries')
      .then((r) =>
        r.json<{
          libraries: TenantPackageGroup[];
        }>(),
      )
      .then(({ libraries }) => {
        setNeighborhoods(libraries);
      });
  }, []);

  return (
    <>
      <TenantPackageAddOnNeighborhoods
        name="libraries"
        title="Stock Neighborhood Amenities"
        image="tenant/twt/neighborhood/1.jpg"
        groups={neighborhoods}
        persona={persona}
        cost={TWT_PHOTO_NEIGHBORHOOD}
        description={
          'Want to market the area lifestyle?\nWe will upload amenity stock photos from our extensive stock image library.\n*the $35 license fee allows you to reuse the photos for future listings'
        }
      />
      <TenantPackageAddOnRepeat
        persona={persona}
        name="neighborhood_custom"
        image="/tenant/twt/neighborhood/78701/downtownaustinladybirdlakecongresstocapitolviewsaustin/1.jpg"
        title="Custom Neighborhood Amenities"
        cost={TWT_PHOTO_NEIGHBORHOOD}
        lightbox
        description="Can't find the right neighborhood photo in our collection? Tell us where to go and we'll shoot additional locations for you."
      >
        <div className="flex items-center space-x-2">
          <ZodFieldInput name="location" placeholder="Location and/or Address" />
        </div>
      </TenantPackageAddOnRepeat>
    </>
  );
}
