import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import {
  LetterheadCount,
  LetterheadPaper,
  countSchema,
  paperSchema,
} from '~/tenants/bre/performable/print/letterheads/BreLetterheadsConfig';
import { BreLetterheadsRate } from '~/tenants/bre/performable/print/letterheads/BreLetterheadsData';
import BreLetterheadsJob from '~/tenants/bre/performable/print/letterheads/BreLetterheadsJob';
import { ReactElement } from 'react';

export default function BreLetterheadsForm(): ReactElement {
  const tz = useTz();
  const paper = useZodFormFieldSingleValue<LetterheadPaper>('paper') ?? 'smooth_white';
  const count = useZodFormFieldSingleValue<LetterheadCount>('count') ?? '250';

  const paperOptions = paperSchema.options.map<InputCardOption>((value) => ({
    title: BreLetterheadsJob.paperType(value),
    description: 'paper',
    disabled: !BreLetterheadsRate[value][count],
    value,
  }));

  const countOptions = countSchema.options.map<InputCardOption>((value) => {
    const rate = BreLetterheadsRate[paper][value];

    return {
      title: `${value} pieces`,
      cost: rate || 'N/A',
      disabled: !rate,
      value,
    };
  });

  return (
    <FormGroup>
      <FormHorizontal name="paper" label="Paper" required>
        <ZodFieldCards name="paper" options={paperOptions} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="count" label="Count" required>
        <ZodFieldCards name="count" options={countOptions} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name="need_by" label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), 7)}
        />
      </FormHorizontal>
    </FormGroup>
  );
}
