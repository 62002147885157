import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';

export default function BreDuskForm(): ReactElement {
  return (
    <FormGroup>
      <FormHorizontal name="quantity" label="Quantity" required>
        <ZodFieldInput name="quantity" type="number" />
      </FormHorizontal>

      <BreListingDisclosure />
    </FormGroup>
  );
}
