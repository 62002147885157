import TwtPhotosNeighborhood from '~/tenants/twt/performable/photo/TwtPhotosNeighborhood';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtPhotosHighlights from '~/tenants/twt/performable/photo/TwtPhotosHighlights';
import { TWT_PHOTO_EXTRA } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { TenantPackageAddOnGeneric } from '~/tenants/common/package';
import { format_money } from '~/components/Money';
import React from 'react';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import Big from 'big.js';
import { TwtOrderType } from '~/tenants/twt/model/enums';
import TwtPhotoDomain from '~/tenants/twt/performable/photo/TwtPhotoDomain';

export default function TwtPhotosAddOns({
  persona,
  type,
  order,
}: {
  order: TwtOrderType;
  persona: PersonaType;
  type: TwtPhotoType;
}) {
  return (
    <>
      {order === TwtOrderType.COMMERCIAL && <TwtPhotosHighlights persona={persona} order={order} />}
      {order === TwtOrderType.FOR_SALE_LISTING && (
        <>
          {type === TwtPhotoType.INTERIOR_EXTERIOR && (
            <>
              <TenantPackageAddOnGeneric
                title="Extra Photos"
                image="tenant/twt/residential/1.jpg"
                persona={PersonaType.CUSTOMER}
                description="Need more photos?"
              >
                <ZodFieldSelect
                  options={[
                    { label: 'No Extra Photos', value: '0' },
                    {
                      label: `5 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(1).toFixed(2))}`,
                      value: '5',
                    },
                    {
                      label: `10 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(2).toFixed(2))}`,
                      value: '10',
                    },
                    {
                      label: `15 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(3).toFixed(2))}`,
                      value: '15',
                    },
                    {
                      label: `20 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(4).toFixed(2))} `,
                      value: '20',
                    },
                    {
                      label: `25 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(5).toFixed(2))} `,
                      value: '25',
                    },
                  ]}
                  name="photos"
                />
              </TenantPackageAddOnGeneric>
              <TwtPhotosHighlights persona={persona} order={order} />
            </>
          )}
          <TwtPhotoDomain persona={persona} />
        </>
      )}
      {order !== TwtOrderType.COMMERCIAL && <TwtPhotosNeighborhood persona={persona} />}
    </>
  );
}
