import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { ReactElement, useRef, useState } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import {
  BreCommertialTypeSchema,
  BreAccessSchema,
  BreResidentialTypeSchema,
  BreAccess,
} from '~/tenants/bre/model/BreOrderContext';
import { InputSelectOption } from '~/components/input/InputSelect';
import { match } from 'ts-pattern';
import { TenantOrderFormProps } from '~/tenants/common/form';
import { PersonaType } from '~/tenants/common/TenantPersona';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import InputCheckbox from '~/components/input/InputCheckbox';
import { BreOrderType } from '~/tenants/bre/model/enums';

const RESIDENTIAL_TYPES = BreResidentialTypeSchema.options.map<InputSelectOption>((value) => ({
  label: match(value)
    .with('single_family_home', () => 'Single Family Home')
    .with('single_family_duet', () => 'Single Family Attached Home (Duet)')
    .with('condo', () => 'Condo')
    .with('town_home', () => 'Town Home')
    .with('mobile_home', () => 'Mobile Home (in Park)')
    .with('manufactured_home', () => 'Manufactured Home')
    .with('bungalow', () => 'Bungalow')
    .with('duplex', () => 'Duplex')
    .with('loft', () => 'Loft')
    .with('penthouse', () => 'Penthouse')
    .with('apartment_unit', () => 'Single Apartment Unit')
    .with('studio', () => 'Studio')
    .exhaustive(),
  value,
}));

const COMMERTIAL_TYPES = BreCommertialTypeSchema.options.map<InputSelectOption>((value) => ({
  label: match(value)
    .with('empty_lot', () => 'Empty Lot')
    .with('land', () => 'Land')
    .with('apartment_complex', () => 'Apartment Complex')
    .with('commercial_building', () => 'Commercial Building')
    .with('multi_use_structure', () => 'Multi-Use Structure')
    .with('multi_family', () => 'Multi-Family (5+ Units)')
    .exhaustive(),
  value,
}));

const ACCESS_OPTS = BreAccessSchema.options.map<InputSelectOption>((value) => ({
  label: match(value)
    .with('none', () => 'No access required')
    .with('agent', () => 'Agent')
    .with('combo', () => 'Combo Box')
    .with('seller', () => 'Seller')
    .exhaustive(),
  value,
}));

export default function BreOrderForm({ persona }: TenantOrderFormProps): ReactElement {
  const type = useZodFormFieldSingleValue<BreOrderType>('type');
  const access = useZodFormFieldSingleValue<BreAccess>('access');

  const preselected = useRef(type !== undefined);

  const isMarketing = type === BreOrderType.AGENT_MKTG || type === BreOrderType.PROPERTY_MKTG;

  const [showGateCode, setShowGateCode] = useState(false);
  const [showCommunityInfo, setShowCommunityInfo] = useState(false);
  const [showParkingInfo, setShowParkingInfo] = useState(false);
  const [showColist, setShowColist] = useState(false);

  return (
    <FormGroup>
      {!preselected.current && (
        <FormHorizontal name="type" label="Type">
          <ZodFieldSelect
            name="type"
            options={[
              { label: 'Residential', value: BreOrderType.RESIDENTIAL },
              { label: 'Commercial', value: BreOrderType.COMMERCIAL },
              { label: 'Rental', value: BreOrderType.RENTAL },
              { label: 'Long-Term', value: BreOrderType.LONG_TERM },
              { label: 'Property Marketing', value: BreOrderType.PROPERTY_MKTG },
              { label: 'Agent Marketing', value: BreOrderType.AGENT_MKTG },
            ]}
          />
        </FormHorizontal>
      )}
      {!isMarketing && (
        <>
          <FormHorizontal name="propertyType" label="Property Type" required>
            <ZodFieldSelect
              name="propertyType"
              options={match(type)
                .with(BreOrderType.RESIDENTIAL, () => RESIDENTIAL_TYPES)
                .with(BreOrderType.RENTAL, () => RESIDENTIAL_TYPES)
                .with(BreOrderType.LONG_TERM, () => RESIDENTIAL_TYPES)
                .with(BreOrderType.COMMERCIAL, () => COMMERTIAL_TYPES)
                .with(undefined, () => [])
                .exhaustive()}
            />
          </FormHorizontal>
          <FormHorizontal name="sqft" label="Square Feet" required>
            <ZodFieldInput name="sqft" type="number" />
          </FormHorizontal>
          {type === BreOrderType.RESIDENTIAL && (
            <>
              <FormHorizontal name="beds" label="Beds" required>
                <ZodFieldInput name="beds" type="number" />
              </FormHorizontal>
              <FormHorizontal name="baths" label="Baths" required>
                <ZodFieldInput name="baths" type="number" />
              </FormHorizontal>
              <FormHorizontal name="lotSqft" label="Lot Square Feet">
                <ZodFieldInput name="lotSqft" type="number" />
              </FormHorizontal>
            </>
          )}
          {(type === BreOrderType.RESIDENTIAL || type === BreOrderType.RENTAL || type === BreOrderType.LONG_TERM) && (
            <>
              <FormHorizontal name="built" label="Year Built" required>
                <ZodFieldInput name="built" type="number" />
              </FormHorizontal>
              <FormHorizontal name="access" label="Access to Property">
                <ZodFieldSelect name="access" options={ACCESS_OPTS} />
              </FormHorizontal>
              {access === 'combo' && (
                <>
                  <FormHorizontal name="comboCode" label="Combo Box Code" required>
                    <ZodFieldInput name="comboCode" />
                  </FormHorizontal>
                  <FormHorizontal name="comboLocation" label="Combo Box Location">
                    <ZodFieldInput name="comboLocation" />
                  </FormHorizontal>
                </>
              )}
              <FormHorizontal label="Need to provide a Gate Code?">
                <InputCheckbox checked={showGateCode} onChange={setShowGateCode} />
              </FormHorizontal>
              {showGateCode && (
                <FormHorizontal name="gateCode" label="Gate Code">
                  <ZodFieldInput name="gateCode" />
                </FormHorizontal>
              )}
              <FormHorizontal label="Need access to community instruction?">
                <InputCheckbox checked={showCommunityInfo} onChange={setShowCommunityInfo} />
              </FormHorizontal>
              {showCommunityInfo && (
                <FormHorizontal name="communityInfo" label="Community access instructions">
                  <ZodFieldTextarea name="communityInfo" />
                </FormHorizontal>
              )}
            </>
          )}
        </>
      )}
      {type !== BreOrderType.AGENT_MKTG && (
        <>
          <FormHorizontal label="Need parking instructions?">
            <InputCheckbox checked={showParkingInfo} onChange={setShowParkingInfo} />
          </FormHorizontal>
          {showParkingInfo && (
            <FormHorizontal name="parkingInfo" label="Parking instructions">
              <ZodFieldTextarea name="parkingInfo" />
            </FormHorizontal>
          )}
          <FormHorizontal label="Have a Co-Listing agent?">
            <InputCheckbox checked={showColist} onChange={setShowColist} />
          </FormHorizontal>
          {showColist && (
            <>
              <FormHorizontal name="colistName" label="Co-Listing Agent Name">
                <ZodFieldInput name="colistName" />
              </FormHorizontal>
              <FormHorizontal name="colistEmail" label="Co-Listing Agent Email">
                <ZodFieldInput name="colistEmail" />
              </FormHorizontal>
              <FormHorizontal name="colistPhone" label="Co-Listing Agent Phone">
                <ZodFieldInput name="colistPhone" />
              </FormHorizontal>
            </>
          )}
        </>
      )}

      {persona === PersonaType.OFFICE && (
        <>
          <FormHorizontal name="excludeWeekendFees" label="Weekend Fees">
            <label className="flex gap-2 cursor-pointer" htmlFor="excludeWeekendFees">
              <ZodFieldCheckbox id="excludeWeekendFees" name="excludeWeekendFees" />
              Exclude weekeend fees.
            </label>
          </FormHorizontal>
          <FormHorizontal name="excludeTravelFees" label="Travel Fees">
            <label className="flex gap-2 cursor-pointer" htmlFor="excludeTravelFees">
              <ZodFieldCheckbox id="excludeTravelFees" name="excludeTravelFees" />
              Exclude travel fees.
            </label>
          </FormHorizontal>
        </>
      )}

      <FormHorizontal
        name="comments"
        label="Comments/Special Instructions"
        description="Special instructions for photographers, additional information for customer service, etc."
      >
        <ZodFieldTextarea name="comments" />
      </FormHorizontal>
    </FormGroup>
  );
}
