import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { MpiTwilightPhotoType } from './MpiPhotosTwilight';
import { GeocodedAddressSchema } from '~/lib/model';

export enum MpiPhotosType {
  STANDARD = 'standard',
  TWILIGHT = 'twilight',
  RESHOOT = 'reshoot',
  EXTERIOR = 'exterior',
  EVENT = 'event'
}

export enum MpiPhotosDetailTime {
  MINUTES_15 = '15',
  MINUTES_30 = '30',
}

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.nativeEnum(MpiPhotosType),

      extra_photos_max: z.coerce.number().optional(),
      extra_photos_actual: z.coerce.number().optional(),
      notes_editor: z.string().optional(),

      hours: z.coerce.number().optional(),

      detail: z.nativeEnum(MpiPhotosDetailTime).optional(),
      detail_notes: z.string().optional(),

      community: coerceBoolean().optional(),
      community_notes: z.string().optional(),

      photos: z.coerce.number().optional(),
      twilight_type: z.nativeEnum(MpiTwilightPhotoType).optional(),
      twilight_photos: z.coerce.number().optional(),
    }),
  },
  {
    name: 'Photography',
    thumbnail: '/tenant/mpi/residential/1.jpg',
    images: [
      // '/tenant/mpi/residential/1.avif',
      // '/tenant/mpi/residential/2.jpg',
      // '/tenant/mpi/residential/3.jpg',
      // '/tenant/mpi/residential/5.avif',
      // '/tenant/mpi/residential/6.jpg',
      // '/tenant/mpi/residential/7.avif',
    ],
    short: 'Our most popular service! Includes 36 photos, delivered in 24 hours or less!',
    tenant: TenantId.MPI,
    group: 'Digital Media',
  },
);
