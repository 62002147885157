import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import ValibotFieldCheckbox from '~/components/valibot/ValibotFieldCheckbox';

export default function BreCinematicSubmitForm(): ReactElement {
  return (
    <FormGroup>
      <label className="flex items-center gap-2" htmlFor="self_edited">
        <ValibotFieldCheckbox id="self_edited" name="self_edited" />
        Self-edited video
      </label>
    </FormGroup>
  );
}
