import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import { BreFloorplanRate } from '~/tenants/bre/performable/floorplan/BreFloorplanData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';

export default function BreFloorplanForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { RUSH } = BreFloorplanRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckboxName
          name="rush"
          title="Rush"
          description="Need the floor plan before noon on the next business day?"
          image="tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
      </TenantPerformableAddOns>

      <BreListingDisclosure />
    </FormGroup>
  );
}
