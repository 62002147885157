import { ReactElement } from 'react';
import Sections from '~/components/layout/Sections';
import PerformableGroupForm, {
  PerformableGroupNested,
  usePerformableGroup,
} from '~/components/performable/PerformableGroupForm';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePackageGroup from '~/tenants/bre/package/BrePackageGroup';
import { BreMatterportInclude, BrePackagePhotosInclude, BreZillowInclude } from '~/tenants/bre/package/common';
import BreMatterportAddOns from '~/tenants/bre/performable/matterport/BreMatterportAddOns';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BrePhotosCommunityAddOn from '~/tenants/bre/performable/photos/BrePhotosCommunityAddOn';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import BreZillowConfig from '~/tenants/bre/performable/zillow/BreZillowConfig';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageIncludes } from '~/tenants/common/package';

export default function BreZillowPackageForm(): ReactElement {
  const { order } = usePerformableGroup<BreOrderContext>();

  return (
    <Sections>
      <TenantPackageIncludes>
        <PerformableGroupNested performable={BrePhotosConfig}>
          <BrePackagePhotosInclude />
        </PerformableGroupNested>
        <PerformableGroupForm performable={BreZillowConfig}>
          <BreZillowInclude />
        </PerformableGroupForm>
        <PerformableGroupNested performable={BreMatterportConfig}>
          <BreMatterportInclude />
        </PerformableGroupNested>
      </TenantPackageIncludes>

      <BrePackageGroup itemNum={1} subtitle="Customize the ground photography." performable={BrePhotosConfig}>
        <BrePhotosFields metadata={order.metadata} />
        <BrePhotosCommunityAddOn context={order} persona={PersonaType.CUSTOMER} />
        <BrePhotosLocalAreaAddOn context={order} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup itemNum={2} subtitle="Customize the 3D Matterport." performable={BreMatterportConfig}>
        <BreMatterportAddOns context={order} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>
    </Sections>
  );
}
