import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  EXTRA_EDIT: '125',
  EXTRA_POSE: '105',
  ONSITE: '475',
  STUDIO: '300',
};

export const BrePortraitsRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
