import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  PHOTOS_8: '415',
  PHOTOS_15: '499',
  PHOTOS_25: '599',
};

export const BreTwilightRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    PHOTOS_8: '525',
  },
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
