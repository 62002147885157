import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { useTz } from '~/components/hooks/useTz';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { isBusinessDay, plusBusinessDays } from '~/lib/datettime';
import { SignShipping, shippingSchema, typeSchema } from '~/tenants/bre/performable/print/signs/BreSignsConfig';
import { BreSignsRate } from '~/tenants/bre/performable/print/signs/BreSignsData';
import BreSignsJob from '~/tenants/bre/performable/print/signs/BreSignsJob';
import { ReactElement } from 'react';

const TYPE_OPTS = typeSchema.options.map<InputCardOption>((value) => ({
  title: BreSignsJob.typeName(value),
  cost: BreSignsRate.signs[value],
  value,
}));

const SHIPPING_OPTS = shippingSchema.options.map<InputCardOption>((value) => ({
  title: BreSignsJob.shippingType(value),
  description: 'delivered',
  cost: BreSignsRate.shipping[value],
  value,
}));

export default function BreSignsForm(): ReactElement {
  const tz = useTz();
  const shipping = useZodFormFieldSingleValue<SignShipping>('shipping');

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type" required>
        <ZodFieldCards name="type" options={TYPE_OPTS} cols={3} required />
      </FormHorizontal>
      <FormHorizontal name="shipping" label="Shipping" required>
        <ZodFieldCards name="shipping" options={SHIPPING_OPTS} cols={2} required />
      </FormHorizontal>
      <FormHorizontal name={'need_by'} label="Need By" required>
        <ZodFieldDate
          name="need_by"
          filter={(date) => {
            const days = shipping === 'courier' ? 3 : 4;
            return isBusinessDay(date) && date >= plusBusinessDays(tz().startOf('day'), days);
          }}
        />
      </FormHorizontal>
    </FormGroup>
  );
}
