import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'walkthrough',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      agent: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Walkthrough Video',
    thumbnail: '',
    images: [],
    short: '',
    tenant: TenantId.MPI,
  },
);
