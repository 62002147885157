import { ReactElement } from 'react';
import Sections from '~/components/layout/Sections';
import PerformableGroupForm, {
  PerformableGroupNested,
  usePerformableGroup,
} from '~/components/performable/PerformableGroupForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePackageGroup from '~/tenants/bre/package/BrePackageGroup';
import {
  BrePackageBoostInclude,
  BrePackageCinematicInclude,
  BrePackageFloorplanInclude,
  BrePackageMatterportUpgrade,
  BrePackagePhotosInclude,
  BrePackageWebsiteInclude,
} from '~/tenants/bre/package/common';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';
import BreCinematicAddOns from '~/tenants/bre/performable/cinematic/BreCinematicAddOns';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreMatterportAddOns from '~/tenants/bre/performable/matterport/BreMatterportAddOns';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BrePhotosCommunityAddOn from '~/tenants/bre/performable/photos/BrePhotosCommunityAddOn';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreWebsiteDomianAddOn from '~/tenants/bre/performable/website/BreWebsiteDomianAddOn';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageIncludes } from '~/tenants/common/package';

export default function BreCinematicsPackageForm(): ReactElement {
  const { order, hasPerformable } = usePerformableGroup<BreOrderContext>();

  return (
    <Sections>
      <TenantPackageIncludes>
        <BrePackageCinematicInclude itemNum={1} />
        <PerformableGroupNested performable={BrePhotosConfig}>
          <BrePackagePhotosInclude itemNum={2} />
        </PerformableGroupNested>
        <PerformableGroupNested performable={BreFloorplanConfig}>
          <BrePackageFloorplanInclude itemNum={3} />
        </PerformableGroupNested>
        <BrePackageWebsiteInclude itemNum={4} />
        <PerformableGroupNested performable={BreBoostConfig}>
          <BrePackageBoostInclude itemNum={5} />
        </PerformableGroupNested>
      </TenantPackageIncludes>

      <BrePackageGroup
        itemNum={1}
        subtitle="Cinematic films that can include aeria footage"
        performable={BreCinematicConfig}
      >
        <BreCinematicAddOns context={order} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup itemNum={2} subtitle="Customize the ground photography." performable={BrePhotosConfig}>
        <BrePhotosFields metadata={order.metadata} />
        <BrePhotosCommunityAddOn context={order} persona={PersonaType.CUSTOMER} />
        <BrePhotosLocalAreaAddOn context={order} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup itemNum={3} subtitle="Earth-Tone Color 2D Floorplan." performable={BreFloorplanConfig}>
        <BrePackageMatterportUpgrade />
      </BrePackageGroup>

      {hasPerformable(BreMatterportConfig) && (
        <PerformableGroupForm performable={BreMatterportConfig}>
          <BreMatterportAddOns context={order} persona={PersonaType.CUSTOMER} />
        </PerformableGroupForm>
      )}

      <BrePackageGroup
        itemNum={4}
        subtitle="1-year subscription to a single property website page"
        performable={BreWebsiteConfig}
      >
        <ZodFieldHidden name="type" value="website" />
        <BreWebsiteDomianAddOn type={order.metadata.type} persona={PersonaType.CUSTOMER} />
      </BrePackageGroup>

      <BrePackageGroup
        itemNum={5}
        subtitle="Promoting your property or an upcoming open house is a great way to get noticed in your listings city."
        performable={BreBoostConfig}
      >
        <BreBoostFields />
      </BrePackageGroup>
    </Sections>
  );
}
