import { TenantPackageAddOnGalleryExamplesCheckbox } from '~/tenants/common/package';
import { PersonaType } from '~/tenants/common/TenantPersona';
import range from 'lodash.range';
import {
  TWT_PHOTO_HIGHLIGHT_COMMERCIAL,
  TWT_PHOTO_HIGHLIGHT_RESIDENTIAL,
} from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { TwtOrderType } from '~/tenants/twt/model/enums';

const TWT_PHOTOS_HIGHLIGHTS: [string, ...string[]] = [
  'tenant/twt/highlight/4.jpg',
  ...range(1, 21)
    .map((i) => `tenant/twt/highlight/${i}.jpg`)
    .filter((i) => !i.includes('/4.jpg')),
];

export default function TwtPhotosHighlights({
  persona,
  order,
}: {
  persona: PersonaType;
  order: TwtOrderType;
}): JSX.Element {
  return (
    <>
      <TenantPackageAddOnGalleryExamplesCheckbox
        name="highlights"
        persona={persona}
        images={TWT_PHOTOS_HIGHLIGHTS}
        title="Property Highlights"
        cost={order === TwtOrderType.COMMERCIAL ? TWT_PHOTO_HIGHLIGHT_COMMERCIAL : TWT_PHOTO_HIGHLIGHT_RESIDENTIAL}
        description="Order this upgrade to receive additional time on site, specialized shooting style and custom editing to capture detail shots beyond standard real estate angles. Perfect for showcasing lifestyle vignettes and architectural details."
      />
    </>
  );
}
