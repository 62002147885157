import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import TwtEditingConfig, {
  TWT_EDITING_LABELS,
  TwtEditingType,
} from '~/tenants/twt/performable/editing/TwtEditingConfig';

export const TWT_EDITING_PRICES: Record<TwtEditingType, number> = {
  [TwtEditingType.BLUE_SKY]: 0,
  [TwtEditingType.GRASS_COLOR]: 10,
  [TwtEditingType.GRASS_REPLACE]: 20,
  [TwtEditingType.GRASS_AERIAL]: 15,
  [TwtEditingType.DRIVEWAY]: 10,
  [TwtEditingType.CROP]: 0,
  [TwtEditingType.COLOR]: 0,
  [TwtEditingType.LIGHT]: 0,
  [TwtEditingType.BOUNDARY]: 0,
  [TwtEditingType.OBJECT_REMOVAL]: 20,
  [TwtEditingType.FIREPLACE]: 10,
  [TwtEditingType.TV_SCREEN]: 10,
  [TwtEditingType.SUNSET_SKY]: 10,
};

export const TWT_EDITING_PAYOUT: Record<TwtEditingType, number> = {
  [TwtEditingType.BLUE_SKY]: 0,
  [TwtEditingType.GRASS_COLOR]: 9,
  [TwtEditingType.GRASS_REPLACE]: 18,
  [TwtEditingType.GRASS_AERIAL]: 14,
  [TwtEditingType.DRIVEWAY]: 9,
  [TwtEditingType.CROP]: 0,
  [TwtEditingType.COLOR]: 0,
  [TwtEditingType.LIGHT]: 0,
  [TwtEditingType.BOUNDARY]: 0,
  [TwtEditingType.OBJECT_REMOVAL]: 18,
  [TwtEditingType.FIREPLACE]: 9,
  [TwtEditingType.TV_SCREEN]: 9,
  [TwtEditingType.SUNSET_SKY]: 9,
};

export class TwtEditingJob extends TwtJob<typeof TwtEditingConfig> {
  get performable() {
    return TwtEditingConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get hidden(): boolean {
    return true;
  }

  get confirm(): boolean {
    return true;
  }

  get configurable(): boolean {
    return true;
  }

  private get images() {
    return this.metadata.images.filter(
      (i) => !i.job_id || !this.appointment || this.appointment?.job_ids.includes(i.job_id),
    );
  }

  get inputs(): string[] {
    return this.images.map((i) => i.deliverable_id);
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtEditingConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtEditingConfig.schema),
      images: [],
    } as any;
  }

  get short() {
    return 'EDIT';
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
  }

  info(): DisplayData[] {
    const info = super.info();

    for (const image of this.images) {
      const notes = image.notes ?? 'No Notes';

      const type = TWT_EDITING_LABELS[image.type];

      info.push({
        name: `Input ${this.images.indexOf(image) + 1}`,
        value: `(${type}) ${notes}`,
        deliverable_id: image.deliverable_id,
        provider: true,
      });
    }

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    for (const image of this.images) {
      if (TWT_EDITING_PAYOUT[image.type]) {
        lines.push({
          amount: new Big(TWT_EDITING_PAYOUT[image.type]),
          description: TWT_EDITING_LABELS[image.type],
          id: `${image.deliverable_id}-${image.type}`,
        });
      }
    }

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    for (const image of this.images) {
      if (TWT_EDITING_PRICES[image.type]) {
        lines.push({
          amount: new Big(TWT_EDITING_PRICES[image.type]),
          description: TWT_EDITING_LABELS[image.type],
          taxable: true,
          id: `${image.deliverable_id}-${image.type}`,
        });
      }
    }

    return lines;
  }
}
