import { ReactElement, ReactNode } from 'react';
import Heading from '~/components/layout/Heading';
import PerformableGroupForm from '~/components/performable/PerformableGroupForm';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';

interface BrePackageGroupProps {
  children: ReactNode;
  itemNum: number;
  performable: TenantPerformableConfig;
  subtitle: string;
}

export default function BrePackageGroup({
  children,
  itemNum,
  performable,
  subtitle,
}: BrePackageGroupProps): ReactElement {
  return (
    <>
      <Heading title={`${itemNum}. ${performable.name}`} subtitle={subtitle} />

      <PerformableGroupForm performable={performable}>{children}</PerformableGroupForm>
    </>
  );
}
