import { z } from 'zod';
import { InputCardOption } from '~/components/input/InputCards';
import { datetime, plusBusinessDays } from '~/lib/datettime';
import { TimeZone } from '~/lib/enum';
import { capitalize } from '~/lib/format';
import { DistanceAddress, DistanceAddressSchema } from '~/lib/model';
import { ZodCoerceBoolean, coerceBoolean } from '~/lib/zod';
import { BRE_DELIVERY_FEES } from '~/tenants/bre/data';
import { BreOrderType } from '~/tenants/bre/model/enums';

export type NeedBy = 'next_day' | 'same_day';

export type VoiceType = z.infer<typeof voiceSchema>;

export const CONTENT_WRITING_PAY = '50';

export const CONTENT_WRITING_RATE = '75';

export const voiceSchema = z.enum(['male', 'female']);

export function consentSchema(message?: string): z.ZodEffects<z.ZodOptional<ZodCoerceBoolean>, boolean | undefined> {
  return coerceBoolean()
    .optional()
    .refine((v) => v === true, {
      message: message ?? 'Please read and accept all consents',
    });
}

export function deliverySchema(message?: string): z.ZodEffects<typeof DistanceAddressSchema, DistanceAddress> {
  return DistanceAddressSchema.refine(
    ({ time }) => time && deliveryFee(time, 'rate'),
    message ?? 'The address out of the delivery range',
  );
}

export const VOICE_OPTS = voiceSchema.options.map<InputCardOption>((value) => ({
  title: `${capitalize(value)} Voice`,
  description: `We narrate with a ${value} voice`,
  value,
}));

export function toNeedBy(date: TDateISODate | undefined): NeedBy {
  if (date) {
    const today = datetime(Date.now(), TimeZone.US_CENTRAL);
    const limit = today.set({ hour: 10 }).startOf('hour');
    const needBy = datetime(date, TimeZone.US_CENTRAL);
    const printDay = today > limit ? plusBusinessDays(today, 1).startOf('day') : today;

    return needBy <= printDay ? 'same_day' : 'next_day';
  }

  return 'next_day';
}

export function deliveryFee(time: number, fee: 'rate' | 'pay'): string | undefined {
  const mins = time / 60;
  const limit = BRE_DELIVERY_FEES.find(({ upTo }) => mins <= upTo);

  return limit?.[fee];
}

export function isMarketingOrder(type: BreOrderType): boolean {
  return type === BreOrderType.AGENT_MKTG || type === BreOrderType.PROPERTY_MKTG;
}
