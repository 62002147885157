import PicAerialConfig, { PicAerialPhotographyOptionId } from '~/tenants/pic/performable/aerial/PicAerialConfig';
import PicJob from '~/tenants/pic/model/PicJob';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PicOrderType } from '~/tenants/pic/model/PicOrder';

interface PicAerialPhotographyOptions {
  revenue: number;
  photos: number;
  acres: number;
}

export const Pic_AERIAL_OPTIONS: Record<PicAerialPhotographyOptionId, PicAerialPhotographyOptions> = {
  [PicAerialPhotographyOptionId.ONE]: {
    revenue: 175,
    photos: 10,
    acres: 1,
  },
  [PicAerialPhotographyOptionId.TWO]: {
    revenue: 225,
    photos: 15,
    acres: 2,
  },
  [PicAerialPhotographyOptionId.THREE]: {
    revenue: 275,
    photos: 20,
    acres: 3,
  },
  [PicAerialPhotographyOptionId.FOUR]: {
    revenue: 325,
    photos: 25,
    acres: 4,
  },
};

export default class PicAerialJob extends PicJob<typeof PicAerialConfig> {
  get performable() {
    return PicAerialConfig;
  }

  onsite(): number {
    return 30;
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.community) {
      info.push({ name: 'Community Photos', value: 'Yes' });
    }

    return info;
  }

  get configurable() {
    return this.order.metadata.type !== PicOrderType.COMMERCIAL;
  }

  get multiple() {
    return this.order.metadata.type !== PicOrderType.COMMERCIAL;
  }

  get hourly() {
    return this.order.metadata.type === PicOrderType.COMMERCIAL;
  }

  defaultValue(): ZodVersionedMetadata<(typeof PicAerialConfig)['schema']> {
    let option = PicAerialPhotographyOptionId.ONE;

    if (this.order.metadata.type !== PicOrderType.DRAFT) {
      for (const [key, value] of Object.entries(Pic_AERIAL_OPTIONS)) {
        if (this.order.metadata.acres <= Math.min(4, value.acres)) {
          // TODO: better typing
          option = key as any as PicAerialPhotographyOptionId;
          break;
        }
      }
    }

    return {
      option,
      version: schema_latest_version(PicAerialConfig.schema),
    };
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type === PicOrderType.COMMERCIAL) {
      return [];
    }

    const amount = new Big(Pic_AERIAL_OPTIONS[this.metadata.option].revenue);

    const lines: TenantOrderLine[] = [
      {
        amount,
        description: this.performable.name,
        discountable: true,
        id: this.performable.id,
      },
    ];

    if (this.metadata.community) {
      lines.push({
        amount: new Big(60),
        description: 'Community Photos',
        id: 'community',
      });
    }

    return lines;
  }
}
