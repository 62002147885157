import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import { MpiOrderContext, MpiOrderType } from '../../model/MpiOrder';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import MpiPhotosAddOns from './MpiPhotosAddOns';
import MpiPhotosExtra from './MpiPhotosExtra';
import { PersonaType } from '~/tenants/common/TenantPersona';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function MpiPhotosForm({ context, persona }: TenantPerformableFormProps<MpiOrderContext>) {
  const type = useZodFormFieldSingleValue('type');

  return (
    <FormGroup>
      {persona === PersonaType.OFFICE ? (
        <ZodFieldSelect
          name="type"
          options={[
            { label: 'Standard', value: MpiPhotosType.STANDARD },
            { label: 'Twilight Only', value: MpiPhotosType.TWILIGHT },
            { label: 'Event', value: MpiPhotosType.EVENT },
            { label: 'Reshoot', value: MpiPhotosType.RESHOOT },
            { label: 'Exterior Only', value: MpiPhotosType.EXTERIOR },
          ]}
        />
      ) : (
        <ZodFieldHidden name="type" value={MpiPhotosType.STANDARD} />
      )}
      <FormHorizontal label="Special Instructions">
        <ZodFieldTextarea name="instructions" />
      </FormHorizontal>
      {context.metadata.type === MpiOrderType.COMMERCIAL && (
        <>
          <FormHorizontal label="Hours">
            <ZodFieldInput name="hours" />
          </FormHorizontal>
        </>
      )}
      {type === MpiPhotosType.STANDARD && (
        <>
          <FormHorizontal name="extra_photos" label="Extra Photos">
            <MpiPhotosExtra />
          </FormHorizontal>
          <TenantPerformableAddOns persona={persona}>
            <MpiPhotosAddOns persona={persona} />
          </TenantPerformableAddOns>
        </>
      )}
    </FormGroup>
  );
}
