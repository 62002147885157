import * as v from 'valibot';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberRequired,
  coerceOptionalPositiveNumber,
  FIRST_VERSION_TIMESTAMP,
} from '~/lib/zod';
import dedent from 'dedent';
import { consentSchema } from '~/tenants/bre/performable/common';

export default createPerformableConfig(
  'matterport',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      outdoor_back: coerceBoolean().optional(),
      outdoor_front: coerceBoolean().optional(),
      outdoor_commercial: coerceBoolean().optional(),
      plan: z.enum(['basic', 'advanced']).optional(),
      sqft: coerceOptionalPositiveNumber(),
      floorplan: coerceBoolean().optional(),
      disclosure: consentSchema(),
    }),
  },
  {
    name: '3D Matterport Showcase',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/matterport/thumbnail.png',
    short: dedent`
      Our 3D Matterport Showcase is an online experience that lets home buyers \
      3-dimensionally move through a property and view it from any angle. Our \
      unique ‘Dollhouse view’ provides you an outside looking in (no exterior \
      walls or roof) providing an incredible visual experience. WE NOW OFFER: \
      Matterport Pro3 camera which can scan both indoors and outdoors, even in \
      full sunlight!!!
    `,
    images: [],
    submit: v.object({
      sqft: v.pipe(v.number(), v.integer(), v.minValue(1)),
    }),
  },
);
