import { ReactElement, useState } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import IconMedia from '~/components/icons/streamline/line/IconMedia';
import Button from '~/components/interactive/Button';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { useZodFormFieldMultiple } from '~/components/zod/ZodForm';
import { BreCommunityStockModal } from '~/tenants/bre/performable/BreCommunityStockModal';

export default function BreCommunityFrom(): ReactElement {
  const [prefixed, photos] = useZodFormFieldMultiple('photos');

  const [show, setShow] = useState(false);

  return (
    <FormGroup>
      <FormHorizontal name={prefixed} label="Community Photos" required>
        <Button icon={<IconMedia />} variant="outline" onClick={() => setShow(true)}>
          {photos.length > 0 ? `View Selected (${photos.length})` : 'Select Photos'}
        </Button>
      </FormHorizontal>
      <BreCommunityStockModal
        name={prefixed}
        onClose={() => setShow(false)}
        show={show}
      />
      {photos.map((id, index) => <ZodFieldHidden key={id} name={`${prefixed}[${index}]`} value={id} />)}
    </FormGroup>
  );
}
