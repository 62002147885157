import dedent from 'dedent';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';

export type EnvelopeCount = z.infer<typeof countSchema>;

export type EnvelopePaper = z.infer<typeof paperSchema>;

export const countSchema = z.enum([
  '250',
  '500',
  '1000',
  '2500',
]);

export const paperSchema = z.enum(['smooth_white', 'premium_linen_white']);

export default createPerformableConfig(
  'envelopes',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: countSchema,
      paper: paperSchema,
      need_by: zodTDateISODate('Need By date is required'),
    }),
  },
  {
    name: 'Envelopes',
    group: 'Agent Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/envelopes/thumbnail_v2.jpg',
    images: [],
    short: dedent`
      We provide custom envelope printing on Smooth White or Premium Linen \
      White paper, giving your mail a professional and sophisticated touch.
    `,
  },
);
