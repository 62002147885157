import dedent from 'dedent';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import { isMarketingOrder } from '~/tenants/bre/performable/common';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreZillowConfig from '~/tenants/bre/performable/zillow/BreZillowConfig';
import { TenantPackageConfig } from '~/tenants/common/registry';

const BreZillowPackageConfig: TenantPackageConfig<BreOrderContext> = {
  id: 'zillow_package',
  name: 'Zillow',
  performables: [
    BrePhotosConfig,
    BreZillowConfig,
    BreMatterportConfig,
  ],
  eligible: ({ metadata }) => metadata.type === BreOrderType.RESIDENTIAL,
  description: dedent`
    Includes our ground photography, a Zillow showcase, and a $50 off 3D \
    Matterport showcase.
  `,
};

export default BreZillowPackageConfig;
