import { Big } from 'big.js';
import { TenantOrderLine } from '~/lib/model';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderMetadata } from '~/tenants/bre/model/BreOrderContext';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { DeliverableType } from '~common/model/Deliverable';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { TenantJobMedia } from '~/tenants/common/TenantJob';
import { BRE_COPYRIGHT_PHOTO_FEE } from '~/tenants/bre/data';
import { BreOrderType } from '~/tenants/bre/model/enums';

interface PhotosRateOptions {
  metadata: BreOrderMetadata;
  photos: number;
}

export default class BrePhotosJob extends BreJob<typeof BrePhotosConfig> {
  get performable(): typeof BrePhotosConfig {
    return BrePhotosConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  get media(): TenantJobMedia {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  get eligible(): boolean {
    return this.isOneOf(
      BreOrderType.RESIDENTIAL,
      BreOrderType.COMMERCIAL,
      BreOrderType.RENTAL,
      BreOrderType.LONG_TERM,
    );
  }

  get copyrightFee(): Big {
    const { photos } = this.metadata;
    const { type } = this.order.metadata;

    return new Big(BRE_COPYRIGHT_PHOTO_FEE[type]).times(photos);
  }

  static recommendedPhotos(metadata: BreOrderMetadata): number {
    const { type } = metadata;

    if (type !== BreOrderType.AGENT_MKTG && type !== BreOrderType.PROPERTY_MKTG && metadata.sqft >= 1500) {
      const closest = Math.floor((metadata.sqft - 500) / 1000) * 1000 + 500;
      return closest / 100;
    }

    return 15;
  }

  static rate({ metadata, photos }: PhotosRateOptions): Big {
    const recommended = BrePhotosJob.recommendedPhotos(metadata);
    const base =
      metadata.type === BreOrderType.COMMERCIAL
        ? new Big(photos >= recommended ? '60' : '30').times(photos).add(100)
        : new Big(photos >= recommended ? '7' : '3.5').times(photos).add(95);

    return base.times(1.05);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePhotosConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: BrePhotosJob.recommendedPhotos(this.order.metadata),
    };
  }

  onsite(): number {
    const { photos, twilight, local_area } = this.metadata;
    const photoTime = photos + 25;
    const twilightTime = twilight ? 40 : 0;
    const localAreaTime = local_area ? local_area.length * 20 : 0;

    return photoTime + twilightTime + localAreaTime;
  }

  revenueLines(): TenantOrderLine[] {
    const { photos, community, local_area, rush, twilight } = this.metadata;
    const { COMMUNITY, LOCAL_AREA, RUSH, TWILIGHT } = BrePhotosRate[this.order.metadata.type];
    const hasCommunity = this.order.jobs.some((j) => j.performable_id === 'community');
    const lines: TenantOrderLine[] = [];

    if (photos > 0) {
      const amount = BrePhotosJob.rate({
        metadata: this.order.metadata,
        photos,
      });

      lines.push({
        id: 'photos',
        description: `${photos} Daytime Photos`,
        amount,
        discountable: true,
      });

      if (this.isOneOf(BreOrderType.LONG_TERM)) {
        lines.push({
          id: 'copyright',
          description: 'Copyright',
          amount: this.copyrightFee,
        });
      }
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(TWILIGHT),
      });
    }

    if (community && community.length > 0 && !hasCommunity) {
      lines.push({
        id: 'community_photos',
        description: `Community Photos (x${community.length})`,
        amount: new Big(COMMUNITY).times(community.length),
      });
    }

    if (local_area && local_area.length > 0) {
      lines.push({
        id: 'poi',
        description: `Local Area Photos (x${local_area.length})`,
        amount: new Big(LOCAL_AREA).times(local_area.length),
      });
    }

    if (rush) {
      lines.push({
        id: 'rush_fee',
        description: 'Before noon rush fee',
        amount: new Big(RUSH),
      });
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { photos, local_area, twilight } = this.metadata;
    const { LOCAL_AREA, TWILIGHT } = BrePhotosRate[this.order.metadata.type];
    const lines: TenantOrderLine[] = [];

    if (photos > 0) {
      const price = BrePhotosJob.rate({
        metadata: this.order.metadata,
        photos: photos,
      });

      lines.push({
        id: 'photos',
        description: `${photos} Daytime Photos`,
        amount: price
          .div('1.05')
          .times('0.65')
          .times(this.matchSocialPackage ? '0.9' : '1'),
      });
    }

    if (twilight) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(TWILIGHT).div('1.05').times('0.65'),
      });
    }

    if (local_area && local_area.length > 0) {
      lines.push({
        id: 'poi',
        description: `Local Area Photos (x${local_area.length})`,
        amount: new Big(LOCAL_AREA).times(local_area.length).times('0.65'),
      });
    }

    return lines;
  }
}
