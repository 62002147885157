import z from 'zod';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { TenantCustomer } from '~/tenants/common/TenantPersona';
import { DisplayData } from '~/lib/model';

export const MpiCustomerSchema = {
  [FIRST_VERSION_TIMESTAMP]: z.object({
    version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),

    discount_flyer_25: z.coerce.number().optional(),
    discount_flyer_50: z.coerce.number().optional(),
    discount_brochure_2_25: z.coerce.number().optional(),
    discount_brochure_4_25: z.coerce.number().optional(),
    discount_brochure_8_25: z.coerce.number().optional(),
    discount_brochure_8_50: z.coerce.number().optional(),
    discount_aerial_video: z.coerce.number().optional(),
    discount_aerial_photo: z.coerce.number().optional(),
    discount_photography: z.coerce.number().optional(),
    discount_print: z.coerce.number().optional(),
    discount_gas: z.coerce.boolean().optional(),
    provider_increase: z.coerce.number().optional(),
  }),
};

export type MpiCustomerMetadata = ZodVersionedMetadata<typeof MpiCustomerSchema>;

export default class MpiCustomer extends TenantCustomer<MpiCustomerMetadata> {
  get info(): DisplayData[] {
    const info = super.info;

    return info;
  }
}
