import dedent from 'dedent';
import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export default createPerformableConfig(
  'zillow',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Zillow Showcase',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: '/tenant/bre/zillow/thumbnail_v3.png',
    images: [],
    short: dedent`
      Showcase Listings: A Premier Real Estate Experience.
      Showcase Listings offer a cutting-edge, media-forward design featuring \
      dynamic hero image carousels, interactive floor plans with embedded \
      photos, room groupings, and map placement. Listing agent branding is \
      prominently highlighted for maximum visibility.
      At ShowingTime+, allows you to work with your preferred photographer. \
      Agents have the freedom to choose their photographers to create stunning \
      media for Zillow Showcase listings, ensuring exceptional quality and engagement.
    `,
  },
);
