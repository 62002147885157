import { ReactElement, useState } from 'react';
import { EyeIcon } from '~/components/IconTemporary';
import { format_money } from '~/components/Money';
import InputCheckbox from '~/components/input/InputCheckbox';
import Button from '~/components/interactive/Button';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { useZodFormFieldMultiple } from '~/components/zod/ZodForm';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreCommunityStockModal } from '~/tenants/bre/performable/BreCommunityStockModal';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnTemplate, TenantPackageGroup } from '~/tenants/common/package';

interface BrePhotosCommunityAddOnProps {
  context: BreOrderContext;
  persona: PersonaType;
}

export default function BrePhotosCommunityAddOn({ context, persona }: BrePhotosCommunityAddOnProps): ReactElement {
  const { COMMUNITY } = BrePhotosRate[context.metadata.type];

  const [name ,community, setCommunity] = useZodFormFieldMultiple('community');
  
  const [show, setShow] = useState(false);

  return (
    <TenantPackageAddOnTemplate
      name={name}
      title="Community Photo Stock"
      description="Want to market the area lifestyle? Use photos from our extensive stock of images."
      image="tenant/bre/photos/community.png"
      persona={persona}
      cost={`${format_money(COMMUNITY)}/each`}
      enable={
        <InputCheckbox
          checked={community.length > 0}
          onChange={() => {
            community.length > 0
              ? setCommunity([])
              : setShow(true);
          }}
        />
      }
    >
      {community.length > 0 && (
        <div className="flex items-center gap-2 pt-2">
          <Button
            icon={<EyeIcon />}
            variant="outline"
            size="slim"
            onClick={(e) => {
              e.preventDefault();
              setShow(true)
            }}
          >
            View Selected ({community.length})
          </Button>
          {community.map((id, index) => <ZodFieldHidden key={id} name={`${name}[${index}]`} value={id} />)}
        </div>
      )}
      <BreCommunityStockModal
        name='community'
        show={show}
        onClose={() => setShow(false)}
      />
    </TenantPackageAddOnTemplate>
  )
}
