import { Big } from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreZillowConfig from '~/tenants/bre/performable/zillow/BreZillowConfig';
import { BreZillowRate } from '~/tenants/bre/performable/zillow/BreZillowData';

export default class BreZillowJob extends BreJob<typeof BreZillowConfig> {
  get performable(): typeof BreZillowConfig {
    return BreZillowConfig;
  }

  get configurable(): boolean {
    return false;
  }

  get eligible(): boolean {
    const hasPhotos = this.order.jobs.some((j) => j.performable_id === BrePhotosConfig.id);

    return !this.isMarketing && !this.isOneOf(BreOrderType.LONG_TERM) && hasPhotos;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreZillowConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines(): TenantJobLine[] {
    const { SHOWCASE } = BreZillowRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'zillow_showcase',
      description: 'Showcase',
      amount: new Big(SHOWCASE),
    });

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { SHOWCASE } = BreZillowRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'zillow_showcase',
      description: 'Showcase',
      amount: new Big(SHOWCASE).times('0.65'),
    });

    return lines;
  }
}
