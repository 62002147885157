import { z } from 'zod';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreBookletsConfig from '~/tenants/bre/performable/print/booklets/BreBookletsConfig';
import BreBrochuresConfig from '~/tenants/bre/performable/print/brochures/BreBrochuresConfig';
import BreBusinessCardsConfig from '~/tenants/bre/performable/print/cards/BreBusinessCardsConfig';
import BreEnvelopesConfig from '~/tenants/bre/performable/print/envelopes/BreEnvelopesConfig';
import BreFlyersConfig from '~/tenants/bre/performable/print/flyers/BreFlyersConfig';
import BreLetterheadConfig from '~/tenants/bre/performable/print/letterheads/BreLetterheadsConfig';
import BrePostcardsPrintOnlyConfig from '~/tenants/bre/performable/print/postcards/printonly/BrePostcardsPrintOnlyConfig';
import BrePostcardsMailingConfig from '~/tenants/bre/performable/print/postcards/mailing/BrePostcardsMailingConfig';
import BreRidersConfig from '~/tenants/bre/performable/print/riders/BreRidersConfig';
import BreSignsConfig from '~/tenants/bre/performable/print/signs/BreSignsConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import BreSlideshowConfig from '~/tenants/bre/performable/slideshow/BreSlideshowConfig';
import BreDesignConfig from '~/tenants/bre/performable/print/design/BreDesignConfig';
import BreFoldersConfig from '~/tenants/bre/performable/print/folders/BreFoldersConfig';
import BreCoversConfig from '~/tenants/bre/performable/print/covers/BreCoversConfig';
import BreGuidesConfig from '~/tenants/bre/performable/print/guides/BreGuidesConfig';
import BreCustomConfig from '~/tenants/bre/performable/custom/BreCustomConfig';
import BreFpvVideoConfig from '~/tenants/bre/performable/fvp/BreFpvVideoConfig';
import BreZillowConfig from '~/tenants/bre/performable/zillow/BreZillowConfig';
import BreCommunityConfig from '~/tenants/bre/performable/community/BreCommunityConfig';
import { BreCustomerMetadata } from '~/tenants/bre/model/BreCustomer';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { deliverySchema } from '~/tenants/bre/performable/common';
import { BreOrderType } from '~/tenants/bre/model/enums';

export type BreAccess = z.infer<typeof BreAccessSchema>;

export const BreAccessSchema = z.enum([
  'none',
  'agent',
  'seller',
  'combo',
]);

export const BreResidentialTypeSchema = z.enum([
  'single_family_home',
  'single_family_duet',
  'condo',
  'town_home',
  'mobile_home',
  'manufactured_home',
  'bungalow',
  'duplex',
  'loft',
  'penthouse',
  'apartment_unit',
  'studio',
]);

export const BreCommertialTypeSchema = z.enum([
  'empty_lot',
  'land',
  'apartment_complex',
  'commercial_building',
  'multi_use_structure',
  'multi_family',
]);

export const BreOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      colistName: z.string().optional(),
      colistEmail: z.string().optional(),
      colistPhone: z.string().optional(),
      comments: z.string().optional(),
      parkingInfo: z.string().optional(),
      excludeWeekendFees: z.boolean().optional(),
      excludeTravelFees: z.boolean().optional(),
      delivery: deliverySchema().optional(),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({
          type: z.literal(BreOrderType.RESIDENTIAL),
          propertyType: BreResidentialTypeSchema,
          built: z.coerce.number().min(1),
          sqft: z.coerce.number().min(1),
          beds: z.coerce.number().min(1),
          baths: z.coerce.number().min(1),
          lotSqft: z.coerce.number().optional(),
          access: BreAccessSchema.default('none'),
          comboCode: z.string().optional(),
          comboLocation: z.string().optional(),
          gateCode: z.string().optional(),
          communityInfo: z.string().optional(),
        }),
        z.object({
          type: z.literal(BreOrderType.COMMERCIAL),
          propertyType: BreCommertialTypeSchema,
          sqft: z.coerce.number().min(1),
        }),
        z.object({
          type: z.literal(BreOrderType.RENTAL),
          propertyType: BreResidentialTypeSchema,
          built: z.coerce.number().min(1),
          sqft: z.coerce.number().min(1),
          access: BreAccessSchema.default('none'),
          comboCode: z.string().optional(),
          comboLocation: z.string().optional(),
          gateCode: z.string().optional(),
          communityInfo: z.string().optional(),
        }),
        z.object({
          type: z.literal(BreOrderType.LONG_TERM),
          propertyType: BreResidentialTypeSchema,
          built: z.coerce.number().min(1),
          sqft: z.coerce.number().min(1),
          access: BreAccessSchema.default('none'),
          comboCode: z.string().optional(),
          comboLocation: z.string().optional(),
          gateCode: z.string().optional(),
          communityInfo: z.string().optional(),
        }),
        z.object({
          type: z.literal(BreOrderType.PROPERTY_MKTG),
        }),
        z.object({
          type: z.literal(BreOrderType.AGENT_MKTG),
        }),
      ]),
    ),
};
export type BreOrderMetadata = ZodVersionedMetadata<typeof BreOrderSchema>;
export type BrePerformableConfig =
  | typeof BreMatterportConfig
  | typeof BreAerialConfig
  | typeof BreCinematicConfig
  | typeof BreFloorplanConfig
  | typeof BrePhotosConfig
  | typeof BreBookletsConfig
  | typeof BreBrochuresConfig
  | typeof BreBusinessCardsConfig
  | typeof BreEnvelopesConfig
  | typeof BreFlyersConfig
  | typeof BreLetterheadConfig
  | typeof BrePostcardsPrintOnlyConfig
  | typeof BrePostcardsMailingConfig
  | typeof BreRidersConfig
  | typeof BreSignsConfig
  | typeof BrePortraitsConfig
  | typeof BreSocialConfig
  | typeof BreWebsiteConfig
  | typeof BreBoostConfig
  | typeof BreDuskConfig
  | typeof BreStagingConfig
  | typeof BreTwilightConfig
  | typeof BreSlideshowConfig
  | typeof BreDesignConfig
  | typeof BreFoldersConfig
  | typeof BreCoversConfig
  | typeof BreGuidesConfig
  | typeof BreCustomConfig
  | typeof BreFpvVideoConfig
  | typeof BreZillowConfig
  | typeof BreCommunityConfig;

export type BreOrderContext = TenantOrderContext<BreOrderMetadata, BrePerformableConfig, BreCustomerMetadata>;
