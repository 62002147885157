import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  BASE: '315',
  INDOOR: '315',
};

export const BreFpvVideoRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};

const BasePays = {
  BASE: '299',
  INDOOR: '301',
};

export const BreFpvVideoPay: Record<BreOrderType, typeof BasePays> = {
  [BreOrderType.COMMERCIAL]: BasePays,
  [BreOrderType.RESIDENTIAL]: BasePays,
  [BreOrderType.RENTAL]: BasePays,
  [BreOrderType.LONG_TERM]: BasePays,
  [BreOrderType.PROPERTY_MKTG]: BasePays,
  [BreOrderType.AGENT_MKTG]: BasePays,
};
