import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export default createPerformableConfig(
  'community',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.string().array().min(1, { message: 'At least one photo is required.' }),
    }),
  },
  {
    name: 'Community Photo Stock',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/photos/community.png',
    images: [],
    short: 'Want to market the area lifestyle? Use photos from our extensive stock of images.',
  },
);
