import dedent from 'dedent';
import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired } from '~/lib/zod';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export default createPerformableConfig(
  'slideshow',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Slideshow Video',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/slideshow/thumbnail.jpg',
    images: [],
    short: dedent`
      We create a 1 to 2 minute video (time depends on number of images) \
      formatted in both 16:9 (1920x1080 pixels) and 9:16 (1080x1920 pixels), \
      perfect for Instagram Stories, TikTok, Facebook Reels and YouTube.
    `,
  },
);
