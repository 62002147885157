import MpiJob from '~/tenants/mpi/model/MpiJob';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiWalkthroughConfig from '~/tenants/mpi/performable/walkthrough/MpiWalkthroughConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { DeliverableType } from '~common/model/Deliverable';
import { PersonaType } from '~/tenants/common/TenantPersona';

export const MPI_WALKTHROUGH_AGENT = 200;

export default class MpiWalkthroughJob extends MpiJob<typeof MpiWalkthroughConfig> {
  get performable() {
    return MpiWalkthroughConfig;
  }

  get configurable() {
    return true;
  }

  get deliverable() {
    return DeliverableType.VIDEO;
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  onsite() {
    let onsite = 60;

    if (this.metadata.agent) {
      onsite += 60;
    }

    return onsite;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpiWalkthroughConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  revenueLines(): TenantJobLine[] {
    const lines = super.revenueLines();

    lines.push({
      id: 'walkthrough',
      discountable: true,
      description: 'Walkthrough Video',
      amount: new Big(250),
    });

    if (this.metadata.agent) {
      lines.push({
        id: 'agent',
        discountable: true,
        description: 'Agent on Camera',
        amount: new Big(MPI_WALKTHROUGH_AGENT),
      });
    }

    return lines;
  }
}
