import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '../../model/MpiJob';
import Mpi3dConfig, { Mpi3dType } from '~/tenants/mpi/performable/3d/Mpi3dConfig';
import { DeliverableType } from '~common/model/Deliverable';
import { PersonaType } from '~/tenants/common/TenantPersona';

const MPI_IGUIDE_MIN_SQFT = 1000;
const MPI_IGUIDE_MIN_AMOUNT = 150;
const MPI_IGUIDE_SQFT_MULTIPLIER = 0.03;

export default class Mpi3dJob extends MpiJob<typeof Mpi3dConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof Mpi3dConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: Mpi3dType.IGUIDE,
    };
  }

  onsite() {
    if (this.order.metadata.sqft > 7000) {
      return 180;
    }

    if (this.order.metadata.sqft > 5000) {
      return 120;
    }

    if (this.order.metadata.sqft > 3000) {
      return 90;
    }

    return 60;
  }

  get deliverable() {
    return DeliverableType.MATTERPORT;
  }

  get performable() {
    return Mpi3dConfig;
  }

  get configurable() {
    return true;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.metadata.type === Mpi3dType.MATTERPORT) {
      lines.push({
        amount: new Big('0.03714285714').times(this.order.metadata.sqft).plus(200),
        description: '3D Matterport',
        id: 'matterport',
      });
    } else {
      const bySquareFootAmount =
        MPI_IGUIDE_MIN_AMOUNT +
        Math.ceil((Math.max(this.order.metadata.sqft, MPI_IGUIDE_MIN_SQFT) - MPI_IGUIDE_MIN_SQFT) / 1000) *
          1000 *
          MPI_IGUIDE_SQFT_MULTIPLIER;

      lines.push({
        amount: new Big(bySquareFootAmount),
        description: '3D IGuide',
        id: 'iguide',
      });
    }

    return lines;
  }
}
