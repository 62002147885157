import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { consentSchema } from '~/tenants/bre/performable/common';

export default createPerformableConfig(
  'dusk',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      quantity: z.coerce
        .number({ invalid_type_error: 'Quantity is required' })
        .positive({ message: 'Quantity must be greater than zero' }),
      disclosure: consentSchema(),
    }),
  },
  {
    name: 'Day to Dusk',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/dusk/thumbnail.jpg',
    images: [],
    short: 'Can create a day image digitally into a dusk image.',
  },
);
