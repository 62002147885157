import { ScheduleAppointmentPopupProps } from '~/components/schedule/components/ScheduleAppointmentPopup';
import { usePopupContext } from '~/components/interactive/Popup';
import { useEffect, useState } from 'react';
import type { ApiOrderContact } from '~/routes/api+/order.$order.contacts';
import { Row } from '~/components/layout/Sections';
import { addressToFull, DisplayData } from '~/lib/model';
import DisplayDataGrid from '~/components/model/DisplayDataGrid';
import LoadingIcon from '~/components/icons/LoadingIcon';
import ContactGrid from '~/components/model/ContactGrid';
import { Duration } from 'luxon';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import Tag from '~/components/Tag';

function Field({ fields, name }: { fields: DisplayData[]; name: string }) {
  const field = fields.find((f) => f.name === name);

  if (!field) {
    return null;
  }

  return <DisplayDataGrid data={[field]} full />;
}

export default function TwtScheduleAppointmentPopup({
  order,
  start,
  duration,
  tags,
  jobs,
}: ScheduleAppointmentPopupProps<TwtOrderContext>) {
  const popup = usePopupContext();
  const [{ loading, contacts }, setContacts] = useState<{ loading: boolean; contacts: ApiOrderContact[] }>({
    loading: true,
    contacts: [],
  });

  useEffect(() => {
    fetch(`/api/order/${order.order_id}/contacts`).then(async (r) => {
      setContacts({
        loading: false,
        contacts: await r.json<ApiOrderContact[]>(),
      });
    });
  }, []);

  useEffect(() => {
    if (contacts.length > 0) {
      popup.recalculate();
    }
  }, [contacts.length]);

  const data = [
    { name: 'Order #', value: order.order_number.toString() },
    { name: 'Address', value: addressToFull(order.address) },
    ...order.fields,
  ];

  return (
    <div className="bg-white p-2 shadow-2xl rounded-md m-2 text-sm max-w-2xl">
      <div className="flex gap-2">
        <div className="bg-gray-100 p-2 w-60 space-y-2 flex-grow-0">
          {/*{provider && <strong className="pb-1 block border-b border-gray-300 mb-1">{provider.name}</strong>}*/}
          {/*<Row>*/}
          {/*  <div className="w-4 h-4">*/}
          {/*    <AddressIcon />*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    <div className="font-medium">{addressToStreet(order.address)}</div>*/}
          {/*    <div className="text-xs">{addressToArea(order.address)}</div>*/}
          {/*  </div>*/}
          {/*</Row>*/}
          {/*{start && (*/}
          {/*  <Row className="border-t border-gray-300 pt-1 mt-1">*/}
          {/*    <div className="w-4 h-4">*/}
          {/*      <ScheduleIcon />*/}
          {/*    </div>*/}
          {/*    <div className="whitespace-nowrap text-left">*/}
          {/*      <div className="font-medium">{formalDay(start)}</div>*/}
          {/*      <div className="text-xs">*/}
          {/*        {start.toFormat('h:mm')} - {start.plus(Duration.fromISO(duration)).toFormat('h:mm a ZZZZ')}*/}
          {/*        /!*<p className="font-xs font-medium leading-snug italic">*!/*/}
          {/*        /!*  <small>{window ? window + ' min. arrival window' : 'No arrival window'} </small>*!/*/}
          {/*        /!*</p>*!/*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </Row>*/}
          {/*)}*/}

          <Row>
            <div className="text-xs">#{order.order_number}</div>
            {tags && tags.length > 0 && (
              <div className="space-x-1">
                {tags.map((tag) => (
                  <Tag key={tag.name} text={tag.name} color={tag.color} />
                ))}
              </div>
            )}
          </Row>

          {jobs.map((j) => (
            <div key={j.job_id} className="bg-gray-200 p-1 rounded-theme">
              <div className="font-medium text-orange-600">{j.name}</div>
              <DisplayDataGrid key={j.job_id} data={j.fields} small />
            </div>
          ))}

          <Row between className="text-lg">
            <div className="underline">Information</div>
            <strong className="font-bold">{order.address.zip}</strong>
          </Row>
          {start && (
            <DisplayDataGrid
              data={[
                {
                  name: 'Time',
                  value: `${start.toFormat('h:mm')} - ${start
                    .plus(Duration.fromISO(duration))
                    .toFormat('h:mm a ZZZZ')}`,
                },
              ]}
              full
            />
          )}
          <Field fields={data} name="Property Access" />
          <Field fields={data} name="Lockbox Code" />
          <Field fields={data} name="Order Type" />
          <Field fields={data} name="Occupancy" />
        </div>
        <div className="min-w-60 space-y-2 flex-1">
          <div className="bg-gray-100 p-2">
            <Row>
              {loading && (
                <div className="size-4">
                  <LoadingIcon />
                </div>
              )}
              <span className="font-bold">
                {order.buyer_name}
                {order.buyer_parent && <span className="text-xs">({order.buyer_parent})</span>}
              </span>
            </Row>
            <ContactGrid contacts={contacts} full small customer={order.buyer_name} />
          </div>
          <div className="flex-1 flex flex-col justify-between gap-2">
            <Field fields={data} name="Address" />
            <Field fields={data} name="Square Feet" />
            <Field fields={data} name="Requested Times" />
            <Row nowrap>
              <Field fields={data} name="Preferred Provider" />
              <Field fields={data} name="Alternate Provider" />
            </Row>
            <Field fields={data} name="Internal Notes" />
            <Field fields={data} name="Special Instructions" />
          </div>
        </div>
      </div>
    </div>
  );
}
