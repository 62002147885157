import dedent from 'dedent';
import { z } from 'zod';
import { FIRST_VERSION_TIMESTAMP, coerceLiteralNumberRequired, zodTDateISODate } from '~/lib/zod';
import { layoutSchema } from '~/tenants/bre/performable/print/design/BreDesignConfig';
import { TenantId, createPerformableConfig } from '~/tenants/common/registry';

export type PostcardPrintOnlyCount = z.infer<typeof countSchema>;

export const countSchema = z.enum([
  '50',
  '100',
  '200',
  '250',
  '300',
  '400',
  '500',
]);

export default createPerformableConfig(
  'postcards-printonly',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      count: countSchema,
      need_by: zodTDateISODate('Need By date is required'),
      design: layoutSchema.optional(),
    }),
  },
  {
    name: 'Post Cards (print-only)',
    group: 'Property Marketing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/print/postcards/printonly/thumbnail.jpg',
    images: [],
    short: dedent`
      Print custom postcards for Just Listed, Just Sold, You're Invited, and \
      more. Need mailing? Select our "Post Cards (mailing)" service!
    `,
  },
);
