import React from 'react';
import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DEMO_EDDM_OPTIONS } from '~/tenants/demo/performable/eddm/DemoEddmJob';
import { DemoEddmOptionId } from '~/tenants/demo/performable/eddm/DemoEddmConfig';
import { DemoOrderContext } from '~/tenants/demo/model/DemoOrder';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';

export default function DemoEddmForm(_props: TenantPerformableFormProps<DemoOrderContext>) {
  const [_optionName, option] = useZodFormFieldSingle<DemoEddmOptionId>('option');

  let quantity = <></>;

  if (option) {
    const pkg = DEMO_EDDM_OPTIONS[option];
    quantity = (
      <FormHorizontal name="quantity" label="Quantity">
        <ZodFieldSelect
          name="quantity"
          options={Object.entries(pkg.revenue).map(([id, pkg]) => ({
            value: id,
            label: id,
          }))}
        />
      </FormHorizontal>
    );
  }

  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="option" label="Size">
        <ZodFieldSelect
          name="option"
          options={Object.entries(DEMO_EDDM_OPTIONS).map(([id, pkg]) => ({
            value: id,
            label: `${pkg.width} x ${pkg.height}`,
          }))}
        />
      </FormHorizontal>

      {quantity}
    </TenantPerformableConfiguration>
  );
}
