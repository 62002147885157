import React, { useState } from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { DeliverableType } from '~common/model/Deliverable';
import DeliverableThumbnail, { DeliverableThumbnailGrid } from '~/components/deliverable/DeliverableThumbnail';
import Heading from '~/components/layout/Heading';
import Sections, { Row } from '~/components/layout/Sections';
import Button from '~/components/interactive/Button';
import { useZodFormFieldArray, ZodNestedForm } from '~/components/zod/ZodForm';
import { Selected } from '~/tenants/twt/performable/editing/TwtEditingAction';

export default function TwtEditingForm(props: TenantPerformableFormProps) {
  const [items, add, clear, data] = useZodFormFieldArray('images');
  const [checked, setChecked] = useState<string[]>(data.map((d) => d.id as string));
  const [configure, setConfigure] = useState(checked.length > 0);

  if (configure) {
    const filtered = props.deliverables?.filter((d) => checked.includes(d.id)) || [];

    return (
      <Sections>
        {items.map((item) => (
          <ZodNestedForm key={item.name} name={item.name}>
            <Selected deliverables={filtered} />
          </ZodNestedForm>
        ))}
      </Sections>
    );
  }

  return (
    <Sections>
      <Row between breakpoint="sm">
        <Heading title={`Photos Selected: ${checked.length}`} subtitle="Please select at least one photo to edit." />
        <Button
          disabled={checked.length === 0}
          variant="tenant"
          onClick={() => {
            clear();

            for (const id of checked) {
              const deliverable = props.deliverables?.find((d) => d.id === id);

              add({ deliverable_id: id, job_id: deliverable?.job_id });
            }

            setConfigure(true);
          }}
        >
          Continue
        </Button>
      </Row>
      <DeliverableThumbnailGrid>
        {props.deliverables?.map((photo) => {
          if (photo.data.type !== DeliverableType.PHOTO && photo.data.type !== DeliverableType.AERIAL) {
            return null;
          }

          return (
            <DeliverableThumbnail
              key={photo.id}
              lightbox
              deliverable={photo}
              checked={checked.includes(photo.id)}
              onCheck={(enabled) => {
                if (enabled) {
                  setChecked([...checked, photo.id]);
                } else {
                  setChecked(checked.filter((id) => id !== photo.id));
                }
              }}
            />
          );
        })}
      </DeliverableThumbnailGrid>
    </Sections>
  );
}
