import Fuse from 'fuse.js';
import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import ResizedThumbnail from '~/components/cdn/ResizedThumbnail';
import IconArrowDown from '~/components/icons/streamline/IconArrowDown';
import IconArrowUp from '~/components/icons/streamline/IconArrowUp';
import InputText from '~/components/input/InputText';
import Button from '~/components/interactive/Button';
import Link from '~/components/interactive/Link';
import Modal, { ModalHeader } from '~/components/interactive/Modal';
import Heading from '~/components/layout/Heading';
import ZodFieldCheckboxMultiple from '~/components/zod/ZodFieldCheckboxMultiple';
import { useZodFormFieldMultiple } from '~/components/zod/ZodForm';
import { LibraryImage } from '~/routes/api+/libraries';
import { TenantPackageGroup } from '~/tenants/common/package';

interface LibrariesApi {
  libraries: TenantPackageGroup[];
}

interface BreCommunityStockModalProps {
  name: string;
  onClose: () => void;
  show: boolean;
}

export function BreCommunityStockModal({ name, onClose, show }: BreCommunityStockModalProps): ReactElement {
  const [prefixed ,value, setValue] = useZodFormFieldMultiple(name);

  const [groups, setGroups] = useState<TenantPackageGroup[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [preview, setPreview] = useState<LibraryImage>();
  const [loading, setLoading] = useState(true);

  const filtered = useMemo((): TenantPackageGroup[] => {
    const fuse = new Fuse(groups, { keys: ['name', 'images.name'] });
    const result = search
      ? fuse.search(search).map(({ item }) => item)
      : groups;

    return result.slice(0, 10);
  }, [groups.map((g) => g.id).join(','), search]);

  useEffect(() => {
    fetch('/api/libraries')
      .then((r) => r.json<LibrariesApi>())
      .then(({ libraries }) => setGroups(libraries))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="relative rounded-theme overflow-hidden bg-white">
        {loading && (
          <div className="absolute w-full h-full flex items-center justify-center text-center z-100 bg-white overflow-hidden rounded-theme">
            <Heading title="Loading Stock..." subtitle="Please wait." />
          </div>
        )}
        {preview && (
          <div className="p-inner space-y-3 flex flex-col items-center justify-between absolute w-full h-full z-50 bg-white overflow-hidden rounded-theme">
            <div className="flex-1 flex items-center w-full">
              <ResizedThumbnail
                className="max-h-80 w-full"
                file={preview}
                width={650}
                height={300}
                medium
                round
              />
            </div>
            <div className="flex w-full justify-between space-x-unrelated">
              <Button type="button" variant="outline" onClick={() => setPreview(undefined)}>
                Back to Gallery
              </Button>
            </div>
          </div>
        )}
        <ModalHeader>Community Photo Stock</ModalHeader>
        <div className="p-4 space-y-2 border-theme-separator border-b">
          <p className="text-sm font-medium">
            There are {groups.length} communities for you to choose from, narrow the list by typing in the search bar
            below.
          </p>
          <InputText
            placeholder="Search by postal code, city name, or location name"
            onChange={setSearch}
          />
        </div>
        <div className="bg-gray-50 overflow-y-scroll h-96 divide-y divide-theme-separator select-none">
          {filtered.map((group) => (
            <Fragment key={group.id}>
              <div
                className="py-2 px-4 flex items-center gap-2 hover:bg-gray-100 cursor-pointer"
                onClick={() =>
                  setExpanded((prev) =>
                    (prev.includes(group.id) ? prev.filter((i) => i !== group.id) : [...prev, group.id])
                  )
                }
              >
                <p className="font-medium flex-1">{group.name}</p>
                <div className="w-6 h-3 pointer-events-none">
                  {expanded.includes(group.id) ? <IconArrowUp /> : <IconArrowDown />}
                </div>
              </div>
              {expanded.includes(group.id) && (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 p-inner">
                  {group.images.map((image) => (
                    <div className="relative flex flex-col items-center gap-1" key={image.id}>
                      <div className="absolute top-1 right-1 pointer-events-none z-10">
                        <ZodFieldCheckboxMultiple name={prefixed} value={image.id} size="sm" />
                      </div>
                      <ResizedThumbnail
                        className="cursor-pointer hover:scale-105 transition rounded-theme"
                        file={image}
                        width={150}
                        height={100}
                        onClick={() =>
                          setValue(
                            value.includes(image.id)
                              ? value.filter((i) => i !== image.id)
                              : [...value, image.id]
                          )
                        }
                      />
                      <div className="flex flex-col items-center text-sm">
                        <span>{stripExt(image.name)}</span>
                        <Link onClick={() => setPreview(image)}>
                          Preview
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </Modal>
  );
}

function stripExt(filename: string): string {
  return filename.includes('.')
    ? filename.split('.').slice(0, -1).join('.')
    : filename;
}
