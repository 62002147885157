import dedent from 'dedent';
import { ReactElement } from 'react';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosFields from '~/tenants/bre/performable/photos/BrePhotosFields';
import { BrePhotosRate } from '~/tenants/bre/performable/photos/BrePhotosData';
import BrePhotosLocalAreaAddOn from '~/tenants/bre/performable/photos/BrePhotosLocalAreaAddOn';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckboxName } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import BreCopyrightDisclosure from '~/tenants/bre/performable/BreCopyrightDisclosure';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BrePhotosCommunityAddOn from '~/tenants/bre/performable/photos/BrePhotosCommunityAddOn';


export default function BrePhotosForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { RUSH, TWILIGHT } = BrePhotosRate[context.metadata.type];

  const hasCommunity = context.jobs.some((j) => j.performable_id === 'community');

  return (
    <FormGroup>
      <BrePhotosFields metadata={context.metadata} />
      <FormHorizontal name="notes" label="Special Instructions">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckboxName
          name="rush"
          title="Rush"
          description="Need your photos before noon on the next business day?"
          image="tenant/bre/rush.jpg"
          persona={persona}
          cost={RUSH}
        />
        <TenantPackageAddOnCheckboxName
          name="twilight"
          title="Same-day Twilight"
          description={dedent`
            Photos include exterior, interior, and twilight shots, all taken in \
            one session. For twilight-only photos, select the Twilight – Separate \
            Trip option, allowing a different shoot date if needed.
          `}
          image="tenant/bre/photos/twilight.jpg"
          persona={persona}
          cost={TWILIGHT}
        />
        {!hasCommunity && (
          <BrePhotosCommunityAddOn context={context} persona={persona} />
        )}
        <BrePhotosLocalAreaAddOn context={context} persona={persona} />
      </TenantPerformableAddOns>

      {[BreOrderType.RENTAL, BreOrderType.LONG_TERM].includes(context.metadata.type) && (
        <BreCopyrightDisclosure context={context} />
      )}
      <BreListingDisclosure />
    </FormGroup>
  );
}
