import { ReactElement } from 'react';
import { match } from 'ts-pattern';
import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputCardOption } from '~/components/input/InputCards';
import ZodFieldCards from '~/components/zod/ZodFieldCards';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { typeSchema } from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { BreWebsiteRate } from '~/tenants/bre/performable/website/BreWebsiteData';
import BreWebsiteDomianAddOn from '~/tenants/bre/performable/website/BreWebsiteDomianAddOn';
import BreWebsiteJob from '~/tenants/bre/performable/website/BreWebsiteJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreWebsiteForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { RENEWAL, WEBSITE } = BreWebsiteRate[context.metadata.type];

  const typeOptions = typeSchema.options.map<InputCardOption>((value) => ({
    title: BreWebsiteJob.typeText(value),
    cost: match(value)
      .with('website', () => WEBSITE)
      .with('renewal', () => RENEWAL)
      .exhaustive(),
    value,
  }));

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type" required>
        <ZodFieldCards name="type" options={typeOptions} cols={2} required />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <BreWebsiteDomianAddOn type={context.metadata.type} persona={persona} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
