import dedent from 'dedent';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantPackageConfig } from '~/tenants/common/registry';

const BrePhotoPackageConfig: TenantPackageConfig<BreOrderContext> = {
  id: 'photo',
  name: 'Photography',
  percentage: 0.1,
  performables: [
    BrePhotosConfig,
    BreFloorplanConfig,
    BreBoostConfig,
    BreWebsiteConfig,
  ],
  eligible: ({ metadata }) => metadata.type === BreOrderType.RESIDENTIAL,
  description: dedent`
    Includes our ground photography, a 2D Floor Plan, a social media boost, and \
    the single property website page.
  `,
};

export default BrePhotoPackageConfig;
