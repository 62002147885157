import NepBrochuresConfig, {
  NepBrochureShipping,
  NepBrochuresOptionId,
  NepBrochuresQuantity,
} from '~/tenants/nep/performable/brochures/NepBrochuresConfig';
import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import NepJob from '~/tenants/nep/model/NepJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { NepOrderType } from '~/tenants/nep/model/NepOrder';

interface NepBrochuresOptions {
  revenue: Record<NepBrochuresQuantity, number>;
  pages: number;
  width: number;
  height: number;
}

export const DEMO_BROCHURES_OPTIONS: Record<NepBrochuresOptionId, NepBrochuresOptions> = {
  [NepBrochuresOptionId.TWO]: {
    revenue: {
      [NepBrochuresQuantity.QTY_25]: 95,
      [NepBrochuresQuantity.QTY_50]: 125,
      [NepBrochuresQuantity.QTY_100]: 175,
      [NepBrochuresQuantity.QTY_250]: 225,
    },
    pages: 2,
    width: 8.5,
    height: 11,
  },
  [NepBrochuresOptionId.FOUR]: {
    revenue: {
      [NepBrochuresQuantity.QTY_25]: 105,
      [NepBrochuresQuantity.QTY_50]: 150,
      [NepBrochuresQuantity.QTY_100]: 200,
      [NepBrochuresQuantity.QTY_250]: 300,
    },
    pages: 4,
    width: 11,
    height: 17,
  },
  [NepBrochuresOptionId.EIGHT]: {
    revenue: {
      [NepBrochuresQuantity.QTY_25]: 170,
      [NepBrochuresQuantity.QTY_50]: 240,
      [NepBrochuresQuantity.QTY_100]: 360,
      [NepBrochuresQuantity.QTY_250]: 525,
    },
    pages: 8,
    width: 11,
    height: 17,
  },
  [NepBrochuresOptionId.TWELVE]: {
    revenue: {
      [NepBrochuresQuantity.QTY_25]: 225,
      [NepBrochuresQuantity.QTY_50]: 300,
      [NepBrochuresQuantity.QTY_100]: 500,
      [NepBrochuresQuantity.QTY_250]: 750,
    },
    pages: 12,
    width: 11,
    height: 17,
  },
  [NepBrochuresOptionId.SIXTEEN]: {
    revenue: {
      [NepBrochuresQuantity.QTY_25]: 300,
      [NepBrochuresQuantity.QTY_50]: 360,
      [NepBrochuresQuantity.QTY_100]: 640,
      [NepBrochuresQuantity.QTY_250]: 850,
    },
    pages: 16,
    width: 11,
    height: 17,
  },
};

export default class NepBrochuresJob extends NepJob<typeof NepBrochuresConfig> {
  get performable() {
    return NepBrochuresConfig;
  }

  get eligible() {
    return this.order.metadata.type !== NepOrderType.COMMERCIAL;
  }

  get configurable() {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof NepBrochuresConfig)['schema']> {
    return {
      option: NepBrochuresOptionId.TWO,
      version: schema_latest_version(NepBrochuresConfig.schema),
      quantity: NepBrochuresQuantity.QTY_25,
    };
  }

  revenueLines(): TenantOrderLine[] {
    const amount = new Big(DEMO_BROCHURES_OPTIONS[this.metadata.option].revenue[this.metadata.quantity]);

    const lines: TenantOrderLine[] = [
      {
        amount,
        description: this.performable.name,
        id: this.performable.id,
      },
    ];

    if (this.metadata.shipping === NepBrochureShipping.RUSH) {
      lines.push({
        amount: new Big('55'),
        description: 'Rush Shipping',
        id: 'rush',
      });
    } else if (this.metadata.shipping === NepBrochureShipping.STANDARD) {
      lines.push({
        amount: new Big('22'),
        description: 'Standard Shipping',
        id: 'standard',
      });
    }

    return lines;
  }
}
