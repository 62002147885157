import { ReactElement } from 'react';
import Message from '~/components/Message';
import { format_money } from '~/components/Money';
import { BRE_COPYRIGHT_PHOTO_FEE, BRE_COPYRIGHT_VIDEO_FEE } from '~/tenants/bre/data';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreOrderType } from '~/tenants/bre/model/enums';

interface BreCopyrightDisclosureProps {
  context: BreOrderContext;
}

export default function BreCopyrightDisclosure({ context }: BreCopyrightDisclosureProps): ReactElement {
  const photoFee = format_money(BRE_COPYRIGHT_PHOTO_FEE[context.metadata.type]);
  const videoFee = format_money(BRE_COPYRIGHT_VIDEO_FEE[context.metadata.type]);

  return (
    <Message type="info">
      When you want to use a photo, you need to pay a fee because we hold the copyright, which gives us exclusive rights
      to our work. This <b>{photoFee} {context.metadata.type === BreOrderType.LONG_TERM ? 'per image' : ''} fee</b>{' '}
      and/or <b>{videoFee} video fee</b> ensures that you have legal permission to use the image. If you wish to use the
      image beyond any initial agreement, additional fees may apply to help cover these extended usage rights. This process
      ensures that you responsibly enjoy and utilize creative works while compensating us fairly.
    </Message>
  );
}
