import { BreOrderType } from '~/tenants/bre/model/enums';

const BaseRates = {
  TIER_1: '50',
  TIER_2: '45',
  TIER_3: '40',
  TIER_4: '35',
  ITEM_REMOVAL: '25',
  SPACE_RENOVATION: '35',
};

export const BreStagingRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.LONG_TERM]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.PROPERTY_MKTG]: BaseRates,
  [BreOrderType.AGENT_MKTG]: BaseRates,
};
