import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '../../model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import TwtMatterportVideoConfig from './TwtMatterportVideoConfig';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { TwtOrderType } from '~/tenants/twt/model/enums';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';

export const TWT_MATTERPORT_VIDEO_PRICE = 75;

export class TwtMatterportVideoJob extends TwtJob<typeof TwtMatterportVideoConfig> {
  get performable() {
    return TwtMatterportVideoConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SOCIAL;
  }

  get dependencies() {
    return [TwtMatterportConfig.id];
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtMatterportVideoConfig)['schema']> {
    return {
      version: schema_latest_version(TwtMatterportVideoConfig.schema),
    } as any;
  }

  get eligible() {
    return (
      this.order.metadata.type === TwtOrderType.FOR_SALE_LISTING &&
      this.order.jobs.some((j) => j.performable_id === '3d')
    );
  }

  get configurable() {
    return false;
  }

  get media() {
    return { persona: PersonaType.OFFICE, microsite: MicrositeFileType.VIDEO };
  }

  revenueLines(): TenantOrderLine[] {
    return [
      {
        amount: new Big(TWT_MATTERPORT_VIDEO_PRICE),
        taxable: true,
        description: 'Matterport Video',
        id: 'video',
      },
    ];
  }
}
