import { ReactElement } from 'react';
import { BreOrderType } from '~/tenants/bre/model/enums';
import OrderWizardTypeCard from '~/tenants/common/forms/OrderWizardTypeCard';

export default function BreOrderWizardType(): ReactElement {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <OrderWizardTypeCard
        value={BreOrderType.RESIDENTIAL}
        image="tenant/bre/order_residential.jpg"
        title="Residential for Sale"
      >
        We provide professional photography, video, and floorplans for residential listings for sale, with images
        licensed for short-term use exclusively for marketing the property.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.COMMERCIAL}
        image="tenant/bre/order_commercial.jpg"
        title="Commercial Listing for sale"
      >
        Commercial listing photography, video, and floorplans designed exclusively for properties for sale—not for lease
        or rent. Whether you&apos;re selling office buildings, apartment complexes, retail spaces, or other commercial
        properties, our professional visuals capture every detail to attract serious buyers.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.RENTAL}
        image="tenant/bre/order_rental.jpg"
        title="Rental / Airbnb/ VRBO photo use"
      >
        Professional photography, video, and floor plans to showcase your rental. This service includes a one-time
        copyright fee, allowing unlimited use for promotion. Ideal for landlords and property managers attracting
        quality tenants with high-quality visuals.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.LONG_TERM}
        image="tenant/bre/order_long_term.jpg"
        title="Long-Term photo usage rights"
      >
        A one time per-photo purchase which allows indefinite use without additional payments. Ideal for marketing your
        business on websites and social media.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.PROPERTY_MKTG}
        image="tenant/bre/order_property_mktg.jpg"
        title="Property Marketing"
      >
        We create high-quality property listing marketing materials, including brochures, flyers, postcards, and sign
        riders, to enhance visibility and attract potential buyers.
      </OrderWizardTypeCard>

      <OrderWizardTypeCard
        value={BreOrderType.AGENT_MKTG}
        image="tenant/bre/order_agent_mktg_v2.jpg"
        title="Agent Marketing"
      >
        Our Agent Marketing Materials include Headshots, Lifestyle images, Buyer/Seller, After the Sale, Preparing Your
        Home for Sale, Contract to Close PDF Guides, Stationary (Letterhead and envelopes) and Business Cards.
      </OrderWizardTypeCard>
    </div>
  );
}
