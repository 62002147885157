import MpiJob from '~/tenants/mpi/model/MpiJob';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { DisplayData, TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import MpiHeadshotConfig, { MpiHeadshotType } from '~/tenants/mpi/performable/headshot/MpiHeadshotConfig';

export const MPI_HEADSHOT_PEOPLE: Record<number, TMoney> = {
  1: '160.00',
  2: '245.00',
  3: '330.00',
  4: '415.00',
  5: '500.00',
  6: '585.00',
  7: '670.00',
  8: '755.00',
  9: '840.00',
  10: '925.00',
};

export default class MpiHeadshotJob extends MpiJob<typeof MpiHeadshotConfig> {
  get performable() {
    return MpiHeadshotConfig;
  }

  get configurable() {
    return true;
  }

  get estimate() {
    return this.metadata.type === MpiHeadshotType.LOCATION;
  }

  onsite() {
    if (this.metadata.type === MpiHeadshotType.LOCATION) {
      return 40;
    }

    return 30;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpiHeadshotConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: MpiHeadshotType.STUDIO,
    };
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.notes) {
      info.push({
        name: 'Notes',
        value: this.metadata.notes,
      });
    }

    if (this.metadata.type === MpiHeadshotType.LOCATION) {
      info.push({
        name: 'Location',
        value: 'On Location',
      });
    } else {
      info.push({
        name: 'Location',
        value: 'MPI Studio',
      });
    }

    if (this.metadata.parking) {
      info.push({
        name: 'Parking',
        value: this.metadata.parking,
      });
    }

    if (this.metadata.people) {
      info.push({
        name: 'People',
        value: this.metadata.people.toString(),
      });
    }

    if (this.metadata.group) {
      info.push({
        name: 'Type',
        value: 'Group Shot',
      });
    }

    return info;
  }

  revenueLines(): TenantJobLine[] {
    const lines = super.revenueLines();

    if (this.metadata.people) {
      lines.push({
        id: 'people',
        discountable: true,
        description: `People (${this.metadata.people})`,
        amount: new Big(MPI_HEADSHOT_PEOPLE[this.metadata.people]),
      });
    } else if (this.metadata.group) {
      lines.push({
        id: 'single',
        discountable: true,
        description: 'Group Shot',
        amount: new Big(125),
      });
    } else {
      lines.push({
        id: 'single',
        discountable: true,
        description: 'Single Person Headshot',
        amount: new Big(125),
      });
    }

    return lines;
  }
}
