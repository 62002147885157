import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpxOrderContext, MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';

export default function MpxMatterportForm({ context }: TenantPerformableFormProps<MpxOrderContext>) {
  const unitValue = useZodFormFieldSingleValue('unit');

  let floorplans = <ZodFieldHidden name="floorplan" value="" />;
  let video = <ZodFieldHidden name="video" value="" />;
  let device = <ZodFieldHidden name="device" value="PRO2" />;
  let type = <ZodFieldHidden name="type" value="sqft" />;
  let unit = <ZodFieldHidden name="unit" value="" />;
  let exterior = <ZodFieldHidden name="exterior" value="" />;
  let google = <ZodFieldHidden name="google" value="" />;
  let staging = <ZodFieldHidden name="staging" value="" />;
  let cad = <ZodFieldHidden name="cad" value="" />;
  let trueplan = <ZodFieldHidden name="trueplan" value="" />;

  if (context.metadata.type === MpxOrderType.RESIDENTIAL) {
    floorplans = (
      <FormHorizontal label="Floorplans" name="floorplan">
        <ZodFieldCheckbox name="floorplan" />
      </FormHorizontal>
    );

    video = (
      <FormHorizontal label="Walkthrough Video" name="video">
        <ZodFieldCheckbox name="video" />
      </FormHorizontal>
    );
  } else if (context.metadata.type === MpxOrderType.MULTI_FAMILY) {
    type = <ZodFieldHidden name="type" value="unit" />;

    const options = context.metadata.units
      .map((u) => ({
        value: u.id,
        label: `#${u.number} - ${u.beds} Beds, ${u.sqft} Sqft`,
      }))
      .filter((option) => {
        const units = context.jobs.map((j) =>
          j.performable_id === 'matterport' && j.metadata.type === 'unit' ? j.metadata.unit : '',
        );

        return !units.includes(option.value) || option.value === unitValue;
      });

    if (options.length === 0) {
      // TODO: prevent unit removal if jobs are associated
      unit = (
        <div>
          There are no remaining units available to assign this job.
          <ZodFieldHidden name="unit" value="" />
        </div>
      );
    } else {
      unit = (
        <FormHorizontal label="Unit">
          <ZodFieldSelect name="unit" options={options} />
        </FormHorizontal>
      );
    }

    exterior = (
      <FormHorizontal label="Community 360 Tour" name="exterior">
        <ZodFieldCheckbox name="exterior" />
      </FormHorizontal>
    );
  } else if (context.metadata.type === MpxOrderType.BUILDERS) {
    exterior = <ZodFieldHidden name="exterior" value="true" />;

    device = (
      <FormHorizontal label="Exterior" name="device">
        <ZodFieldSelect
          options={[
            { label: '360 Panorama', value: 'PRO2' },
            { label: 'Pro 3', value: 'PRO3' },
          ]}
          name="device"
        />
      </FormHorizontal>
    );

    staging = (
      <FormHorizontal label="Virtual Staging">
        <ZodFieldInput name="staging" type="number" />
      </FormHorizontal>
    );
  } else if (context.metadata.type === MpxOrderType.ARCHITECTURAL) {
    cad = (
      <FormHorizontal label="CAD Assets" name="cad">
        <ZodFieldCheckbox name="cad" />
      </FormHorizontal>
    );
  } else if (context.metadata.type === MpxOrderType.INSURANCE) {
    trueplan = (
      <FormHorizontal label="TruePlan" name="trueplan">
        <ZodFieldCheckbox name="trueplan" />
      </FormHorizontal>
    );
  }

  if ([MpxOrderType.MULTI_FAMILY, MpxOrderType.BUILDERS, MpxOrderType.COMMERCIAL].includes(context.metadata.type)) {
    google = (
      <FormHorizontal label="Publish as Google Virtual Tour" name="google">
        <ZodFieldCheckbox name="google" />
      </FormHorizontal>
    );
  }

  return (
    <>
      {type}
      {device}
      {floorplans}
      {video}
      {unit}
      {exterior}
      {google}
      {staging}
      {cad}
      {trueplan}
    </>
  );
}
