import { format_money } from '~/components/Money';
import FormGroup from '~/components/form/FormGroup';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreAerialFields from '~/tenants/bre/performable/aerial/BreAerialFields';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialData';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckboxName, TenantPackageAddOnRepeat } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { AerialType } from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreListingDisclosure from '~/tenants/bre/performable/BreListingDisclosure';
import dedent from 'dedent';
import { ReactElement } from 'react';
import BreCopyrightDisclosure from '~/tenants/bre/performable/BreCopyrightDisclosure';
import { BreOrderType } from '~/tenants/bre/model/enums';

export default function BreAerialForm(props: TenantPerformableFormProps<BreOrderContext>): ReactElement {
  const { context, persona } = props;
  const { POI, POI_EACH, RUSH, TWILIGHT } = BreAerialRate[context.metadata.type];

  const type = useZodFormFieldSingleValue<AerialType>('type');

  return (
    <FormGroup>
      <BreAerialFields type={context.metadata.type} />

      <TenantPerformableAddOns persona={persona}>
        {type === 'combo' && (
          <TenantPackageAddOnCheckboxName
            name="rush"
            title="Rush"
            description="Need your video by the next business day instead of the second business day?"
            image="tenant/bre/rush.jpg"
            persona={persona}
            cost={RUSH}
          />
        )}
        <TenantPackageAddOnCheckboxName
          name="twilight"
          title="Aerial Twilight"
          description="Add 5 aerial twilight photos"
          image="tenant/bre/aerial/twilight.jpg"
          persona={persona}
          cost={TWILIGHT}
        />
        <TenantPackageAddOnRepeat
          name="poi"
          title="Points of Interest"
          description={dedent`
            Add-On nearby points of interest. First 7 for only
            ${format_money(POI)}, then ${format_money(POI_EACH)}/each.
          `}
          image="tenant/bre/aerial/poi.jpg"
          persona={persona}
        >
          <div className="flex gap-2">
            <ZodFieldInput name="name" placeholder="Name" />
            <ZodFieldInput name="location" placeholder="Location" />
          </div>
        </TenantPackageAddOnRepeat>
      </TenantPerformableAddOns>

      {[BreOrderType.RENTAL, BreOrderType.LONG_TERM].includes(context.metadata.type) && (
        <BreCopyrightDisclosure context={context} />
      )}
      <BreListingDisclosure />
    </FormGroup>
  );
}
