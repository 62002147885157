import * as v from 'valibot';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { boostSchema } from '~/tenants/bre/performable/boost/BreBoostConfig';
import { consentSchema, voiceSchema } from '~/tenants/bre/performable/common';
import dedent from 'dedent';

export default createPerformableConfig(
  'social',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      aerial: coerceBoolean().optional(),
      intro: coerceBoolean().optional(),
      narration: coerceBoolean().optional(),
      kwasi: voiceSchema.optional(),
      boost: boostSchema.optional(),
      rush: coerceBoolean().optional(),
      disclosure: consentSchema(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Social Media Video',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/social/thumbnail.png',
    images: [],
    short: dedent`
      Leveraging vertical social media reels is revolutionizing how we showcase \
      properties on Facebook and Instagram. With just a smartphone and a gimbal, \
      we're crafting high-quality reels that grab attention and keep viewers \
      engaged. Adding an agent intro and voice narration brings a personal touch, \
      creating an immediate connection with potential buyers. And when we incorporate \
      aerial shots? It's like offering a panoramic tour, highlighting the property's \
      surroundings in a way static images just can't match.
    `,
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
