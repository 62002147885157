import dedent from 'dedent';
import { z } from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export type WebsiteType = z.infer<typeof typeSchema>;

export const typeSchema = z.enum(['website', 'renewal']);

export default createPerformableConfig(
  'website',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: typeSchema,
      domain: z.string().optional(),
    }),
  },
  {
    name: 'Single Property Website',
    group: 'Listing',
    tenant: TenantId.BEYOND_RE_MARKETING,
    thumbnail: 'tenant/bre/website/thumbnail.png',
    images: [],
    short: dedent`
      Our Single Property Website Page provides a custom web page for your \
      listing with all your services in one place.
    `,
  },
);
